<div class="row">
    <div class="col-sm-4 offset-sm-4">
        <br>
        <div class="alert alert-success" *ngIf="signupSuccess">
            Votre compte a bien été créé. Vous pouvez dès maintenant vous authentifier.
        </div>
        <div class="card">
            <div class="card-body">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="inputEmail">Email</label>
                        <input type="email" class="form-control" formControlName="email" id="inputEmail"
                            placeholder="Email address">
                    </div>
                    <div class="form-group">
                        <label for="inputPassword">Mot de passe</label>
                        <input type="password" class="form-control" formControlName="password" id="inputPassword"
                            placeholder="Password">
                        <small><a routerLink="/forgotten_password">Mot de passe oublié ?</a></small>
                    </div>
                    <div class="alert alert-danger" *ngIf="error">
                        {{error}}
                    </div>
                    <button [disabled]="loading" type="submit" class="btn btn-primary btn-block">
                        <span *ngIf="loading" class="button-loader"></span>
                        <span *ngIf="!loading">Se connecter</span>
                    </button>
                </form>
            </div>
        </div>
        <div class="text-center mt-2">
            Vous n'avez pas de compte ? <a routerLink="/signup">Créez un nouveau compte candidat</a>.
        </div>
    </div>
</div>
