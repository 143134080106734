import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Certification } from '../../../shared/models/certification';
import { CertificationCategory } from '../../../shared/models/certificationCategory';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CertificationCategoryService } from '../../../shared/services/certification-category/certification-category.service';
import { CertificationService } from '../../../shared/services/certification/certification.service';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-certification-modal',
  templateUrl: './certification-modal.component.html',
  styleUrls: ['./certification-modal.component.scss']
})
export class CertificationModalComponent implements OnInit {
  @Input() certification: Certification;

  baseUrl = environment.baseUrl;
  certificationForm: FormGroup;
  categories: Array<CertificationCategory>;
  isDataLoading: boolean = true;
  error: string;
  unsubscribe = new Subject<void>();
  uploadProgress: number;
  submitting: boolean;
  newFiles: Array<File>;
  validationErrors: Record<string, string>;

  constructor(
    public activeModal: NgbActiveModal,
    public certificationService: CertificationService,
    public certificationCategoryService: CertificationCategoryService,

  ) {
    this.isDataLoading = true;
    this.submitting = false;
  }

  ngOnInit() {
    this.certificationCategoryService.list().pipe(take(1)).subscribe((categories: Array<CertificationCategory>) => {
      this.categories = categories;
      this.resetForm();
      this.isDataLoading = false;
    });
  }

  // Function to instanciate the frontend form
  // Call only after all data is fetched
  private resetForm() {

    if (!this.certification) {

      this.certificationForm = new FormGroup({
        title: new FormControl(''),
        category: new FormControl(),
        description: new FormControl(''),
        code: new FormControl(''),
      });
    
    } else {

      this.certificationForm = new FormGroup({
        title: new FormControl(this.certification.title),
        category: new FormControl(this.certification.category.id),
        description: new FormControl(this.certification.description),
        code: new FormControl(this.certification.code),
      });

      this.isDataLoading = true;
    }
  }

  validate() {
    this.submitting = true;
    this.uploadProgress = 0;

    // Trigger update if it's an edit

    if (this.certification) {
      let submitCertfication = new Certification({
        id: this.certification.id,
        title: this.certificationForm.value.title,
        category: this.categories.find((cat: CertificationCategory) => cat.id == this.certificationForm.value.category),
        description: this.certificationForm.value.description,
        code: this.certificationForm.value.code,
      });

      this.certificationService.partialUpdate(submitCertfication).pipe(take(1)).subscribe(
        (res: Certification) => {
          this.certification = res;
          this.resetForm();
          this.activeModal.close(this.certification);
        },
        (err: any) => {
          if (err.status === 400) {
            this.validationErrors = err.error;
          } else {
            this.error = "Une erreur incconue est survenue lors de la modification de la certification";
            throw err;
          }
          this.submitting = false;
        });

    } else {
      let submitCertfication = new Certification({
        title: this.certificationForm.value.title,
        category: this.categories.find((cat: CertificationCategory) => cat.id == this.certificationForm.value.category),
        description: this.certificationForm.value.description,
        code: this.certificationForm.value.code,
      });

      this.certificationService.create(submitCertfication).pipe(take(1)).subscribe(
        (res: Certification) => {
          this.certification = res;
          this.resetForm();
          this.activeModal.close(this.certification);
        },
        (err: any) => {
          if (err.status === 400) {
            this.validationErrors = err.error;
          } else {
            this.error = "Une erreur incconue est survenue lors de la création de la certification";
            throw err;
          }
          this.submitting = false;
        });
    }
  }
}
