import { Component, OnInit, Input } from '@angular/core';
import { ExamCenter } from '../../../shared/models/examCenter';
import { UserService } from '../../../shared/services/user/user.service';
import { ExamCenterService } from '../../../shared/services/exam-center/exam-center.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';
import { User } from '../../../shared/models/user';
import { take } from 'rxjs/operators';
import { GroupsEnum } from '../../../shared/enums/groupsEnum';

@Component({
  selector: 'app-exam-center-modal',
  templateUrl: './exam-center-modal.component.html',
  styleUrls: ['./exam-center-modal.component.scss']
})
export class ExamCenterModalComponent implements OnInit {

  @Input() examCenter: ExamCenter;
  centerForm: FormGroup;
  bosses: Array<User>;
  isDataLoading: boolean = true;
  error: string;
  submitting: boolean = false;
  createExamCenterBossMode: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public examCenterService: ExamCenterService,
    private userService: UserService,
  ) {
    this.centerForm = new FormGroup({
      nom: new FormControl(''),
      boss: new FormControl(''),
      adresse1: new FormControl(''),
      adresse2: new FormControl(''),
      zipcode: new FormControl(''),
      city: new FormControl(''),
      siret: new FormControl(''),
      tva: new FormControl(''),
      boss_email: new FormControl(''),
      boss_phone_number: new FormControl(''),
      boss_first_name: new FormControl(''),
      boss_last_name: new FormControl(''),
    });
    this.isDataLoading = true;
  }

  // Function to instanciate the frontend form
  // Call only after all data is fetched
  private resetForm() {

    // Populate the form if there's an input
    if (this.examCenter != null) {
      this.centerForm.setValue({
        nom: this.examCenter.nom,
        boss: this.examCenter.boss ? this.examCenter.boss.id : null,
        adresse1: this.examCenter.adresse1,
        adresse2: this.examCenter.adresse2,
        zipcode: this.examCenter.zipcode,
        city: this.examCenter.city,
        siret: this.examCenter.siret,
        tva: this.examCenter.tva,
        boss_email: '',
        boss_phone_number: '',
        boss_first_name: '',
        boss_last_name: '',
      });
    }
  }

  ngOnInit() {
    this.userService.list([GroupsEnum.EXAM_CENTER_BOSS]).pipe(take(1)).subscribe((users: Array<User>) => {
      this.bosses = users;
      this.resetForm();
      this.isDataLoading = false;
    });
  }

  validate() {
    this.submitting = true;

    let submitExamCenter = new ExamCenter();

    submitExamCenter.nom = this.centerForm.value.nom;
    submitExamCenter.adresse1 = this.centerForm.value.adresse1;
    submitExamCenter.adresse2 = this.centerForm.value.adresse2;
    submitExamCenter.city = this.centerForm.value.city;
    submitExamCenter.zipcode = this.centerForm.value.zipcode;
    submitExamCenter.siret = this.centerForm.value.siret;
    submitExamCenter.tva = this.centerForm.value.tva;

    if (this.createExamCenterBossMode) {
      let boss = new User();
      boss.email = this.centerForm.value.boss_email;
      boss.phone_number = this.centerForm.value.boss_phone_number;
      boss.first_name = this.centerForm.value.boss_first_name;
      boss.last_name = this.centerForm.value.boss_last_name;
      submitExamCenter.boss = boss;
    } else {
      submitExamCenter.boss_id = this.centerForm.value.boss;
    }


    // Trigger update if it's an edit
    if (this.examCenter) {
      submitExamCenter.id = this.examCenter.id;
      this.examCenterService.update(submitExamCenter).pipe(take(1)).subscribe(
        (res: ExamCenter) => {
          this.examCenter = res;
          this.resetForm();
          this.activeModal.close(this.examCenter);
        },
        (err: any) => {
          this.error = "Une erreur est survenue lors de l'édition du centre d'examen"
          this.submitting = false;
        })
    }

    // Trigger a save otherwise
    else {
      this.examCenterService.create(submitExamCenter).pipe(take(1)).subscribe(
        (res: ExamCenter) => {
          this.examCenter = res;
          this.resetForm();
          this.activeModal.close(this.examCenter);
        },
        (err: any) => {
          this.error = "Une erreur est survenue lors de la création du centre d'examen"
          this.submitting = false;
        });
    }
  }
}
