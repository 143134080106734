import {Component, OnInit, Input} from "@angular/core";
import {Invoice} from "../../../shared/models/invoice";
import {FormGroup, FormControl} from "@angular/forms";
import {ExamCenter} from "../../../shared/models/examCenter";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {InvoiceService} from "../../../shared/services/invoice/invoice.service";
import {ExamCenterService} from "../../../shared/services/exam-center/exam-center.service";
import {take} from "rxjs/operators";
import {FileDownloadHelper} from "../../../shared/helpers/file-download.helper";
import {HttpResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";


@Component({
    selector: "app-invoice-modal",
    templateUrl: "./invoice-modal.component.html",
    styleUrls: ["./invoice-modal.component.scss"]
})
export class InvoiceModalComponent implements OnInit {

    @Input() invoice: Invoice;

    invoiceForm: FormGroup;
    examCenters: Array<ExamCenter>;
    isDataLoading: boolean = true;
    now: Date;
    document: File;
    submitting: boolean = false;
    retrievingDocument: boolean = false;

    constructor (
        public activeModal: NgbActiveModal,
        public examCenterService: ExamCenterService,
        public invoiceService: InvoiceService,
        private toastr: ToastrService
    ) {
        this.invoiceForm = new FormGroup({
            label: new FormControl(""),
            center: new FormControl(""),
            date: new FormControl("")
        });
        this.isDataLoading = true;
        this.now = new Date();
    }

    /*
     * Function to instanciate the frontend form
     * Call only after all data is fetched
     */
    private resetForm () {

        // Populate the form if there's an input
        if (this.invoice !== null) {
            this.invoiceForm.setValue({
                label: this.invoice.label,
                center: this.invoice.center
                    ? this.invoice.center.id
                    : null,
                date: this.invoice.date
            });
        }
    }

    ngOnInit () {
        this.examCenterService.list(true).pipe(take(1)).
            subscribe((examCenters: Array<ExamCenter>) => {
                this.examCenters = examCenters;
                this.resetForm();
                this.isDataLoading = false;
            });
    }

    onFileChange (event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.document = file;
        }
    }

    validate () {
        this.submitting = true;

        const submitInvoice = new Invoice(this.invoice);
        submitInvoice.label = this.invoiceForm.value.label;
        submitInvoice.center = this.examCenters.find((ec: ExamCenter) => ec.id == this.invoiceForm.value.center);
        submitInvoice.date = this.invoiceForm.value.date;

        // Trigger update if it's an edit
        if (this.invoice) {
            this.invoiceService.update(submitInvoice).pipe(take(1)).
                subscribe(
                    (res: Invoice) => {
                        this.invoice = res;

                        // If there's a document to upload, upload it then put it back in the invoice object
                        if (this.document) {
                            this.invoiceService.uploadFile(
                                this.invoice.id,
                                this.document
                            ).pipe(take(1)).
                                subscribe((res2: string) => {
                                    this.invoice.document = res2;
                                    this.resetForm();
                                    this.activeModal.close(this.invoice);
                                });
                        } else {
                            this.resetForm();
                            this.activeModal.close(this.invoice);
                        }
                    },
                    (err) => {
                        this.toastr.error(
                            "Une erreur est survenue",
                            "Erreur"
                        );
                        this.submitting = false;
                        throw err;
                    }
                );
        } else {
            this.invoiceService.create(submitInvoice).pipe(take(1)).
                subscribe(
                    (res: Invoice) => {
                        this.invoice = res;

                        // If there's a document to upload, upload it then put it back in the invoice object
                        if (this.document) {
                            this.invoiceService.uploadFile(
                                this.invoice.id,
                                this.document
                            ).pipe(take(1)).
                                subscribe((res2: string) => {
                                    this.invoice.document = res2;
                                    this.resetForm();
                                    this.activeModal.close(this.invoice);
                                });
                        } else {
                            this.resetForm();
                            this.activeModal.close(this.invoice);
                        }
                    },
                    (err) => {
                        if (err.status === 597) {
                            this.toastr.error(
                                "Attention ! l'email de confirmation n'a pas pu être envoyé",
                                "Opération enregistrée"
                            );
                        } else {
                            this.toastr.error(
                                "Une erreur est survenue",
                                "Erreur"
                            );
                        }

                        this.submitting = false;
                        throw err;
                    }
                );
        }
    }

    showDocument () {
        this.retrievingDocument = true;

        this.invoiceService.retrieveFile(this.invoice.id).pipe(take(1)).
            subscribe(
                (response: HttpResponse<Blob>) => {
                    this.retrievingDocument = false;
                    FileDownloadHelper.downloadBlobFromResponse(response);
                },
                () => {
                    this.retrievingDocument = false;
                    this.toastr.error(
                        "Contactez l'administrateur pour plus d'informations",
                        "Fichier introuvable"
                    );
                }
            );
    }
}
