<div class="modal-header">
    <h4 *ngIf="!miscFile" class="modal-title">Ajouter un document utile</h4>
    <h4 *ngIf="miscFile" class="modal-title">Document {{miscFile.name}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="miscFileForm" *ngIf="!isDataLoading">
        <div class="form-group row">
            <label for="nameInput" class="col-sm-4 col-form-label text-right">Nom du document</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="nameInput" formControlName='name'>
            </div>
        </div>
        <div class="form-group row">
            <label for="categorySelect" class="col-sm-4 col-form-label text-right">Catégorie</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="category" id="categorySelect">
                    <option *ngFor="let category of miscFileCategories; let i = index"
                        [value]='miscFileCategories[i].id'>
                        {{miscFileCategories[i].title}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="dateInput" class="col-sm-4 col-form-label text-right">Date</label>
            <div class="input-group col-sm-8">
                <input class="form-control" id="dateInput" placeholder="jj/mm/aaaa" formControlName='date' name="dp"
                    ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i
                            class="far fa-calendar-alt"></i></button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-8 offset-sm-4">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="allowSupervisorInput"
                        formControlName='allow_supervisor'>
                    <label for="allowSupervisorInput" class="form-check-label">Accessible aux superviseurs</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-8 offset-sm-4">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="allowCorrectorInput"
                        formControlName='allow_corrector'>
                    <label for="allowCorrectorInput" class="form-check-label">Accessible aux correcteurs</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-8 offset-sm-4">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="allowStudentInput"
                        formControlName='allow_student'>
                    <label for="allowStudentInput" class="form-check-label">Accessible aux candidats</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-8 offset-sm-4">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="allowExamCenterBossInput"
                        formControlName='allow_exam_center_boss'>
                    <label for="allowExamCenterBossInput" class="form-check-label">Accessible aux centres
                        d'examens</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="documentUrl" class="col-sm-4 col-form-label text-right">Fichier</label>
            <div class="col-sm-8" style="display: flex; align-items: center;">
                <button *ngIf="miscFile && miscFile.document" class="btn btn-link" (click)="showDocument()"
                    [disabled]="retrievingDocument">
                    <span *ngIf="retrievingDocument" class="button-loader"></span>
                    Voir le fichier
                </button>
                <input class="form-control-file" type="file" id="documentFile" (change)="onFileChange($event)">
            </div>
        </div>
        <div class="form-group" *ngIf="loading">
            <ngb-progressbar type="primary" [value]="uploadProgress"></ngb-progressbar>
        </div>
    </form>
    <div *ngIf="error" class="alert alert-danger">
        {{error}}
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" [disabled]="loading"
        (click)="activeModal.dismiss()">Annuler</button>
    <button type="button" class="btn btn-outline-success" [disabled]="loading" (click)="validate()">
        <span *ngIf="loading" class="button-loader"></span>
        Valider
    </button>
</div>