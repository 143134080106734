import {Component, Input, OnInit} from "@angular/core";
import {Exam} from "../../../shared/models/exam";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Certification} from "../../../shared/models/certification";
import {CertificationService} from "../../../shared/services/certification/certification.service";
import {CertificationTest} from "../../../shared/models/certificationTest";
import {ExamResultFileModalComponent} from "../../exam/exam-result-file-modal/exam-result-file-modal.component";
import {ExamResultValue} from "../../../shared/models/examResultValue";
import {ExamService} from "../../../shared/services/exam/exam.service";
import {forkJoin} from "rxjs";

import {take} from "rxjs/operators";
import {User} from "../../../shared/models/user";
import {UserService} from "../../../shared/services/user/user.service";

@Component({
    "selector": "app-correction-modal",
    "templateUrl": "./correction-modal.component.html",
    "styleUrls": ["./correction-modal.component.scss"]
})
export class CorrectionModalComponent implements OnInit {

    @Input() exam: Exam;
    correctionForm: FormGroup;
    isDataLoading: boolean = true;
    certification: Certification;
    error: string;
    user: User;
    loading: boolean = false;

    constructor (
        public activeModal: NgbActiveModal,
        private certificationService: CertificationService,
        private examService: ExamService,
        private userService: UserService,
        private modalService: NgbModal
    ) {

        this.isDataLoading = true;
    }

    ngOnInit () {
        const getCertification = this.certificationService.retrieve(this.exam.certification.id),
            getUser = this.userService.getMe();

        // Fill user form once all data has been fetched
        forkJoin([
            getCertification,
            getUser
        ]).pipe(take(1)).
            subscribe(([
                certification,
                user
            ]) => {
                this.certification = certification;
                this.user = user;
                this.resetForm();
                this.isDataLoading = false;
            });
    }

    /*
     * Function to instanciate the frontend form
     * Call only after all data is fetched
     */
    private resetForm () {

        // We create a new from group for the exam results, depending on the certification tests
        const resultValueForms = new FormArray(this.certification.tests.map((cnr: CertificationTest) => {
            const examResultValue = this.exam.examResultValues.find((erv: ExamResultValue) => erv.test.id == cnr.id);
            return new FormGroup({
                "value": new FormControl(examResultValue
                    ? examResultValue.value
                    : 0),
                "success": new FormControl(examResultValue
                    ? examResultValue.success
                    : false),
                "commentaire": new FormControl(examResultValue
                    ? examResultValue.commentaire
                    : "")

            });
        }));

        this.correctionForm = new FormGroup({
            "examResultValues": resultValueForms,
            "success": new FormControl(this.exam.success === null
                ? false
                : this.exam.success),
            "notifyUsers": new FormControl(true)
        });

        if (!this.user.isCorrector && !this.user.isStaff) {
            this.correctionForm.disable();
        }

        if (this.exam.locked) {
            this.correctionForm.disable();
        }

    }

    // Looks at the exam result values and compares it to the required threshold on the test
    getTestSuccess (i: number): boolean {
        const formArray = this.correctionForm.controls.examResultValues as FormArray,
            {success} = formArray.controls[i].value;
        return success;
    }

    openFilesModal (exam: Exam) {
        const modalRef = this.modalService.open(
            ExamResultFileModalComponent,
            {"size": "lg"}
        );
        modalRef.componentInstance.exam = exam;
    }

    validate () {
        this.loading = true;

        let confirmResult = true;

        if (this.user.isStaff) {
            confirmResult = confirm("Êtes-vous certain de vouloir valider cette correction ? Une fois validée, la correction sera figée.");
        }

        if (confirmResult) {

            // Set the exam result value objects
            const formArray = this.correctionForm.controls.examResultValues as FormArray;

            const submitExamResultValues = formArray.controls.map((res: FormGroup, i: number) => ({
                "test_id": this.certification.tests[i].id,
                "value": res.value.value,
                // If success is null, set to false
                "success": Boolean(res.value.success),
                "commentaire": res.value.commentaire
            }));

            const examPatchedFields = {
                "success": this.correctionForm.controls.success.value,
                "notify_users": this.correctionForm.controls.notifyUsers.value,
                "exam_result_values": submitExamResultValues
            };

            // Staff: valdates the correction
            if (this.user.isStaff) {
                // eslint-disable-next-line dot-notation
                examPatchedFields["locked"] = true;
            }

            this.examService.partialUpdate(
                this.exam.id,
                examPatchedFields
            ).pipe(take(1)).
                subscribe(
                    (res2: Exam) => {
                        this.activeModal.close(res2);
                    },
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (err: any) => {
                        this.error = "Une erreur est survenue lors de l'envoi de la correction";
                        this.loading = false;
                        throw err;
                    }
                );
        }

    }
}
