import {Injectable} from "@angular/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {CertificationMockTest} from "../../models/certificationMockTest";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class CertificationMockTestService {

    constructor (private http: HttpClient) { }

    update (test: CertificationMockTest) {
        return this.http.put<CertificationMockTest>(
            `${environment.baseApiUrl}/certification-mock-tests/${test.id}/`,
            test
        ).pipe(map((res) => new CertificationMockTest(res)));
    }

    create (test: CertificationMockTest) {
        return this.http.post<CertificationMockTest>(
            `${environment.baseApiUrl}/certification-mock-tests/`,
            test
        ).pipe(map((res) => new CertificationMockTest(res)));
    }

    destroy (id: number) {
        return this.http.delete<CertificationMockTest>(`${environment.baseApiUrl}/certification-mock-tests/${id}/`);
    }

    uploadFile (certificationMockTestId: number, document: File): Observable<any> {
        const formData = new FormData();
        formData.append(
            "file",
            document
        );
        return this.http.put(
            `${environment.baseApiUrl}/certification-mock-tests/${certificationMockTestId}/upload_file/`,
            formData,
            {reportProgress: true, observe: "events"}
        );
    }

    removeFile (certificationMockTestId: number) {
        return this.http.delete<CertificationMockTest>(`${environment.baseApiUrl}/certification-mock-tests/${certificationMockTestId}/remove_file/`);
    }

    fetchFile (certificationMockTestId: number): Observable<HttpResponse<Blob>> {
        return this.http.get(
            `${environment.baseApiUrl}/certification-mock-tests/${certificationMockTestId}/retrieve_file/`,
            {
                responseType: "blob",
                observe: "response" // Observe the full response
            }
        );
    }
}
