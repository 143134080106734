import {HttpResponse} from "@angular/common/http";


export class FileDownloadHelper {
    static downloadBlobFromResponse (response: HttpResponse<Blob>, filename: string = null): void {
        this.downloadBlob(
            response.body,
            filename
                ? filename
                : this.extractFilenameFromResponse(response)
        );
    }

    static downloadBlob (blob: Blob, filename: string = null): void {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    private static extractFilenameFromResponse (response: HttpResponse<Blob>): string {
        const contentDisposition = response.headers.get("Content-Disposition");
        const matches = contentDisposition
            ? contentDisposition.match(/filename="?([^"]+)"?/)
            : null;
        return matches
            ? matches[1]
            : "downloaded_file";
    }
}
