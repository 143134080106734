import {Component, Input} from "@angular/core";
import {ExamSessionService} from "../../../shared/services/exam-session/exam-session.service";
import {ExamSession} from "../../../shared/models/examSession";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {take} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";


@Component({
    selector: "app-corrector-attendance-confirmation-modal",
    templateUrl: "./corrector-attendance-confirmation-modal.component.html",
    styleUrls: ["./corrector-attendance-confirmation-modal.component.scss"]
})
export class CorrectorAttendanceConfirmationModalComponent {

    @Input() examSession: ExamSession;
    submitting: boolean = false;
    accepted: boolean = false;

    constructor (
      public activeModal: NgbActiveModal,
      private examSessionService: ExamSessionService,
      private toastr: ToastrService
    ) {}

    validate () {
        this.submitting = true;
        this.examSessionService.confirmCorrectorAttendance(this.examSession.id).pipe(take(1)).
            subscribe(
                (res: ExamSession) => {
                    this.submitting = false;
                    this.activeModal.close(this.examSession);
                    return res;
                },
                (err) => {
                    if (err.status === 597) {
                        this.toastr.error(
                            "Attention ! l'email de confirmation n'a pas pu être envoyé",
                            "Opération enregistrée"
                        );
                    } else {
                        this.toastr.error(
                            "Une erreur est survenue",
                            "Erreur"
                        );
                    }

                    this.submitting = false;
                    throw err;
                }
            );
    }

}
