/* eslint-disable @typescript-eslint/no-explicit-any */
import {Component, OnInit} from "@angular/core";
import {AuthenticationService} from "../../../shared/services/authentication/authentication.service";
import {ActivatedRoute} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
    "selector": "app-login",
    "templateUrl": "./login.component.html",
    "styleUrls": ["./login.component.scss"]
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error: string;
    destroy$: Subject<boolean> = new Subject<boolean>();
    signupSuccess: boolean;

    constructor (
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute
    ) { }

    ngOnInit () {
        this.loginForm = this.formBuilder.group({
            "email": [
                "",
                [
                    Validators.required,
                    Validators.email
                ]
            ],
            "password": [
                "",
                Validators.required
            ]
        });

        this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params: any) => {
            if (params.signup_success === "true") {
                this.signupSuccess = true;
            }
        });
    }

    ngOnDestroy () {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


    // Convenience getter for easy access to form fields
    get f () {
        return this.loginForm.controls;
    }

    onSubmit () {
        this.submitted = true;

        // Stop here if form is invalid
        if (this.loginForm.invalid) {
            this.error = "Mauvais email et/ou mot de passe";
            return;
        }

        this.loading = true;
        this.authenticationService.login(
            this.f.email.value,
            this.f.password.value
        ).
            catch((error) => {
                if (error.status === 401) {
                    this.error = "Mauvais email et/ou mot de passe";

                } else {
                    this.error = "Une erreur est survenue";
                    throw error;
                }

                this.loading = false;

            });
    }

}
