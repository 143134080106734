import {BrowserModule} from "@angular/platform-browser";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core";
import {NgbModule, NgbDateAdapter, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import * as Sentry from "@sentry/angular-ivy";

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {LoginComponent} from "./components/auth/login/login.component";
import {TokenInterceptor} from "./shared/helpers/token.interceptor";
import {ReactiveFormsModule, FormsModule} from "@angular/forms";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {CertificationListComponent} from "./components/certification/certification-list/certification-list.component";
import {ExamListComponent} from "./components/exam/exam-list/exam-list.component";
import {SessionListComponent} from "./components/session/session-list/session-list.component";
import { PreregistrationListComponent } from "./components/preregistration/preregistration-list/preregistration-list.component";
import { PreregistrationModalComponent } from "./components/preregistration/preregistration-modal/preregistration-modal.component";
import {ExamCenterListComponent} from "./components/exam-center/exam-center-list/exam-center-list.component";
import {ExamCenterMembershipListComponent} from "./components/exam-center-membership/exam-center-membership-list/exam-center-membership-list.component";
import {UserListComponent} from "./components/user/user-list/user-list.component";
import {SessionModalComponent} from "./components/session/session-modal/session-modal.component";
import {SessionExportModalComponent} from "./components/session/session-export-modal/session-export-modal.component";
import {CustomAdapter} from "./shared/helpers/custom-date-adapter";
import {CustomDateParserFormatter} from "./shared/helpers/custom-date-parser-formatter";
import {ExamCenterModalComponent} from "./components/exam-center/exam-center-modal/exam-center-modal.component";
import {ExamCenterMembershipModalComponent} from "./components/exam-center-membership/exam-center-membership-modal/exam-center-membership-modal.component";
import {UserModalComponent} from "./components/user/user-modal/user-modal.component";
import {FileDashboardComponent} from "./components/file-dashboard/file-dashboard.component";
import {InvoiceListComponent} from "./components/invoice/invoice-list/invoice-list.component";
import {ExamModalComponent} from "./components/exam/exam-modal/exam-modal.component";
import {ExamEditionModalComponent} from "./components/exam/exam-edition-modal/exam-edition-modal.component";
import {CertificationModalComponent} from "./components/certification/certification-modal/certification-modal.component";
import {CertificationTestModalComponent} from "./components/certification/certification-test-modal/certification-test-modal.component";
import {CertificationMockTestModalComponent} from "./components/certification/certification-mock-test-modal/certification-mock-test-modal.component";
import {InvoiceModalComponent} from "./components/invoice/invoice-modal/invoice-modal.component";
import {MiscFileListComponent} from "./components/misc-file/misc-file-list/misc-file-list.component";
import {MiscFileModalComponent} from "./components/misc-file/misc-file-modal/misc-file-modal.component";
import {ExamResultFileModalComponent} from "./components/exam/exam-result-file-modal/exam-result-file-modal.component";
import {ExamResetConfirmModalComponent} from "./components/exam/exam-reset-confirm-modal/exam-reset-confirm-modal.component";
import {RequestExamsModalComponent} from "./components/exam/request-exams-modal/request-exams-modal.component";
import {ExamCommentsModalComponent} from "./components/exam/exam-comments-modal/exam-comments-modal.component";
import {SelectModalComponent} from "./components/select-modal/select-modal.component";
import {MultiSelectModalComponent} from "./components/multi-select-modal/multi-select-modal.component";
import {CorrectionModalComponent} from "./components/correction/correction-modal/correction-modal.component";
import {StudentProfileComponent} from "./components/student-profile/student-profile/student-profile.component";
import {SignupComponent} from "./components/auth/signup/signup.component";
import {UserSelectModalComponent} from "./components/user/user-select-modal/user-select-modal.component";
import {LoadingOverlayComponent} from "./components/loading-overlay/loading-overlay.component";
import {SettingsComponent} from "./components/settings/settings.component";
import {ForgottenPasswordComponent} from "./components/auth/forgotten-password/forgotten-password.component";
import {SelectFileModalComponent} from "./components/select-file-modal/select-file-modal.component";
import {SessionSupervisionModalComponent} from "./components/session/session-supervision-modal/session-supervision-modal.component";
import {ExamTestComponent} from "./components/exam-test/exam-test.component";
import {ExamMockTestComponent} from "./components/exam-mock-test/exam-mock-test.component";
import {StudentProfileModalComponent} from "./components/student-profile/student-profile-modal/student-profile-modal.component";
import {ExamResultsModalComponent} from "./components/exam/exam-results-modal/exam-results-modal.component";
import {ContactFormComponent} from "./components/contact-form/contact-form.component";
import {
    SupervisorAttendanceConfirmationModalComponent
} from "./components/session/supervisor-attendance-confirmation-modal/supervisor-attendance-confirmation-modal.component";
import {
    CorrectorAttendanceConfirmationModalComponent
} from "./components/session/corrector-attendance-confirmation-modal/corrector-attendance-confirmation-modal.component";
import {CountrySelectComponent} from "./components/utils/country-select/country-select.component";
import {ToastrModule} from "ngx-toastr";
import {CommonModule} from "@angular/common";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {Router} from "@angular/router";
import {PhoneValidatorDirective} from "./shared/directives/phone-validator.directive";
import {SessionCommentsModalComponent} from "./components/session/session-comments-modal/session-comments-modal.component";

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        CertificationListComponent,
        CertificationModalComponent,
        CertificationTestModalComponent,
        CertificationMockTestModalComponent,
        SessionListComponent,
        PreregistrationListComponent,
        PreregistrationModalComponent,
        SessionModalComponent,
        SessionExportModalComponent,
        ExamCenterListComponent,
        ExamCenterMembershipListComponent,
        UserListComponent,
        ExamCenterModalComponent,
        ExamCenterMembershipModalComponent,
        UserModalComponent,
        FileDashboardComponent,
        InvoiceListComponent,
        ExamListComponent,
        ExamModalComponent,
        ExamEditionModalComponent,
        StudentProfileModalComponent,
        ExamResultsModalComponent,
        InvoiceModalComponent,
        MiscFileListComponent,
        MiscFileModalComponent,
        ExamResultFileModalComponent,
        ExamResetConfirmModalComponent,
        SelectModalComponent,
        MultiSelectModalComponent,
        SelectFileModalComponent,
        CorrectionModalComponent,
        StudentProfileComponent,
        SignupComponent,
        UserSelectModalComponent,
        LoadingOverlayComponent,
        SettingsComponent,
        ForgottenPasswordComponent,
        SessionSupervisionModalComponent,
        ExamTestComponent,
        ExamMockTestComponent,
        ContactFormComponent,
        SupervisorAttendanceConfirmationModalComponent,
        CorrectorAttendanceConfirmationModalComponent,
        RequestExamsModalComponent,
        ExamCommentsModalComponent,
        CountrySelectComponent,
        PhoneValidatorDirective,
        SessionCommentsModalComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: "toast-bottom-right",
            preventDuplicates: true
        })
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: NgbDateAdapter, useClass: CustomAdapter},
        {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false, // Set true to automatically open a dialog box in case of error
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
