<div class="row">
    <div class="col">
        <div class="form-group form-inline">
            <input class="form-control" type="text" [formControl]="searchTermControl" placeholder="Rechercher" />
            <small class="ml-2">
                Total: {{itemsCount}}
            </small>
        </div>
    </div>
    <div class="col">
        <ngb-pagination (pageChange)="onPageChange($event)" [page]="page" [pageSize]="pageSize"
            [collectionSize]="itemsCount"></ngb-pagination>
    </div>
    <div class="col d-flex justify-content-end">
        <button class="btn btn-link" (click)="add()"><i class="fas fa-plus"></i> Ajouter une nouvelle
            certification</button>
    </div>
</div>

<app-loading-overlay [active]="loading">
    <table class="table table-striped">
        <thead>
            <tr>
                <ng-container>
                    <th scope="col">Titre</th>
                    <th scope="col">Catégorie</th>
                    <th scope="col">Description</th>
                    <th scope="col">Succès</th>
                    <th scope="col">Code</th>
                    <th scope="col">Epreuves</th>
                    <th scope="col">Epreuves blanches</th>
                    <th scope="col">Actions</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let certification of certifications; index as i">
                <ng-container>
                    <td>
                        <ngb-highlight [result]="certification.title" [term]="searchTermControl.value"></ngb-highlight>
                    </td>
                    <td>
                        {{ certification.category.label }}
                    </td>
                    <td>
                        <ngb-highlight [result]="certification.description"
                            [term]="searchTermControl.value"></ngb-highlight>
                    </td>

                    <td *ngIf="certification.nb_passed_exams == null">
                        ??
                    </td>

                    <td *ngIf="certification.nb_passed_exams <= 0">
                        /
                    </td>

                    <td *ngIf="certification.nb_passed_exams > 0">
                        <span *ngIf="certification.success_rate >= 0.5" class="text-success">
                            {{certification.nb_suceeded_exams}}/{{certification.nb_passed_exams}}
                            ({{certification.success_rate | percent}})
                        </span>

                        <span *ngIf="certification.success_rate < 0.5" class="text-danger">
                            {{certification.nb_suceeded_exams}}/{{certification.nb_passed_exams}}
                            ({{certification.success_rate | percent}})
                        </span>
                    </td>

                    <td>
                        <ngb-highlight [result]="certification.code" [term]="searchTermControl.value"></ngb-highlight>
                    </td>
                    <td>
                        <table style="width: 100%;">
                            <tbody>
                                <tr *ngFor="let test of certification.tests" style="background: transparent;">
                                    <td style="padding: 0; vertical-align: middle; border: none;">{{ test.label }}</td>
                                    <td style="padding: 0; vertical-align: middle; border: none;">
                                        <button class="btn btn-link" (click)="editTest(test)">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </td>
                                    <td style="padding: 0; vertical-align: middle; border: none;"
                                        (click)="deleteTest(test)">
                                        <button class="btn btn-link">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr style="background: transparent;">
                                    <td colspan="3"
                                        style="padding: 0; vertical-align: middle; border: none; text-align: right;"
                                        (click)="addTest(certification)">
                                        <button class="btn btn-link">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <table style="width: 100%;">
                            <tbody>
                                <tr *ngFor="let test of certification.mock_tests" style="background: transparent;">
                                    <td style="padding: 0; vertical-align: middle; border: none;">{{ test.label }}</td>
                                    <td style="padding: 0; vertical-align: middle; border: none;">
                                        <button class="btn btn-link" (click)="editMockTest(test)">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </td>
                                    <td style="padding: 0; vertical-align: middle; border: none;"
                                        (click)="deleteMockTest(test)">
                                        <button class="btn btn-link">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr style="background: transparent;">
                                    <td colspan="3"
                                        style="padding: 0; vertical-align: middle; border: none; text-align: right;"
                                        (click)="addMockTest(certification)">
                                        <button class="btn btn-link">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <button class="btn btn-link" (click)="edit(certification)">
                            <i class="fas fa-edit"></i>
                            Modifier
                        </button>
                        <button class="btn btn-link" (click)="delete(certification)">
                            <i class="fas fa-trash"></i>
                            Supprimer
                        </button>
                    </td>
                </ng-container>
            </tr>
        </tbody>
    </table>
</app-loading-overlay>