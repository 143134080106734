import { Exam } from './exam';
import { User } from './user';
import { ExamResultFileCategory } from './examResultFileCategory';

export class ExamResultFile {
    id: number;
    document: string;
    voucher: Exam;
    created_date: Date;
    droppedby: User;
    category: ExamResultFileCategory;

    constructor(data: any) {
        this.id = data.id;
        this.document = data.document;
        if (data.voucher) this.voucher = new Exam(data.voucher);
        if (data.created_date) this.created_date = new Date(data.created_date);
        if (data.droppedby) this.droppedby = new User(data.droppedby);
        if (data.category) this.category = new ExamResultFileCategory(data.category);
    }
}