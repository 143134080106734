export class StudentProfile {
    id: number;
    birthday_date: string;
    birth_zipcode: string;
    sex: string;
    address: string;
    zipcode: string;
    city: string;
    wants_to_be_published: boolean;
    first_name_2: string;
    first_name_3: string;

    constructor (data) {
        if (data) {
            this.id = data.id;
            this.birthday_date = data.birthday_date;
            this.birth_zipcode = data.birth_zipcode;
            this.sex = data.sex;
            this.address = data.address;
            this.zipcode = data.zipcode;
            this.city = data.city;
            this.wants_to_be_published = data.wants_to_be_published;
            this.first_name_2 = data.first_name_2;
            this.first_name_3 = data.first_name_3;
        }
    }
}
