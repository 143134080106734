/* eslint-disable camelcase */
export class SignupDTO {
    email: string;
    password1: string;
    last_name: string;
    first_name: string;
    first_name_2: string;
    first_name_3: string;
    phone_number: string;
    birthday_date: string;
    birth_zipcode: string;
    birth_country: string;
    sex: string;
    address: string;
    zipcode: string;
    city: string;
    code: string;
    file_number: string;

    constructor (data) {
        this.email = data.email;
        this.password1 = data.password1;
        this.last_name = data.last_name;
        this.first_name = data.first_name;
        this.first_name_2 = data.first_name_2;
        this.first_name_3 = data.first_name_3;
        this.phone_number = data.phone_number;
        this.birthday_date = data.birthday_date;
        this.birth_zipcode = data.birth_zipcode;
        this.birth_country = data.birth_country;
        this.sex = data.sex;
        this.address = data.address;
        this.zipcode = data.zipcode;
        this.city = data.city;
        this.code = data.code;

        if (data.file_number) {
            this.file_number = data.file_number;
        } else {
            this.file_number = null;
        }
    }
}
