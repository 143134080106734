<div class="modal-header">
    <h4 class="modal-title">Exam {{exam.voucher_code}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="examForm" *ngIf="!isDataLoading">
        <div class="form-group row">
            <label for="fileNumberInput" class="col-sm-4 col-form-label text-right">Numéro de dossier</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="fileNumberInput" formControlName='fileNumber'>
                <small *ngIf="validationErrors?.fileNumber" class="text-danger">{{ validationErrors.fileNumber }}</small>
            </div>
        </div>
        <div class="form-group row">
            <label for="certificationSelect" class="col-sm-4 col-form-label text-right">Certification</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="certificationId" id="certificationSelect">
                    <option *ngFor="let certification of certifications; let i = index" [value]='certifications[i].id'>
                        {{certifications[i].title}}
                    </option>
                </select>
                <small *ngIf="validationErrors?.certificationId" class="text-danger">{{ validationErrors.certificationId }}</small>
            </div>
        </div>
        <div class="form-group row">
            <label for="sessionSelect" class="col-sm-4 col-form-label text-right">Session</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="sessionId" id="sessionSelect">
                    <option *ngFor="let session of sessions; let i = index" [value]='sessions[i].id'>
                        {{sessions[i].code}}
                    </option>
                </select>
                <small *ngIf="validationErrors?.sessionId" class="text-danger">{{ validationErrors.sessionId }}</small>
            </div>
        </div>
    </form>
    <div *ngIf="error" class="alert alert-danger">
        {{error}}
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">
        Annuler
    </button>
    <button type="button" class="btn btn-outline-success" (click)="validate()">
        <span *ngIf="submitting" class="button-loader"></span>
        Valider
    </button>
</div>
