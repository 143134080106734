import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupsEnum } from '../../../shared/enums/groupsEnum';
import { User } from '../../../shared/models/user';

@Component({
  selector: 'app-user-select-modal',
  templateUrl: './user-select-modal.component.html',
  styleUrls: ['./user-select-modal.component.scss']
})
export class UserSelectModalComponent {

  @Input() group: GroupsEnum;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  onSelectedUser(user: User) {
    this.activeModal.close(user);
  }
}
