import { ExamCenter } from './examCenter';

export class Invoice {
    id: number;
    label: string;
    date: Date;
    document: string; // Should be an URL on get, but a file when posting/putting the invoice
    center: ExamCenter;

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.label = data.label;
            this.document = data.document;
            if (data.date) this.date = new Date(data.date);
            if (data.center) this.center = new ExamCenter(data.center);
        }
    }
}