import { Component, OnInit } from '@angular/core';
import { ExamCenterMemberShip } from '../../../shared/models/examCenterMembership';
import { Observable } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, startWith, map } from 'rxjs/operators';
import { ExamCenterMembershipModalComponent } from '../exam-center-membership-modal/exam-center-membership-modal.component';
import { ExamCenterMembershipService } from '../../../shared/services/exam-center-membership/exam-center-membership.service';

@Component({
  selector: 'app-exam-center-membership-list',
  templateUrl: './exam-center-membership-list.component.html',
  styleUrls: ['./exam-center-membership-list.component.scss']
})
export class ExamCenterMembershipListComponent implements OnInit {
  examCenterMemberships: Array<ExamCenterMemberShip>; // List of all the exams fetched from backend
  examCenterMemberships$: Observable<Array<ExamCenterMemberShip>>; // Search-filtered exam observable
  searchForm: FormGroup;
  loading: boolean;

  constructor(
    private examCenterMembershipService: ExamCenterMembershipService,
    private modalService: NgbModal,
  ) {
    this.searchForm = new FormGroup({
      filter: new FormControl('')
    });
  }

  search(text: string) {
    return this.examCenterMemberships.filter((membership: ExamCenterMemberShip) => {
      const term = text.toLowerCase();
      return membership.reference.toLowerCase().includes(term)
        || membership.center.nom.toLowerCase().includes(term)
    })
  }

  ngOnInit() {
    this.fetchExamCenterMemberships();
  }

  fetchExamCenterMemberships() {
    // Reset the search field
    this.searchForm.get('filter').setValue('');

    this.loading = true;
    this.examCenterMembershipService.list().pipe((take(1))).subscribe((res: Array<ExamCenterMemberShip>) => {
      this.loading = false;
      this.examCenterMemberships = res
      this.examCenterMemberships$ = this.searchForm.get('filter').valueChanges
        .pipe(
          startWith(''),
          map((text: string) => this.search(text))
        )
    });

  }

  add() {
    const modalRef = this.modalService.open(ExamCenterMembershipModalComponent, { size: 'lg' });
    modalRef.componentInstance.membership = null;
    modalRef.result.then((res: ExamCenterMemberShip) => {
      this.fetchExamCenterMemberships();
    });
  }

  edit(membership: ExamCenterMemberShip) {
    const modalRef = this.modalService.open(ExamCenterMembershipModalComponent, { size: 'lg' });
    modalRef.componentInstance.membership = membership;
    modalRef.result.then((res: ExamCenterMemberShip) => {
      this.fetchExamCenterMemberships();
    });
  }

  delete(membership: ExamCenterMemberShip) {
    let res = confirm("Êtes-vous sûr de vouloir supprimer cette adhésion ?")
    if (res) {
      this.examCenterMembershipService.destroy(membership.id).pipe(take(1)).subscribe((res: any) => {
        this.fetchExamCenterMemberships();
      });
    }
  }
}
