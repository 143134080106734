import {Component, Input, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {take} from "rxjs/operators";
import {StudentProfile} from "src/app/shared/models/studentProfile";
import {User} from "../../../shared/models/user";
import {UserService} from "../../../shared/services/user/user.service";

@Component({
    "selector": "app-student-profile-modal",
    "templateUrl": "./student-profile-modal.component.html",
    "styleUrls": ["./student-profile-modal.component.scss"]
})
export class StudentProfileModalComponent implements OnInit {

    @Input() student: User;

    studentForm: FormGroup;
    isDataLoading: boolean = true;
    error: string;
    submitting: boolean = false;

    constructor (
        public activeModal: NgbActiveModal,
        private userService: UserService
    ) {
        this.studentForm = new FormGroup({
            "first_name": new FormControl(
                "",
                Validators.required
            ),
            "first_name_2": new FormControl(
                "",
            ),
            "first_name_3": new FormControl(
                "",
            ),
            "last_name": new FormControl(
                "",
                Validators.required
            ),
            "email": new FormControl(
                "",
                Validators.required
            ),
            "phone_number": new FormControl(""),
            "birthday_date": new FormControl(
                "",
                Validators.required
            ),
            "birth_zipcode": new FormControl(
                "",
                Validators.required
            ),
            "sex": new FormControl(
                null,
                Validators.required
            ),
            "address": new FormControl(
                "",
                Validators.required
            ),
            "zipcode": new FormControl(
                "",
                Validators.required
            ),
            "city": new FormControl(
                "",
                Validators.required
            )
        });
        this.isDataLoading = true;
    }

    private resetForm () {
        if (this.student !== null) {
            this.studentForm.setValue({
                "first_name": this.student.first_name,
                "last_name": this.student.last_name,
                "email": this.student.email,
                "phone_number": this.student.phone_number,
                "birthday_date": new Date(this.student.student_profile.birthday_date),
                "birth_zipcode": this.student.student_profile.birth_zipcode,
                "sex": this.student.student_profile.sex
                    ? this.student.student_profile.sex
                    : null,
                "address": this.student.student_profile.address,
                "zipcode": this.student.student_profile.zipcode,
                "city": this.student.student_profile.city,
                "first_name_2": this.student.student_profile.first_name_2,
                "first_name_3": this.student.student_profile.first_name_3,
            });
        }
    }

    ngOnInit () {
        this.resetForm();
        this.isDataLoading = false;
        this.submitting = false;
    }

    validate () {
        this.submitting = true;

        const formattedBirthDate = this.studentForm.value.birthday_date ? this.studentForm.value.birthday_date.getFullYear() + '-' + ('0' + (this.studentForm.value.birthday_date.getMonth() + 1)).slice(-2) + '-' + ('0' + this.studentForm.value.birthday_date.getDate()).slice(-2) : null;

        // Trigger update if it's an edit

        if (this.student) {

            const fieldsToUpdate = {
                "first_name": this.studentForm.value.first_name,
                "last_name": this.studentForm.value.last_name,
                "email": this.studentForm.value.email,
                "phone_number": this.studentForm.value.phone_number,
                "student_profile": {
                    "birthday_date": formattedBirthDate,
                    "birth_zipcode": this.studentForm.value.birth_zipcode,
                    "sex": this.studentForm.value.sex,
                    "address": this.studentForm.value.address,
                    "zipcode": this.studentForm.value.zipcode,
                    "city": this.studentForm.value.city,
                    "first_name_2": this.studentForm.value.first_name_2 ? this.studentForm.value.first_name_2 : null,
                    "first_name_3": this.studentForm.value.first_name_3 ? this.studentForm.value.first_name_3 : null,
                }
            };

            this.userService.partialUpdate(
                this.student.id,
                fieldsToUpdate
            ).pipe(take(1)).
                subscribe(
                    (res: User) => {
                        this.student = res;
                        this.resetForm();
                        this.activeModal.close(this.student);
                        this.submitting = false;
                    },
                    (err) => {
                        this.error = "Une erreur est survenue lors de la mise à jour de votre profil";
                        this.submitting = false;
                        throw err;
                    }
                );
        }

        // Trigger a create otherwise

        else {

            const submitStudent = new User(this.student);
            submitStudent.student_profile = new StudentProfile(this.student?.student_profile);
            submitStudent.first_name = this.studentForm.value.first_name;
            submitStudent.last_name = this.studentForm.value.last_name;
            submitStudent.email = this.studentForm.value.email;
            submitStudent.phone_number = this.studentForm.value.phone_number;
            submitStudent.student_profile.birthday_date = formattedBirthDate;
            submitStudent.student_profile.birth_zipcode = this.studentForm.value.birth_zipcode;
            submitStudent.student_profile.sex = this.studentForm.value.sex;
            submitStudent.student_profile.address = this.studentForm.value.address;
            submitStudent.student_profile.zipcode = this.studentForm.value.zipcode;
            submitStudent.student_profile.city = this.studentForm.value.city;
            submitStudent.student_profile.first_name_2 = this.studentForm.value.first_name_2 ? this.studentForm.value.first_name_2 : null;
            submitStudent.student_profile.first_name_3 = this.studentForm.value.first_name_3 ? this.studentForm.value.first_name_3 : null;

            this.userService.create(submitStudent).pipe(take(1)).
                subscribe(
                    (res: User) => {
                        this.student = res;
                        this.resetForm();
                        this.activeModal.close(this.student);
                        this.submitting = false;
                    },
                    (err) => {
                        this.error = "Une erreur est survenue lors de la création de la session";
                        this.submitting = false;
                        throw err;
                    }
                );
        }
    }
}
