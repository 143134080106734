import {Component, OnInit} from "@angular/core";
import {MiscFile} from "../../../shared/models/miscFile";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {FormGroup, FormControl} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MiscFileService} from "../../../shared/services/misc-file/misc-file.service";
import {take, startWith, map} from "rxjs/operators";
import {MiscFileModalComponent} from "../misc-file-modal/misc-file-modal.component";
import {User} from "../../../shared/models/user";
import {UserService} from "../../../shared/services/user/user.service";
import {FileDownloadHelper} from "../../../shared/helpers/file-download.helper";
import {HttpResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";


@Component({
    selector: "app-misc-file-list",
    templateUrl: "./misc-file-list.component.html",
    styleUrls: ["./misc-file-list.component.scss"]
})
export class MiscFileListComponent implements OnInit {

    baseUrl = environment.baseUrl;

    // List of all the exams fetched from backend
    miscFiles: Array<MiscFile>;

    // Search-filtered exam observable
    miscFiles$: Observable<Array<MiscFile>>;
    searchForm: FormGroup;
    user: User;
    loading: boolean;
    retrievingDocumentForMiscFileId: number = null;

    constructor (
        private miscFileService: MiscFileService,
        private modalService: NgbModal,
        private userService: UserService,
        private toastr: ToastrService
    ) {
        this.searchForm = new FormGroup({
            filter: new FormControl("")
        });
    }

    search (text: string) {
        return this.miscFiles.filter((miscfile: MiscFile) => {
            const term = text.toLowerCase();

            // TODO: add other fields
            return miscfile.name.toLowerCase().includes(term);
        });
    }

    ngOnInit () {
        this.fetchMiscFiles();

        this.userService.getMe().pipe(take(1)).
            subscribe((user: User) => {
                this.user = user;
            });
    }

    fetchMiscFiles () {

        // Reset the search field
        this.searchForm.get("filter").setValue("");

        this.loading = true;
        this.miscFileService.list().pipe(take(1)).
            subscribe((res: Array<MiscFile>) => {
                this.loading = false;
                this.miscFiles = res;
                this.miscFiles$ = this.searchForm.get("filter").valueChanges.
                    pipe(
                        startWith(""),
                        map((text: string) => this.search(text))
                    );
            });
    }

    add () {
        const modalRef = this.modalService.open(
            MiscFileModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.miscFile = null;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        modalRef.result.then((res: MiscFile) => {
            this.fetchMiscFiles();
        });
    }

    edit (miscFile: MiscFile) {
        const modalRef = this.modalService.open(
            MiscFileModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.miscFile = miscFile;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        modalRef.result.then((res: MiscFile) => {
            this.fetchMiscFiles();
        });
    }

    delete (miscFile: MiscFile) {
        const res = confirm("Êtes-vous sûr de vouloir supprimer ce document ?");
        if (res) {
            this.miscFileService.destroy(miscFile.id).pipe(take(1)).
                subscribe(() => {
                    this.fetchMiscFiles();
                });
        }
    }

    showDocument (miscFile: MiscFile) {
        this.retrievingDocumentForMiscFileId = miscFile.id;

        this.miscFileService.retrieveFile(miscFile.id).pipe(take(1)).
            subscribe(
                (response: HttpResponse<Blob>) => {
                    this.retrievingDocumentForMiscFileId = null;
                    FileDownloadHelper.downloadBlobFromResponse(response);
                },
                () => {
                    this.retrievingDocumentForMiscFileId = null;
                    this.toastr.error(
                        "Contactez l'administrateur pour plus d'informations",
                        "Fichier introuvable"
                    );
                }
            );
    }
}
