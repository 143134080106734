/* eslint-disable camelcase */
import {User} from "./user";
import {ExamSession} from "./examSession";
import {ExamResultFile} from "./examResultFile";
import {Invoice} from "./invoice";
import {Certification} from "./certification";
import {ExamResultValue} from "./examResultValue";
import {ExamCenter} from "./examCenter";
import {ExamComment} from "./examComment";

export class Exam {

    id: number;

    file_number: string;

    voucher_code: string;

    session: ExamSession;

    student: User;

    success: boolean;

    examResultFiles: Array<ExamResultFile>;

    invoice: Invoice;

    certification: Certification;

    diplome: string;

    examResultValues: Array<ExamResultValue>;

    locked: boolean;

    can_pass: boolean;

    can_pass_today: boolean;

    start_date: Date;

    exam_center: ExamCenter;

    comments: Array<ExamComment>;

    constructor (data?) {
        if (data) {
            this.build(data);
        }
    }

    // eslint-disable-next-line max-statements
    build (data) {
        this.id = data.id;
        this.voucher_code = data.voucher_code;
        this.file_number = data.file_number;

        if (data.session) {
            this.session = new ExamSession(data.session);
        }

        if (data.student) {
            this.student = new User(data.student);
        }

        this.success = data.success;

        if (data.exam_result_files) {
            this.examResultFiles = data.exam_result_files.map((f: object) => new ExamResultFile(f));
        }

        if (data.invoice) {
            this.invoice = new Invoice(data.invoice);
        }

        if (data.certification) {
            this.certification = new Certification(data.certification);
        }

        if (data.exam_result_values) {
            this.examResultValues = data.exam_result_values.map((v: object) => new ExamResultValue(v));
        }

        this.diplome = data.diplome;
        this.locked = data.locked;
        this.can_pass = data.can_pass;

        if (data.start_date) {
            this.start_date = new Date(data.start_date);
        }

        this.can_pass_today = data.can_pass_today;

        if (data.exam_center) {
            this.exam_center = new ExamCenter(data.exam_center);
        }

        if (data.comments) {
            this.comments = data.comments.map((c: unknown) => new ExamComment(c));
        }
    }

    get hasResults () {
        return this.examResultFiles.length > 0;
    }

    get canBeReset () {
        return !this.hasResults && !this.locked && !this.diplome && this.examResultValues.length === 0;
    }

    get canBeCorrected () {
        return this.session.wasOpen;
    }

    get isCorrected () {

        for (let index = 0; index < this.examResultValues.length; index += 1) {

            if (this.examResultValues[index].success !== null) {

                return true;

            }

        }

        return false;

    }

    toString (): string {
        return this.voucher_code;
    }

}
