import { Component, Input } from '@angular/core';
import { Exam } from '../../../shared/models/exam';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-exam-results-modal',
  templateUrl: './exam-results-modal.component.html',
  styleUrls: ['./exam-results-modal.component.scss']
})
export class ExamResultsModalComponent {

  @Input() exam: Exam;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

}
