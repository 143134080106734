import {Injectable} from "@angular/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {CertificationTest} from "../../models/certificationTest";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class CertificationTestService {

    constructor (private http: HttpClient) { }

    update (test: CertificationTest) {
        return this.http.put<CertificationTest>(
            `${environment.baseApiUrl}/certification_tests/${test.id}/`,
            test
        ).pipe(map((res) => new CertificationTest(res)));
    }

    create (test: CertificationTest) {
        return this.http.post<CertificationTest>(
            `${environment.baseApiUrl}/certification_tests/`,
            test
        ).pipe(map((res) => new CertificationTest(res)));
    }

    destroy (id: number) {
        return this.http.delete<CertificationTest>(`${environment.baseApiUrl}/certification_tests/${id}/`);
    }

    uploadFile (certificationTestId: number, document: File): Observable<any> {
        const formData = new FormData();
        formData.append(
            "file",
            document
        );
        return this.http.put(
            `${environment.baseApiUrl}/certification_tests/${certificationTestId}/upload_file/`,
            formData,
            {reportProgress: true, observe: "events"}
        );
    }

    removeFile (certificationTestId: number) {
        return this.http.delete<CertificationTest>(`${environment.baseApiUrl}/certification_tests/${certificationTestId}/remove_file/`);
    }

    fetchFile (certificationTestId: number): Observable<HttpResponse<Blob>> {
        return this.http.get(
            `${environment.baseApiUrl}/certification_tests/${certificationTestId}/retrieve_file/`,
            {
                responseType: "blob",
                observe: "response" // Observe the full response
            }
        );
    }
}
