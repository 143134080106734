import {Component, OnInit, Input, OnDestroy} from "@angular/core";
import {MiscFile} from "../../../shared/models/miscFile";
import {FormGroup, FormControl} from "@angular/forms";
import {Group} from "../../../shared/models/group";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {MiscFileService} from "../../../shared/services/misc-file/misc-file.service";
import {GroupService} from "../../../shared/services/group/group.service";
import {take, takeUntil} from "rxjs/operators";
import {MiscFileCategory} from "../../../shared/models/miscFileCategory";
import {MiscFileCategoryService} from "../../../shared/services/misc-file-category/misc-file-category.service";
import {GroupsEnum} from "../../../shared/enums/groupsEnum";
import {environment} from "../../../../environments/environment";
import {Subject} from "rxjs";
import {HttpEvent, HttpEventType, HttpResponse} from "@angular/common/http";
import {FileDownloadHelper} from "../../../shared/helpers/file-download.helper";
import {ToastrService} from "ngx-toastr";


@Component({
    selector: "app-misc-file-modal",
    templateUrl: "./misc-file-modal.component.html",
    styleUrls: ["./misc-file-modal.component.scss"]
})
export class MiscFileModalComponent implements OnInit, OnDestroy {

    @Input() miscFile: MiscFile;

    baseUrl = environment.baseUrl;
    miscFileForm: FormGroup;
    groups: Array<Group>;
    miscFileCategories: Array<MiscFileCategory>;

    // Initial loading of the form
    isDataLoading: boolean = true;
    error: string;
    document: File;

    // Modal validation loading (when uploading the file)
    loading: boolean;
    unsubscribe = new Subject<void>();
    uploadProgress: number;
    retrievingDocument: boolean = false;

    constructor (
        public activeModal: NgbActiveModal,
        public groupService: GroupService,
        private miscFileService: MiscFileService,
        private miscFileCategoryService: MiscFileCategoryService,
        private toastr: ToastrService
    ) {
        this.miscFileForm = new FormGroup({
            name: new FormControl(""),
            date: new FormControl(""),
            category: new FormControl(""),
            allow_supervisor: new FormControl(""),
            allow_corrector: new FormControl(""),
            allow_student: new FormControl(""),
            allow_exam_center_boss: new FormControl("")
        });
        this.isDataLoading = true;
        this.loading = false;
    }

    ngOnInit () {
        this.groupService.list().pipe(take(1)).
            subscribe((groups: Array<Group>) => {
                this.groups = groups;
            });

        this.miscFileCategoryService.list().pipe(take(1)).
            subscribe((categories: Array<MiscFileCategory>) => {
                this.miscFileCategories = categories;
                this.resetForm();
                this.isDataLoading = false;
            });
    }

    onFileChange (event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.document = file;
        }
    }

    /*
     * Function to instanciate the frontend form
     * Call only after all data is fetched
     */
    private resetForm () {

        // Populate the form if there's an input
        if (this.miscFile != null) {
            this.miscFileForm.setValue({
                name: this.miscFile.name,
                date: this.miscFile.date,
                category: this.miscFile.category
                    ? this.miscFile.category.id
                    : null,
                allow_supervisor: this.miscFile.allowsSupervisor,
                allow_corrector: this.miscFile.allowsCorrector,
                allow_student: this.miscFile.allowsStudent,
                allow_exam_center_boss: this.miscFile.allowsExamCenterBoss
            });
        }
    }

    // On destroy clear the form subscription
    ngOnDestroy (): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    get is_student () {
        return this.miscFileForm.get("is_student");
    }

    getGroupFromEnum (groupEnum: GroupsEnum): Group {
        return this.groups.find((g: Group) => g.name == groupEnum);
    }


    validate () {
        this.loading = true;
        this.uploadProgress = 0;
        const submitMiscFile = new MiscFile(this.miscFile);
        submitMiscFile.name = this.miscFileForm.value.name;
        submitMiscFile.date = this.miscFileForm.value.date;
        submitMiscFile.category = this.miscFileCategories.find((c: MiscFileCategory) => c.id == this.miscFileForm.value.category);

        // Allow groups depending on the checkboxes
        submitMiscFile.groups = [];
        if (this.miscFileForm.value.allow_supervisor) {
            submitMiscFile.groups.push(this.getGroupFromEnum(GroupsEnum.SUPERVISOR));
        }
        if (this.miscFileForm.value.allow_corrector) {
            submitMiscFile.groups.push(this.getGroupFromEnum(GroupsEnum.CORRECTOR));
        }
        if (this.miscFileForm.value.allow_student) {
            submitMiscFile.groups.push(this.getGroupFromEnum(GroupsEnum.STUDENT));
        }
        if (this.miscFileForm.value.allow_exam_center_boss) {
            submitMiscFile.groups.push(this.getGroupFromEnum(GroupsEnum.EXAM_CENTER_BOSS));
        }

        // Trigger update if it's an edit
        if (this.miscFile) {
            this.miscFileService.update(submitMiscFile).pipe(take(1)).
                subscribe(
                    (res: MiscFile) => {
                        this.uploadFileToMiscFile(res);
                    },
                    (err) => {
                        this.loading = false;
                        this.error = "Une erreur est survenue lors de la modification du document";
                        throw err;
                    }
                );
        }

        // Trigger a save otherwise
        else {
            this.miscFileService.create(submitMiscFile).pipe(take(1)).
                subscribe(
                    (res: MiscFile) => {
                        this.uploadFileToMiscFile(res);
                    },
                    (err) => {
                        this.loading = false;
                        this.error = "Une erreur est survenue lors de la création du document";
                        throw err;
                    }
                );
        }
    }

    uploadFileToMiscFile (miscFile: MiscFile) {
        this.miscFile = miscFile;

        // If there's a document to upload, upload it then put it back in the misc file object
        if (this.document) {
            this.miscFileService.uploadFile(
                this.miscFile.id,
                this.document
            ).pipe(takeUntil(this.unsubscribe)).
                subscribe((event: HttpEvent<any>) => {
                // Look for upload progress events.
                    if (event.type === HttpEventType.UploadProgress) {
                        this.uploadProgress = Math.round(100 * event.loaded / event.total);
                    } else if (event instanceof HttpResponse) {
                        this.miscFile.document = event.body;
                        this.resetFormCloseModal();
                    }
                });
        } else {
            this.resetFormCloseModal();
        }
    }

    resetFormCloseModal () {
        this.resetForm();
        this.activeModal.close(this.miscFile);
    }

    showDocument () {
        this.retrievingDocument = true;

        this.miscFileService.retrieveFile(this.miscFile.id).pipe(take(1)).
            subscribe(
                (response: HttpResponse<Blob>) => {
                    this.retrievingDocument = false;
                    FileDownloadHelper.downloadBlobFromResponse(response);
                },
                () => {
                    this.retrievingDocument = false;
                    this.toastr.error(
                        "Contactez l'administrateur pour plus d'informations",
                        "Fichier introuvable"
                    );
                }
            );
    }
}
