import { MiscFileCategory } from './miscFileCategory';
import { Group } from './group';
import { GroupsEnum } from '../enums/groupsEnum';

export class MiscFile {
    id: number;
    name: string;
    document: string;
    date: Date;
    category: MiscFileCategory;
    groups: Array<Group>;

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.document = data.document;
            if (data.date) this.date = new Date(data.date);
            if (data.category) this.category = new MiscFileCategory(data.category);
            if (data.groups) this.groups = data.groups.map((d: any) => new Group(d));
        }
    }

    get groupsString() {
        if (!this.groups || this.groups.length == 0) return 'Aucun';
        return this.groups.map((g: Group) => g.friendlyName).join(', ');
    }

    get allowsSupervisor(): boolean {
        if (!this.groups) return false;
        if (!this.groups.length) return false;
        return this.groups.some((g: Group) => g.name == GroupsEnum.SUPERVISOR);
    }

    get allowsCorrector(): boolean {
        if (!this.groups) return false;
        if (!this.groups.length) return false;
        return this.groups.some((g: Group) => g.name == GroupsEnum.CORRECTOR);
    }

    get allowsStudent(): boolean {
        if (!this.groups) return false;
        if (!this.groups.length) return false;
        return this.groups.some((g: Group) => g.name == GroupsEnum.STUDENT);
    }

    get allowsExamCenterBoss(): boolean {
        if (!this.groups) return false;
        if (!this.groups.length) return false;
        return this.groups.some((g: Group) => g.name == GroupsEnum.EXAM_CENTER_BOSS);
    }
}