<div *ngIf="!exam?.can_pass">
    <div class="row">
        <div class="col d-flex justify-content-center">
            En attente de l'autorisation du superviseur...
        </div>
    </div>
    <div class="row">
        <div class="col d-flex justify-content-center">
            <i class="fas fa-spinner fa-pulse"></i>
        </div>
    </div>
</div>
<div *ngIf="error">
    <div class="row">
        <div class="col d-flex justify-content-center">
            <span class="alert alert-warning">
                {{error}}
            </span>
        </div>
    </div>
</div>
<div *ngIf="exam?.can_pass && embeddingQcmURL">
    <iframe frameBorder="0" class="w-100 vh-100" [src]="embeddingQcmURL"></iframe>
</div>
<div *ngIf="exam?.can_pass && document" class="d-flex flex-column align-items-center">
    <hr />
    <div style="max-width: 800px; width: 100%;">
        <div class="d-flex">
            <h1 style="font-size: 28px; font-weight: normal; line-height: 1.25em;">Examen: {{test.description}}</h1>
            <a [href]="document" target="_blank" class="btn btn-primary" style="margin-left: auto;"><strong>Démarrer</strong></a>
        </div>
        <hr />
        <p><strong>Temps limite pour l'examen: </strong>{{test.duration_minutes_qcm}} minutes</p>
    </div>
</div>
<div *ngIf="exam?.can_pass && !document && !error && !embeddingQcmURL">
    <div class="row">
        <div class="col d-flex justify-content-center align-items-center">
            <span class="button-loader"></span>Chargement...
        </div>
    </div>
</div>