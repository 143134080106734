export class ExamResultFileCategory {
    id: number;
    name: string;
    title: string;

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.title = data.title;
    }
}