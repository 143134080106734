<div class="modal-header">
    <h4 class="modal-title">Résultat de l'examen {{exam.voucher_code}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="correctionForm" *ngIf="!isDataLoading">
        <div formArrayName="examResultValues">
            <div
                *ngFor="let test of certification.tests; let i = index; first as isFirst;">
                <ng-container [formGroupName]="i">
                    <hr *ngIf="!isFirst">
                    <div class="form-group row">
                        <label for="test{{i}}input"
                            class="col-sm-4 col-form-label text-right">{{test.description}}</label>
                        <div class="col-sm-2">
                            <input type="number" class="form-control" id="test{{i}}input" formControlName="value">
                        </div>
                        <div class="col-sm-1">
                            <input type="checkbox" class="form-control" id="test{{i}}success" formControlName="success">
                        </div>
                        <div class="col-form-label">
                            <span *ngIf="!getTestSuccess(i)" class="text-danger">Échec</span>
                            <span *ngIf="getTestSuccess(i)" class="text-success">Succès</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="commentaireInput" class="col-sm-4 col-form-label text-right">Commentaires</label>
                        <div class="col-sm-8">
                            <textarea type="text" rows="8" class="form-control" id="commentaireInput"
                                formControlName="commentaire"></textarea>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4 col-form-label text-right">Résultat final</label>
            <div class="col-sm-1">
                <input type="checkbox" class="form-control" id="exam-success" formControlName="success">
            </div>
            <div class="col-form-label">
                <span *ngIf="!correctionForm.controls.success.value" class="text-danger">Échec</span>
                <span *ngIf="correctionForm.controls.success.value" class="text-success">Succès</span>
            </div>
        </div>
        <div class="form-group">
            <label class="col-sm-4 col-form-label text-right">Fichiers associés</label>
            <label class="col-sm-8 col-form-label">
                <span>
                    {{exam.examResultFiles ? exam.examResultFiles.length : 0}}
                </span>
            </label>
        </div>
        <div class="form-group row" *ngIf="user.isCorrector && !exam.locked">
            <div class="col-sm-4"></div>
            <div class="col-sm-8">
                <button class="btn btn-primary" (click)="openFilesModal(exam)">Gérer les fichiers de correction</button>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-4"></div>
            <div class="col-sm-8">
                <div class="form-check">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        id="notifyUsersInput"
                        formControlName='notifyUsers'>
                    <label class="form-check-label">
                        Notifier les personnes concernées par email
                    </label>
                </div>
            </div>
        </div>
    </form>
    <div *ngIf="error" class="alert alert-danger">
        {{error}}
    </div>
</div>
<div class="modal-footer">
    <ng-container *ngIf="!exam.locked">
        <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">Annuler</button>
        <button
            *ngIf="user?.isCorrector"
            type="button"
            class="btn btn-outline-success"
            [disabled]="loading"
            (click)="validate()">
            <span *ngIf="loading" class="button-loader"></span>
            Valider
        </button>
        <button
            *ngIf="user?.isStaff"
            type="button"
            class="btn btn-success"
            (click)="validate()"
            [disabled]="loading">
            <span *ngIf="loading" class="button-loader"></span>
            Valider définitivement la correction
        </button>
    </ng-container>
    <ng-container *ngIf="exam.locked">
        <div class="alert alert-warning" >
            Cette correction a été validée et n'est plus modifiable
        </div>
    </ng-container>
</div>
