import {Component} from "@angular/core";
import {AuthenticationService} from "./shared/services/authentication/authentication.service";
import {UserService} from "./shared/services/user/user.service";
import {User} from "./shared/models/user";
import {take} from "rxjs/operators";
import {Group} from "./shared/models/group";

@Component({
    "selector": "app-root",
    "templateUrl": "./app.component.html",
    "styleUrls": ["./app.component.scss"]
})
export class AppComponent {
    isLoggedIn: boolean = false;
    user: User;

    constructor (
        private authenticationService: AuthenticationService,
        private userService: UserService
    ) { }

    ngOnInit () {

        // Subscribe to the "isLoggedIn" subject => will update the isLoggedIn property in the app component if it changes
        this.authenticationService.getIsLoggedInSubject().subscribe((isLoggedIn) => {
            if (isLoggedIn) {
                this.userService.getMe().pipe(take(1)).
                    subscribe((user) => {
                        this.user = user;
                    });
                this.isLoggedIn = true;
            } else if (this.isLoggedIn != false) {
                this.isLoggedIn = false;
                window.location.href = "/login";
            }
        });
    }

    logout () {
        this.authenticationService.logout();

    }

    get userGroups (): string {
        const friendlyGroupNames = this.user.groups.map((g: Group) => g.friendlyName);
        return friendlyGroupNames.join(", ");
    }
}
