import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MiscFileCategory } from '../../models/miscFileCategory';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MiscFileCategoryService {

  constructor(private http: HttpClient) { }

  list(): Observable<Array<MiscFileCategory>> {
    return this.http.get<Array<MiscFileCategory>>(`${environment.baseApiUrl}/misc_file_categories/`)
      .pipe(map((res: any) => res.map((d: any) => new MiscFileCategory(d))));
  }
}
