import {take, debounceTime} from "rxjs/operators";

import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

import {Component, OnInit} from "@angular/core";
import {FormControl} from "@angular/forms";

import {Certification} from "../../../shared/models/certification";
import {CertificationTest} from "../../../shared/models/certificationTest";
import {CertificationMockTest} from "../../../shared/models/certificationMockTest";

import {CertificationService} from "../../../shared/services/certification/certification.service";
import {CertificationTestService} from "../../../shared/services/certification-test/certification-test.service";
import {CertificationMockTestService} from "../../../shared/services/certification-mock-test/certification-mock-test.service";

import {CertificationModalComponent} from "../certification-modal/certification-modal.component";
import {CertificationTestModalComponent} from "../certification-test-modal/certification-test-modal.component";
import {CertificationMockTestModalComponent} from "../certification-mock-test-modal/certification-mock-test-modal.component";

import {environment} from "../../../../environments/environment";


@Component({
    selector: "app-certification-list",
    templateUrl: "./certification-list.component.html",
    styleUrls: ["./certification-list.component.scss"]
})
export class CertificationListComponent implements OnInit {

    // List of all the exams fetched from backend
    certifications: Array<Certification>;
    searchTermControl: FormControl;
    loading: boolean;

    // Paginiation related
    page: number;
    pageSize: number;
    itemsCount: number;

    constructor (
        private certificationService: CertificationService,
        private certificationTestService: CertificationTestService,
        private certificationMockTestService: CertificationMockTestService,
        private modalService: NgbModal
    ) {
        this.searchTermControl = new FormControl("");
        this.page = 1;
        this.pageSize = environment.pageSize;
        this.loading = false;
    }

    ngOnInit () {
        // Subscribe to the change of value of the search field
        this.searchTermControl.valueChanges.pipe(debounceTime(1000)).
            subscribe(() => {
                this.fetchItemCount();
                this.fetchCurrentPage();
            });


        this.fetchCurrentPage();
        this.fetchItemCount();
    }

    fetchCurrentPage () {
        this.loading = true;
        const searchTerm = this.searchTermControl.value;
        this.certificationService.list(
            false,
            this.page,
            searchTerm
        ).pipe(take(1)).
            subscribe((res: Array<Certification>) => {
                this.certifications = res;
                this.loading = false;
            });
    }

    fetchItemCount () {
        const searchTerm = this.searchTermControl.value;
        this.certificationService.count(searchTerm).pipe(take(1)).
            subscribe((count: number) => {
                this.itemsCount = count;
            });
    }

    onPageChange (newPage: number) {
        this.page = newPage;
        this.fetchCurrentPage();
    }

    add () {
        const modalRef = this.modalService.open(
            CertificationModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.certification = null;
        modalRef.result.then(() => {
            this.fetchCurrentPage();
        }).catch(() => {
        // Dummy catch to avoid ugly errors in console
        });
    }

    edit (certification: Certification) {
        const modalRef = this.modalService.open(
            CertificationModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.certification = certification;
        modalRef.result.then(() => {
            this.fetchCurrentPage();
        }).catch(() => {
        // Dummy catch to avoid ugly errors in console
        });
    }

    delete (certification: Certification) {
        const res = confirm("Êtes-vous sûr de vouloir supprimer cette certification ?");
        if (res) {
            this.certificationService.destroy(certification.id).pipe(take(1)).
                subscribe(() => {
                    this.fetchCurrentPage();
                });
        }
    }

    addTest (certification: Certification) {
        const modalRef = this.modalService.open(
            CertificationTestModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.test = null;
        modalRef.componentInstance.certification = certification;
        modalRef.result.then(() => {
            this.fetchCurrentPage();
        }).catch(() => {
        // Dummy catch to avoid ugly errors in console
        });
    }

    addMockTest (certification: Certification) {
        const modalRef = this.modalService.open(
            CertificationMockTestModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.test = null;
        modalRef.componentInstance.certification = certification;
        modalRef.result.then(() => {
            this.fetchCurrentPage();
        }).catch(() => {
        // Dummy catch to avoid ugly errors in console
        });
    }

    editTest (test: CertificationTest) {
        const modalRef = this.modalService.open(
            CertificationTestModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.test = test;
        modalRef.result.then(() => {
            this.fetchCurrentPage();
        }).catch(() => {
        // Dummy catch to avoid ugly errors in console
        });
    }

    editMockTest (test: CertificationMockTest) {
        const modalRef = this.modalService.open(
            CertificationMockTestModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.test = test;
        modalRef.result.then(() => {
            this.fetchCurrentPage();
        }).catch(() => {
        // Dummy catch to avoid ugly errors in console
        });
    }

    deleteTest (test: CertificationTest) {
        const res = confirm("Êtes-vous sûr de vouloir supprimer cette épreuve blanche ?");
        if (res) {
            this.certificationTestService.destroy(test.id).pipe(take(1)).
                subscribe(() => {
                    this.fetchCurrentPage();
                });
        }
    }

    deleteMockTest (test: CertificationMockTest) {
        const res = confirm("Êtes-vous sûr de vouloir supprimer cette épreuve blanche ?");
        if (res) {
            this.certificationMockTestService.destroy(test.id).pipe(take(1)).
                subscribe(() => {
                    this.fetchCurrentPage();
                });
        }
    }
}
