import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-select-modal',
  templateUrl: './select-modal.component.html',
  styleUrls: ['./select-modal.component.scss']
})
export class SelectModalComponent implements OnInit {

  selectForm: FormGroup;
  @Input() title;
  @Input() options: Array<any>;
  @Input() defaultOption: any;
  isDataLoading: boolean = true;
  error: string;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
    this.selectForm = new FormGroup({
      selected: new FormControl(''),
    });
    this.isDataLoading = true;
  }

  ngOnInit() {
    this.resetForm();
  }

  private resetForm() {

    // Populate the form if there's an input
    if (this.defaultOption != null) {
      this.selectForm.setValue({
        selected: this.defaultOption.id
      });
    }
  }

  validate() {
    let selected = this.options.find((o: any) => o.id == this.selectForm.value.selected)
    this.activeModal.close(selected);
  }
}
