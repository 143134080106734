import {Injectable} from "@angular/core";
import {Certification} from "../../models/certification";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class CertificationService {

    constructor (private http: HttpClient) { }

    list (short: boolean = false, page: number = null, term = null): Observable<Array<Certification>> {
        let params = new HttpParams();

        if (short) {
            params = params.set(
                "short",
                "True"
            );
        }

        if (page) {
            params = params.set(
                "page",
                `${page}`
            );
        }

        if (term) {
            params = params.set(
                "term",
                `${term}`
            );
        }

        return this.http.get<Array<Certification>>(
            `${environment.baseApiUrl}/certifications/`,
            {params}
        ).
            pipe(map((res) => res.map((d) => new Certification(d))));
    }

    short () {
        return this.http.get<Array<Certification>>(`${environment.baseApiUrl}/certifications/short/`).
            pipe(map((res) => res.map((d) => new Certification(d))));
    }

    retrieve (id: number): Observable<Certification> {
        return this.http.get<Certification>(`${environment.baseApiUrl}/certifications/${id}/`).pipe(map((res) => new Certification(res)));
    }

    count (term: string): Observable<number> {
        let params = new HttpParams();
        if (term) {
            params = params.set(
                "term",
                `${term}`
            );
        }
        return this.http.get<number>(
            `${environment.baseApiUrl}/certifications/count/`,
            {params}
        );
    }

    update (certification: Certification) {
        return this.http.put<Certification>(
            `${environment.baseApiUrl}/certifications/${certification.id}/`,
            certification
        ).pipe(map((res) => new Certification(res)));
    }

    partialUpdate (certification: Certification) {
        return this.http.patch<Certification>(
            `${environment.baseApiUrl}/certifications/${certification.id}/`,
            certification
        ).pipe(map((res) => new Certification(res)));
    }

    create (certification: Certification) {
        return this.http.post<Certification>(
            `${environment.baseApiUrl}/certifications/`,
            certification
        ).pipe(map((res) => new Certification(res)));
    }

    destroy (id: number) {
        return this.http.delete<Certification>(`${environment.baseApiUrl}/certifications/${id}/`);
    }
}
