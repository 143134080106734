import {Component, Input} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {take} from "rxjs/operators";
import {Exam} from "../../../shared/models/exam";
import {ExamSession} from "../../../shared/models/examSession";
import {ExamService} from "../../../shared/services/exam/exam.service";
import {ExamSessionService} from "src/app/shared/services/exam-session/exam-session.service";

@Component({
    selector: "app-session-supervision-modal",
    templateUrl: "./session-supervision-modal.component.html",
    styleUrls: ["./session-supervision-modal.component.scss"]
})
export class SessionSupervisionModalComponent {

  @Input() examSession: ExamSession;
  error: string;
  loading: boolean = false;
  openingSession: boolean = false;
  closingSession: boolean = false;

  constructor (
    public activeModal: NgbActiveModal,
    public examSerivce: ExamService,
    public examSessionService: ExamSessionService
  ) {}

  openSession () {
      this.openingSession = true;
      this.examSessionService.open(this.examSession.id).pipe(take(1)).
          subscribe(
              (examSession: ExamSession) => {
                  this.examSession = examSession;
                  this.openingSession = false;
              },
              (error) => {
                  this.openingSession = false;
                  this.error = error;
              }
          );
  }

  closeSession () {
      this.closingSession = true;
      this.examSessionService.close(this.examSession.id).pipe(take(1)).
          subscribe(
              (examSession: ExamSession) => {
                  this.examSession = examSession;
                  this.closingSession = false;
              },
              (error) => {
                  this.closingSession = false;
                  this.error = error;
              }
          );
  }

  toggleExamPass (exam: Exam) {
      this.loading = true;

      this.examSerivce.toggleCanPass(exam.id).pipe(take(1)).
          subscribe((exam: Exam) => {
              const index = this.examSession.exams.findIndex((e: Exam) => e.id == exam.id);
              if (index >= 0) {
                  this.examSession.exams[index].can_pass = exam.can_pass;
              }
              this.loading = false;
          });
  }
}
