<div class="row" *ngIf="user">
    <div class="col">
        <form [formGroup]="searchForm">
            <div class="form-group form-inline">
                <input class="form-control" type="text" formControlName="filter" placeholder="Rechercher" />
            </div>
        </form>
    </div>
    <div class="col d-flex justify-content-end">
        <button class="btn btn-link" (click)="add()" *ngIf="user.isStaff"><i class="fas fa-plus"></i> Ajouter un nouveau
            document</button>
    </div>
</div>

<app-loading-overlay [active]="loading">
    <table class="table table-striped" *ngIf="user">
        <thead>
            <tr>
                <th scope="col">Catégorie</th>
                <th scope="col">Titre</th>
                <th scope="col">Fichier</th>
                <th scope="col">Date</th>
                <th scope="col" *ngIf="user.isStaff">Accès</th>
                <th scope="col" *ngIf="user.isStaff">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let miscFile of miscFiles$ | async; index as i">
                <td>
                    <ngb-highlight [result]="miscFile.category.title" [term]="searchForm.value.filter"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="miscFile.name" [term]="searchForm.value.filter"></ngb-highlight>
                </td>
                <td>
                    <ng-container *ngIf="miscFile.document">
                        <button
                            class="btn btn-link"
                            (click)="showDocument(miscFile)"
                            [disabled]="retrievingDocumentForMiscFileId == miscFile.id">
                            <span *ngIf="retrievingDocumentForMiscFileId == miscFile.id" class="button-loader"></span>
                            Voir le fichier
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!miscFile.document">
                        -
                    </ng-container>
                </td>
                <td>
                    {{miscFile.date | date:'dd/MM/yyyy'}}
                </td>
                <td *ngIf="user.isStaff">
                    <ngb-highlight [result]="miscFile.groupsString" [term]="searchForm.value.filter"></ngb-highlight>
                </td>
                <td *ngIf="user.isStaff">
                    <button class="btn btn-link" (click)="edit(miscFile)"><i class="fas fa-edit"></i> Modifier</button>
                    <button class="btn btn-link" (click)="delete(miscFile)"><i class="fas fa-trash"></i>
                        Supprimer</button>
                </td>
            </tr>
        </tbody>
    </table>
</app-loading-overlay>
