import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

/**
 * The dateadapter changes the regular ngboostrap model "NgbDateStruct" to a string "dd/mm/yyyy"
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<Date> {

  readonly DELIMITER = '/';

  fromModel(value: Date | null): NgbDateStruct | null {
    if (value instanceof Date) {
      return {
        day: value.getDate(),
        month: value.getMonth() + 1, // January is 0
        year: value.getFullYear(),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): Date | null {
    return date ? new Date(date.year, date.month - 1, date.day) : null;
  }
}
