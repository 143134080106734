
export class CertificationMockTest {
    id: number;
    label: string;
    description: string;
    document: string;
    newDocument: File;
    certification: number;
    embedding_qcm: string;
    type: "embedded" | "blob";

    constructor (data) {
        if (data) {
            this.id = data.id;
            this.label = data.label;
            this.description = data.description;
            this.document = data.document;
            this.newDocument = null;
            this.certification = data.certification;
            this.embedding_qcm = data.embedding_qcm;
            this.type = data.type;
        }
    }
}
