import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Group } from '../../models/group';
import { environment } from '../../../../environments/environment';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  private groups: Array<Group>;

  constructor(private http: HttpClient) { }

  // Get the currently logged user
  list(): Observable<Array<Group>> {

    // Return observable version of groups if already in memory
    if (this.groups) {
      return of(this.groups);
    }

    // Fetch data if groups not in memory
    else {
      return this.http.get<Array<Group>>(`${environment.baseApiUrl}/groups/`)
        .pipe(
          map((res: any) => res.map((d: any) => new Group(d))),
          tap({
            next: (res: Array<Group>) => {
              this.groups = res;
            },
            error: err => {
              console.error("Could not fetch groups: " + err);
            }
          })
        )
    }
  }
}
