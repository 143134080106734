import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-select-file-modal',
  templateUrl: './select-file-modal.component.html',
  styleUrls: ['./select-file-modal.component.scss']
})
export class SelectFileModalComponent implements OnInit {

  selectForm: FormGroup;
  @Input() title;
  isDataLoading: boolean = true;
  error: string;
  document: File;

  constructor(
    public activeModal: NgbActiveModal,
  ) {  }

  ngOnInit() {
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.document = file;
    }
  }

  validate() {
    this.activeModal.close(this.document);
  }
}
