import { GroupsEnum } from '../enums/groupsEnum';

export class Group {
    id: number;
    name: string;

    get friendlyName(): string {
        switch (this.name) {
            case GroupsEnum.CORRECTOR:
                return "Correcteur";
            case GroupsEnum.EXAM_CENTER_BOSS:
                return "Centre d'examen";
            case GroupsEnum.STAFF:
                return "Staff";
            case GroupsEnum.STUDENT:
                return "Candidat";
            case GroupsEnum.SUPERVISOR:
                return "Superviseur";
            default:
                return "Groupe inconnu";
        }
    }

    constructor(data:any) {
        this.id = data.id;
        this.name = data.name;
    }
}