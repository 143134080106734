
export class CertificationTest {
    id: number;
    label: string;
    description: string;
    threshold_value: number;
    document: string;
    newDocument: File;
    duration_minutes_qcm: number;
    certification: number;
    embedding_qcm: string;
    type: "embedded" | "blob";

    constructor (data) {
        if (data) {
            this.id = data.id;
            this.label = data.label;
            this.description = data.description;
            this.threshold_value = data.threshold_value;
            this.document = data.document;
            this.newDocument = null;
            this.duration_minutes_qcm = data.duration_minutes_qcm;
            this.certification = data.certification;
            this.embedding_qcm = data.embedding_qcm;
            this.type = data.type;
        }
    }
}
