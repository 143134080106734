import {Injectable} from "@angular/core";
import {Router, CanActivate} from "@angular/router";

import {AuthenticationService} from "../authentication/authentication.service";

@Injectable({
    providedIn: "root"
})
export class AuthGuardService implements CanActivate {

    constructor (
    private router: Router,
    private authenticationService: AuthenticationService
    ) { }

    canActivate (): boolean {
        if (this.authenticationService.getAccessToken()) {
            return true;
        }

        // Not logged-in: redirect to login page
        this.router.navigate(["login"]);
        return false;
    }
}
