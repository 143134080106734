import {Injectable} from "@angular/core";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {MiscFile} from "../../models/miscFile";

@Injectable({
    "providedIn": "root"
})
export class MiscFileService {

    constructor (private http: HttpClient) { }

    list (short?: boolean): Observable<Array<MiscFile>> {
        let params = {};
        if (short) {
            params = new HttpParams().set(
                "short",
                "True"
            );
        }
        return this.http.get<Array<MiscFile>>(
            `${environment.baseApiUrl}/misc-files/`,
            {params}
        ).
            pipe(map((res) => res.map((d) => new MiscFile(d))));
    }

    update (invoice: MiscFile) {
        return this.http.put<MiscFile>(
            `${environment.baseApiUrl}/misc-files/${invoice.id}/`,
            invoice
        ).pipe(map((res) => new MiscFile(res)));
    }

    create (invoice: MiscFile) {
        return this.http.post<MiscFile>(
            `${environment.baseApiUrl}/misc-files/`,
            invoice
        ).pipe(map((res) => new MiscFile(res)));
    }

    destroy (id: number) {
        return this.http.delete<MiscFile>(`${environment.baseApiUrl}/misc-files/${id}/`);
    }

    uploadFile (miscFileId: number, document: File): Observable<any> {
        const formData = new FormData();
        formData.append(
            "file",
            document
        );
        return this.http.put(
            `${environment.baseApiUrl}/misc-files/${miscFileId}/upload_file/`,
            formData,
            {"reportProgress": true,
                "observe": "events"}
        );
    }

    retrieveFile (miscFileId: number): Observable<HttpResponse<Blob>> {
        return this.http.get(
            `${environment.baseApiUrl}/misc-files/${miscFileId}/document/`,
            {
                responseType: "blob",
                observe: "response" // Observe the full response
            }
        );
    }
}
