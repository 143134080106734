import {Component, OnInit, Input} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ExamSession} from "../../../shared/models/examSession";
import {Exam} from "../../../shared/models/exam";
import {take} from "rxjs/operators";
import {UserService} from "../../../shared/services/user/user.service";
import {ExamSessionService} from "../../../shared/services/exam-session/exam-session.service";
import {ToastrService} from "ngx-toastr";
import {saveAs} from "file-saver";

@Component({
    selector: "app-session-modal",
    templateUrl: "./session-export-modal.component.html",
    styleUrls: ["./session-export-modal.component.scss"]
})
export class SessionExportModalComponent implements OnInit {

    @Input() examSession: ExamSession;
    exams: Exam[] = [];
    selectedExams: Exam[] = [];
    exporting: boolean = false;

    constructor (
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private examSessionService: ExamSessionService
    ) {}

    ngOnInit () {
        this.exams = this.examSession.exams.filter(exam => exam.success === true);
        this.selectedExams = [
            ...this.exams
        ]
    }


    onSelectExam(exam: Exam) {
        if (!this.selectedExams.includes(exam)) {
            this.selectedExams.push(exam);
        } else {
            this.selectedExams = this.selectedExams.filter(u => u !== exam);
        }
    }

    isSelected(exam: Exam) {
        return this.selectedExams.includes(exam);
    }

    export() {
        if (this.selectedExams.length === 0) {
            this.toastr.error("You must select at least one exam to export");
            return;
        }

        this.exporting = true;

        this.examSessionService.exportToXml(
            this.examSession.id,
            this.selectedExams.map(exam => exam.id),
        ).pipe(take(1)).
            subscribe((res: string) => {

                // Créez un Blob à partir de la chaîne XML
                const blob = new Blob(
                    [res],
                    {type: "application/xml"}
                );

                // Utilisez saveAs pour télécharger le fichier
                saveAs(
                    blob,
                    this.examSession.code + ".xml",
                );

                this.close();

            });


    }

    close() {
        this.activeModal.close();
        this.selectedExams = [];
        this.exporting = false;
    }

}
