import {Component, Input} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {take} from "rxjs/operators";
import {ExamCenterService} from "../../../shared/services/exam-center/exam-center.service";
import {ExamCenter} from "../../../shared/models/examCenter";
import {FormGroup, FormControl, Validators} from "@angular/forms";

@Component({
    selector: "app-request-exams-modal",
    templateUrl: "./request-exams-modal.component.html",
    styleUrls: ["./request-exams-modal.component.scss"]
})
export class RequestExamsModalComponent {
    @Input() examCenters: Array<ExamCenter>;
    error: string;
    submitting: boolean = false;
    form: FormGroup;

    constructor (
        public activeModal: NgbActiveModal,
        public examCenterService: ExamCenterService
    ) {
        this.form = new FormGroup({
            count: new FormControl(
                1,
                [Validators.required]
            ),
            examCenterId: new FormControl(
                null,
                [Validators.required]
            ),
            comment: new FormControl("")
        });
    }

    submit () {
        this.submitting = true;

        const {count} = this.form.value;
        const {examCenterId} = this.form.value;
        const {comment} = this.form.value;

        this.examCenterService.requestExams(
            examCenterId,
            count,
            comment
        ).pipe(take(1)).
            subscribe(
                (res) => {
                    this.activeModal.close(res);
                },
                (err) => {
                    this.error = "Une erreur est survenue lors l'envoi de la demande des codes";
                    this.submitting = false;
                    throw err;
                }
            );
    }

}
