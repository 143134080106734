import {Component, OnInit, Input} from "@angular/core";
import {FormGroup, FormControl} from "@angular/forms";
import {CertificationMockTest} from "../../../shared/models/certificationMockTest";
import {Certification} from "../../../shared/models/certification";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CertificationMockTestService} from "../../../shared/services/certification-mock-test/certification-mock-test.service";
import {take, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {HttpEvent, HttpEventType, HttpResponse} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {FileDownloadHelper} from "../../../shared/helpers/file-download.helper";
import {ToastrService} from "ngx-toastr";


@Component({
    selector: "app-certification-mock-test-modal",
    templateUrl: "./certification-mock-test-modal.component.html",
    styleUrls: ["./certification-mock-test-modal.component.scss"]
})
export class CertificationMockTestModalComponent implements OnInit {
    @Input() test: CertificationMockTest;
    @Input() certification: Certification;

    testForm: FormGroup;
    loadingForm: boolean;
    error: string;
    submitting: boolean = false;
    retrievingFile: boolean = false;
    baseUrl = environment.baseUrl;
    newFile: File;
    fileUploadProgress: number;
    unsubscribeFromFileUploadProgress = new Subject<void>();

    constructor (
        public activeModal: NgbActiveModal,
        public certificationMockTestService: CertificationMockTestService,
        private toastr: ToastrService
    ) {
        this.loadingForm = true;
        this.fileUploadProgress = 0;
    }

    ngOnInit () {
        this.resetForm();
    }

    /*
     * Function to instanciate the frontend form
     * Call only after all data is fetched
     */
    private resetForm () {

        this.loadingForm = true;

        if (this.test) {
            this.testForm = new FormGroup({
                label: new FormControl(this.test.label),
                description: new FormControl(this.test.description),
                document: new FormControl(this.test.document),
                embedding_qcm: new FormControl(this.test.embedding_qcm),
            });

        } else {

            this.testForm = new FormGroup({
                label: new FormControl(""),
                description: new FormControl(""),
                document: new FormControl(),
                embedding_qcm: new FormControl(""),
            });
        }

        this.loadingForm = false;
    }

    onFileChange (event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.newFile = file;
        }
    }

    validate () {
        this.submitting = true;

        // Create test to submit

        const submitTest = new CertificationMockTest(this.test);

        submitTest.label = this.testForm.value.label;
        submitTest.description = this.testForm.value.description;
        submitTest.document = this.testForm.value.document;
        submitTest.embedding_qcm = this.testForm.value.embedding_qcm;

        if (this.certification) {
            submitTest.certification = this.certification.id;
        }

        // Eventually upload new test file

        if (this.newFile) {
            this.uploadFile(this.newFile);
        }

        // Trigger update if it's an edit

        if (this.test) {
            this.certificationMockTestService.update(submitTest).pipe(take(1)).
                subscribe(
                    (res: CertificationMockTest) => {
                        this.test = res;
                        this.resetForm();
                        this.activeModal.close(this.test);
                    },
                    (err) => {
                        this.submitting = false;
                        this.error = "Une erreur est survenue lors de la modification de l'épreuve blanche";
                        throw err;
                    }
                );
        } else {
            this.certificationMockTestService.create(submitTest).pipe(take(1)).
                subscribe(
                    (res: CertificationMockTest) => {
                        this.test = res;
                        this.resetForm();
                        this.activeModal.close(this.test);
                    },
                    (err) => {
                        this.submitting = false;
                        this.error = "Une erreur est survenue lors de la création de l'épreuve blanche";
                        throw err;
                    }
                );
        }
    }

    showFile () {
        this.retrievingFile = true;

        this.certificationMockTestService.fetchFile(this.test.id).pipe(take(1)).
            subscribe(
                (response: HttpResponse<Blob>) => {
                    this.retrievingFile = false;
                    FileDownloadHelper.downloadBlobFromResponse(response);
                },
                () => {
                    this.retrievingFile = false;
                    this.toastr.error(
                        "Contactez l'administrateur pour plus d'informations",
                        "Fichier introuvable"
                    );
                }
            );
    }

    uploadFile (file: File) {
        this.certificationMockTestService.uploadFile(
            this.test.id,
            file
        ).pipe(takeUntil(this.unsubscribeFromFileUploadProgress)).
            subscribe((event: HttpEvent<any>) => {
                // Look for upload progress events.
                if (event.type === HttpEventType.UploadProgress) {
                    this.fileUploadProgress = Math.round(100 * event.loaded / event.total);
                } else if (event instanceof HttpResponse) {
                    this.test.document = event.body;
                }
            });
    }

    removeFile () {
        const res = confirm("Êtes-vous sûr de vouloir supprimer le fichier associé à cette épreuve blanche ?");
        if (res) {
            this.certificationMockTestService.removeFile(this.test.id).pipe(take(1)).
                subscribe(() => {
                    this.test.document = null;
                    this.resetForm();
                });
        }
    }
}
