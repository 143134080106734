<div class="modal-header">
    <h4 class="modal-title">Confirmer ma présence</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-group row" style="align-items: center;">
        <input
            type="checkbox"
            class="col-1 form-control"
            [checked]="accepted"
            (change)="accepted = !accepted"/>
        <label class="col col-form-label">
            Je confirme ma disponibilité pour encadrer cette épreuve de certification à la date mentionnée, dans le respect du protocole établi par la structure de certification et validé par France Compétences. En conséquence, j’atteste de ma responsabilité dans le respect des règles de confidentialité et de sécurité inhérentes à cette épreuve de certification nationale réglementée.
        </label>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">Annuler</button>
    <button type="button" class="btn btn-outline-success" (click)="validate()" [disabled]="!accepted">
        <span *ngIf="submitting" class="button-loader"></span>
        Valider
    </button>
</div>
