import {Component, Input, OnInit} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ExamSessionService} from "../../../shared/services/exam-session/exam-session.service";
import {ExamSessionCommentService} from "../../../shared/services/exam-session-comment/exam-session-comment.service";
import {take} from "rxjs/operators";
import {ExamSession} from "../../../shared/models/examSession";
import {ExamSessionComment} from "../../../shared/models/examSessionComment";
import {UserService} from "../../../shared/services/user/user.service";
import {User} from "../../../shared/models/user";


@Component({
    selector: "app-session-comments-modal",
    templateUrl: "./session-comments-modal.component.html",
    styleUrls: ["./session-comments-modal.component.scss"]
})
export class SessionCommentsModalComponent implements OnInit {
    @Input() session: ExamSession;
    editCommentForm: FormGroup;
    form: FormGroup;
    error: string;
    user: User;
    submittingComment: boolean = false;
    submittingEditedComment: boolean = false;
    editedComment: ExamSessionComment = null;
    deletedComment: ExamSessionComment = null;

    constructor (
        public activeModal: NgbActiveModal,
        private sessionService: ExamSessionService,
        private sessionCommentService: ExamSessionCommentService,
        private userService: UserService
    ) {
        this.form = new FormGroup({
            comment: new FormControl(
                "",
                Validators.required
            )
        });
        this.editCommentForm = new FormGroup({
            comment: new FormControl(
                "",
                Validators.required
            )
        });
    }

    ngOnInit () {
        this.userService.getMe().pipe(take(1)).
            subscribe((user: User) => {
                this.user = user;
            });
    }

    editComment (comment: ExamSessionComment) {
        this.editedComment = comment;
        this.editCommentForm.setValue({comment: comment.comment});
    }

    cancelEditComment () {
        this.editedComment = null;
        this.editCommentForm.reset();
    }

    submitEditedComment () {
        this.submittingEditedComment = true;

        const {comment} = this.editCommentForm.value;

        this.sessionCommentService.partialUpdate(
            this.editedComment.id,
            {comment}
        ).pipe(take(1)).
            subscribe(
                (res: ExamSessionComment) => {
                    this.editedComment.comment = res.comment;
                    this.submittingEditedComment = false;
                    this.cancelEditComment();
                },
                (err) => {
                    this.error = "Une erreur est survenue lors de la modification du commentaire";
                    this.submittingEditedComment = false;
                    throw err;
                }
            );
    }

    deleteComment (comment: ExamSessionComment) {
        this.deletedComment = comment;

        this.sessionCommentService.delete(comment.id).pipe(take(1)).
            subscribe(
                () => {
                    this.session.comments = this.session.comments.filter((c) => c.id != comment.id);
                    this.deletedComment = null;
                },
                (err) => {
                    this.error = "Une erreur est survenue lors de la suppression du commentaire";
                    this.deletedComment = null;
                    throw err;
                }
            );
    }

    submitComment () {
        this.submittingComment = true;

        const {comment} = this.form.value;

        this.sessionService.comment(
            this.session.id,
            comment
        ).pipe(take(1)).
            subscribe(
                (res: ExamSession) => {
                    this.session.comments.push(res.comments[res.comments.length - 1]);
                    this.submittingComment = false;
                    this.form.reset();
                },
                (err) => {
                    this.error = "Une erreur est survenue lors de la création des codes";
                    this.submittingComment = false;
                    throw err;
                }
            );
    }

}
