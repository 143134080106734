<div class="modal-header">
    <h4 class="modal-title">Générer de nouveaux codes</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="examForm" *ngIf="!isDataLoading">
        <div class="form-group row">
            <label for="countInput" class="col-sm-4 col-form-label text-right">Nombre de codes</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="countInput" formControlName='count'>
            </div>
        </div>
        <div class="form-group row">
            <label for="invoiceSelect" class="col-sm-4 col-form-label text-right">Facture</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="invoice" id="invoiceSelect">
                    <option *ngFor="let invoice of invoices; let i = index" [value]='invoices[i].id'>
                        {{invoices[i].label}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="centerSelect" class="col-sm-4 col-form-label text-right">Centre d'examen</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="examCenter" id="centerSelect">
                    <option *ngFor="let examCenter of examCenters; let i = index" [value]='examCenters[i].id'>
                        {{examCenters[i].nom}}
                    </option>
                </select>
            </div>
        </div>
    </form>
    <div *ngIf="error" class="alert alert-danger">
        {{error}}
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">Annuler</button>
    <button type="button" class="btn btn-outline-success" [disabled]="examForm.invalid" (click)="validate()">
        <span *ngIf="submitting" class="button-loader"></span>
        Valider
    </button>
</div>
