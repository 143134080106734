import {Injectable} from "@angular/core";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {Invoice} from "../../models/invoice";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class InvoiceService {

    constructor (private http: HttpClient) { }

    list (short?: boolean): Observable<Array<Invoice>> {
        let params = {};
        if (short) {
            params = new HttpParams().set(
                "short",
                "True"
            );
        }
        return this.http.get<Array<Invoice>>(
            `${environment.baseApiUrl}/invoices/`,
            {params}
        ).
            pipe(map((res) => res.map((d) => new Invoice(d))));
    }

    update (invoice: Invoice) {
        return this.http.put<Invoice>(
            `${environment.baseApiUrl}/invoices/${invoice.id}/`,
            invoice
        ).pipe(map((res) => new Invoice(res)));
    }

    create (invoice: Invoice) {
        return this.http.post<Invoice>(
            `${environment.baseApiUrl}/invoices/`,
            invoice
        ).pipe(map((res) => new Invoice(res)));
    }

    destroy (id: number) {
        return this.http.delete<Invoice>(`${environment.baseApiUrl}/invoices/${id}/`);
    }

    uploadFile (invoiceId: number, document: File): Observable<string> {
        const formData = new FormData();
        formData.append(
            "file",
            document
        );
        return this.http.put<string>(
            `${environment.baseApiUrl}/invoices/${invoiceId}/upload_file/`,
            formData
        );
    }

    retrieveFile (invoiceId: number): Observable<HttpResponse<Blob>> {
        return this.http.get(
            `${environment.baseApiUrl}/invoices/${invoiceId}/document/`,
            {
                responseType: "blob",
                observe: "response" // Observe the full response
            }
        );
    }
}
