<div class="modal-header">
    <h4 class="modal-title">Modifier mon profil</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="studentForm" *ngIf="!isDataLoading">
        <div class="form-group row">
            <label for="first-name-input" class="col-sm-4 col-form-label text-right">Prénom</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="first-name-input" formControlName='first_name'>
            </div>
        </div>
        <div class="form-group row">
            <label for="first-name-2-input" class="col-sm-4 col-form-label text-right">Deuxième prénom</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="first-name-2-input" formControlName='first_name_2'>
            </div>
        </div>
        <div class="form-group row">
            <label for="first-name-3-input" class="col-sm-4 col-form-label text-right">Troisième prénom</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="first-name-3-input" formControlName='first_name_3'>
            </div>
        </div>
        <div class="form-group row">
            <label for="last-name-input" class="col-sm-4 col-form-label text-right">Nom de famille</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="last-name-input" formControlName='last_name'>
            </div>
        </div>
        <div class="form-group row">
            <label for="email-input" class="col-sm-4 col-form-label text-right">Email</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="email-input" formControlName='email'>
            </div>
        </div>
        <div class="form-group row">
          <label for="phone-input" class="col-sm-4 col-form-label text-right">Numéro de téléphone</label>
          <div class="col-sm-8">
              <input type="text" class="form-control" id="phone-input" formControlName='phone_number'>
          </div>
      </div>
        <div class="form-group row">
            <label for="sex-select" class="col-sm-4 col-form-label text-right">Sexe</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="sex" id="sex-select">
                    <option selected disabled [value]="null">
                        Sélectionnez
                    </option>
                    <option value='M'>
                        Homme
                    </option>
                    <option value='F'>
                        Femme
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="address-input" class="col-sm-4 col-form-label text-right">Adresse</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="address-input" formControlName='address'>
            </div>
        </div>
        <div class="form-group row">
            <label for="zipcode-input" class="col-sm-4 col-form-label text-right">Code postal</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="zipcode-input" formControlName='zipcode'>
            </div>
        </div>
        <div class="form-group row">
            <label for="city-input" class="col-sm-4 col-form-label text-right">Ville</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="city-input" formControlName='city'>
            </div>
        </div>
        <div class="form-group row">
            <label for="birthdayDateInput" class="col-sm-4 col-form-label text-right">Date de naissance</label>
            <div class="input-group col-sm-8">
                <input class="form-control" id="birthdayDateInput" placeholder="jj/mm/aaaa"
                    formControlName='birthday_date' name="dp" ngbDatepicker #d="ngbDatepicker"
                    [minDate]="{year: 1920, month: 1, day: 1}">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i
                            class="far fa-calendar-alt"></i></button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="birth-zipcode-input" class="col-sm-4 col-form-label text-right">Code postal de naissance</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="birth-zipcode-input" formControlName='birth_zipcode'>
            </div>
        </div>
    </form>
    <div *ngIf="error" class="alert alert-danger">
        {{error}}
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-outline-danger"
        (click)="activeModal.dismiss()"
        [disabled]="submitting">
        Annuler
    </button>
    <button
        type="button"
        class="btn btn-outline-success"
        [disabled]="studentForm.invalid || submitting"
        (click)="validate()">
        Valider
        <span *ngIf="submitting" class="button-loader"></span>
    </button>
</div>
