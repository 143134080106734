import { ExamCenter } from './examCenter';

export class SupervisorProfile {
    id: number;
    center: ExamCenter;

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.center = new ExamCenter(data.center);
        }
    }
}