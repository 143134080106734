<select (change)="onCodeSelected($event.target.value)" class="form-control">
  <option disabled selected hidden>Choisissez un pays</option>
  <option
    *ngFor="let code of favoriteCountries"
    [value]="code">
    {{ countries[code] }}
  </option>
  <option disabled>
    ──────────
  </option>
  <option
  *ngFor="let code of getCountryCodes()"
  [value]="code">
  {{ countries[code] }}
</option>
</select>