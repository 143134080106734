
import {AuthenticationService} from "../authentication/authentication.service";
import {CanActivate, Router} from "@angular/router";
import {Injectable} from "@angular/core";


@Injectable({
    providedIn: "root"
})
export class NoAuthGuardService implements CanActivate {

    constructor (
    private router: Router,
    private authenticationService: AuthenticationService
    ) { }

    canActivate (): boolean {
        if (!this.authenticationService.getAccessToken()) {
            return true;
        }

        // Not logged-in: redirect to login page
        this.router.navigate(["dashboard"]);
        return false;
    }
}
