<div class="modal-header">
    <h4 *ngIf="!test" class="modal-title">Ajouter une épreuve blanche</h4>
    <h4 *ngIf="test" class="modal-title">Epreuve {{ test.label }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="testForm" *ngIf="!loadingForm">
        <div class="form-group row">
            <label for="labelInput" class="col-sm-4 col-form-label text-right">Libellé</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="labelInput" formControlName='label'>
            </div>
        </div>
        <div class="form-group row">
            <label for="descriptionInput" class="col-sm-4 col-form-label text-right">Description</label>
            <div class="col-sm-8">
                <textarea type="text" class="form-control" id="descriptionInput" formControlName='description'></textarea>
            </div>
        </div>
        <div class="form-group row">
            <label for="embeddingQcmInput" class="col-sm-4 col-form-label text-right">Embedding QCM</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="embeddingQcmInput" formControlName='embedding_qcm'>
            </div>
        </div>
        <div class="form-group row" *ngIf="test">
            <label for="documentUrl" class="col-sm-4 col-form-label text-right">Fichier</label>
            <div *ngIf="test.document" class="col-sm-8 col-form-label">
                <button
                    id="document"
                    class="btn btn-link"
                    [disabled]="retrievingFile"
                    (click)="showFile()">
                    <span *ngIf="retrievingFile" class="button-loader"></span>
                    Voir le fichier
                </button>
                <button
                    class="btn btn-link"
                    style="color: red;"
                    (click)="removeFile()">
                    Supprimer le fichier
                </button>
                <div>
                    Associer un autre fichier :
                    <input
                        class="form-control-file"
                        type="file"
                        id="documentUrl"
                        (change)="onFileChange($event)"
                        formControlName='document'
                        style="border: 1px solid #ced4da; border-radius: 0.25rem;">
                </div>
            </div>
            <div *ngIf="!test.document" class="col-sm-8 col-form-label">
                <input
                    class="form-control-file"
                    type="file"
                    id="documentUrl"
                    (change)="onFileChange($event)"
                    formControlName='document'
                    style="border: 1px solid #ced4da; border-radius: 0.25rem;">
            </div>
        </div>
        <div class="form-group" *ngIf="fileUploadProgress > 0">
            <ngb-progressbar type="primary" [value]="fileUploadProgress"></ngb-progressbar>
        </div>
    </form>
    <div *ngIf="error" class="alert alert-danger">
        {{error}}
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">
        Annuler
    </button>
    <button type="button" class="btn btn-outline-success" (click)="validate()">
        <span *ngIf="submitting" class="button-loader"></span>
        Valider
    </button>
</div>
