<div class="row">
    <div class="col">
        <form [formGroup]="searchForm">
            <div class="form-group form-inline">
                <input class="form-control" type="text" formControlName="filter" placeholder="Rechercher" />
            </div>
        </form>
    </div>
    <div class="col d-flex justify-content-end">
        <button class="btn btn-link" (click)="add()"><i class="fas fa-plus"></i> Ajouter une nouvelle adhésion</button>
    </div>
</div>

<app-loading-overlay [active]="loading">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Référence</th>
                <th scope="col">Centre d'examen adhérant</th>
                <th scope="col">Date d'adhésion</th>
                <th scope="col">Expire le</th>
                <th scope="col">Statut</th>
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let membership of examCenterMemberships$ | async; index as i">
                <td>
                    <ngb-highlight [result]="membership.reference" [term]="searchForm.value.filter"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="membership.center.nom" [term]="searchForm.value.filter"></ngb-highlight>
                </td>
                <td>
                    {{membership.date_start | date:'dd/MM/yyyy'}}
                </td>
                <td>
                    {{membership.date_end | date:'dd/MM/yyyy'}}
                </td>
                <td>
                    <span *ngIf="membership.isValid">Valide</span>
                    <span class="text-danger" *ngIf="!membership.isValid">Expirée</span>
                </td>
                <td>
                    <button class="btn btn-link" (click)="edit(membership)"><i class="fas fa-edit"></i>
                        Modifier</button>
                    <button class="btn btn-link" (click)="delete(membership)"><i class="fas fa-trash"></i>
                        Supprimer</button>
                </td>
            </tr>
        </tbody>
    </table>
</app-loading-overlay>