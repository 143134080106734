import {Component, OnInit, Input, OnDestroy} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ExamResultFile} from "../../../shared/models/examResultFile";
import {environment} from "../../../../environments/environment";
import {take, takeUntil} from "rxjs/operators";
import {ExamResultFileService} from "../../../shared/services/exam-result-file/exam-result-file.service";
import {Exam} from "../../../shared/models/exam";
import {ExamService} from "../../../shared/services/exam/exam.service";
import {UserService} from "../../../shared/services/user/user.service";
import {User} from "../../../shared/models/user";
import {FormControl} from "@angular/forms";
import {forkJoin, Subject} from "rxjs";
import {ExamResultFileCategoryService} from "../../../shared/services/exam-result-file-category/exam-result-file-category.service";
import {ExamResultFileCategory} from "../../../shared/models/examResultFileCategory";
import {ExamResultFileCategoryEnum} from "../../../shared/enums/examResultFileCategoryEnum";
import {HttpEvent, HttpEventType, HttpResponse} from "@angular/common/http";
import {FileDownloadHelper} from "../../../shared/helpers/file-download.helper";
import {ToastrService} from "ngx-toastr";


@Component({
    selector: "app-exam-result-file-modal",
    templateUrl: "./exam-result-file-modal.component.html",
    styleUrls: ["./exam-result-file-modal.component.scss"]
})
export class ExamResultFileModalComponent implements OnInit, OnDestroy {

    @Input() exam: Exam;

    // List of all the exams fetched from backend
    examResultFiles: Array<ExamResultFile>;
    baseUrl = environment.baseUrl;
    document: File;
    user: User;
    categories: Array<ExamResultFileCategory>;
    categoryControl: FormControl;
    error: string;

    // Used when a file upload is running
    loading: boolean;
    uploadProgress: number;
    unsubscribe = new Subject<void>();
    retrievingDocumentForExamResultFileId: number = null;

    constructor (
        public activeModal: NgbActiveModal,
        private examService: ExamService,
        private examResultFileService: ExamResultFileService,
        private examResultFileCategoryService: ExamResultFileCategoryService,
        private userService: UserService,
        private toastr: ToastrService
    ) {
        this.categoryControl = new FormControl(-1);
    }

    ngOnInit () {
        this.examService.getExamResultFiles(this.exam.id).pipe(take(1)).
            subscribe((res: Array<ExamResultFile>) => {
                this.examResultFiles = res;
            });

        const getUser = this.userService.getMe().pipe(take(1));
        const getCategories = this.examResultFileCategoryService.list();

        // Fill user form once all data has been fetched
        forkJoin([
            getCategories,
            getUser
        ]).pipe(take(1)).
            subscribe(([
                categories,
                user
            ]) => {
                this.user = user;

                // We have to filter the categories in the dropdown, not all are allowed for each time of user
                let allowedCats = [];
                if (user.isStaff) {
                    allowedCats = [
                        ExamResultFileCategoryEnum.EXAM_CORRECTION_FILE,
                        ExamResultFileCategoryEnum.EXAM_FILE,
                        ExamResultFileCategoryEnum.ACHIEVEMENT_CERTIFICATE
                    ];
                } else {
                    if (user.isCorrector) {
                        allowedCats.push(ExamResultFileCategoryEnum.EXAM_CORRECTION_FILE);
                    }
                    if (user.isSupervisor) {
                        allowedCats.push(ExamResultFileCategoryEnum.EXAM_FILE);
                    }
                }
                this.categories = categories.filter((category: ExamResultFileCategory) => allowedCats.some((allowedCat) => allowedCat == category.name));
                this.user = user;
            });
    }

    // On destroy clear the form subscription
    ngOnDestroy (): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onFileChange (event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.document = file;
        }
    }

    addFile () {
        this.error = "";

        // If there's a document to upload, upload it then put it back in the misc file object
        if (this.document) {

            const category = this.categoryControl.value;
            if (category == -1) {
                this.error = "Spécifiez le type du fichier";
                return;
            }

            this.uploadProgress = 0;
            this.loading = true;
            this.examService.uploadResultFile(
                this.exam.id,
                this.document,
                category
            ).pipe(takeUntil(this.unsubscribe)).
                subscribe((event: HttpEvent<any>) => {

                    // Look for upload progress events.
                    if (event.type === HttpEventType.UploadProgress) {
                        this.uploadProgress = Math.round(100 * event.loaded / event.total);
                    } else if (event instanceof HttpResponse) {
                        const examResultFile = new ExamResultFile(event.body);
                        this.loading = false;
                        this.examResultFiles.push(examResultFile);
                        this.document = null;
                    }
                });
        }
    }

    delete (resultFile: ExamResultFile) {
        const res = confirm("Êtes-vous sûr de vouloir supprimer cette session ?");
        if (res) {
            this.examResultFileService.destroy(resultFile.id).pipe(take(1)).
                subscribe(() => {
                    const index = this.examResultFiles.findIndex((rf: ExamResultFile) => rf.id == resultFile.id);
                    if (index != -1) {
                        this.examResultFiles.splice(
                            index,
                            1
                        );
                    }
                });
        }
    }

    showDocument (resultFile: ExamResultFile) {
        this.retrievingDocumentForExamResultFileId = resultFile.id;
        this.examResultFileService.fetchDocument(resultFile.id).pipe(take(1)).
            subscribe(
                (response: HttpResponse<Blob>) => {
                    this.retrievingDocumentForExamResultFileId = null;
                    FileDownloadHelper.downloadBlobFromResponse(response);
                },
                () => {
                    this.retrievingDocumentForExamResultFileId = null;
                    this.toastr.error(
                        "Contactez l'administrateur pour plus d'informations",
                        "Fichier introuvable"
                    );
                }
            );
    }

}
