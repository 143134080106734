<div class="modal-header">
    <h4 *ngIf="examSession" class="modal-title">Supervision de {{examSession.code}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="examSession && examSession.isOpen" class="alert alert-warning">
        <button class="btn btn-danger" (click)="closeSession()" [disabled]="closingSession || !examSession.canClose" style="margin-right: 10px;">
            <span *ngIf="closingSession" class="button-loader"></span>
            Refermer la session
        </button>
        Session en cours depuis le {{ examSession.startDatetime | date:'dd/MM/yyyy HH:mm' }}
    </div>
    <div *ngIf="examSession && examSession.isFinished" class="alert alert-success">
        Session terminée
    </div>
    <div *ngIf="examSession && !examSession.wasOpen" class="alert alert-info">
        <button class="btn btn-success" (click)="openSession()" [disabled]="openingSession">
            <span *ngIf="openingSession" class="button-loader"></span>
            Ouvrir la session
        </button>
    </div>

    <table *ngIf="examSession && examSession.isOpen" class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Actions</th>
                <th scope="col">Candidat</th>
                <th scope="col">Ticket</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let exam of examSession.exams; index as i">
                <tr *ngIf="exam.student">
                    <td>
                        <button *ngIf="exam.success == null && !exam.can_pass" class="btn btn-success" (click)="toggleExamPass(exam)" [disabled]="loading">
                            <span *ngIf="loading" class="button-loader"></span>
                            Autoriser à passer l'examen
                        </button>
                        <button *ngIf="exam.success == null && exam.can_pass" class="btn btn-danger" (click)="toggleExamPass(exam)" [disabled]="loading">
                            <span *ngIf="loading" class="button-loader"></span>
                            Révoquer l'accès à l'examen
                        </button>
                        <span *ngIf="exam.success != null">Examen déjà terminé</span>
                    </td>
                    <td>
                        {{exam.student.fullName}}
                    </td>
                    <td>
                        {{exam.voucher_code}}
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <div *ngIf="error" class="alert alert-danger">
        {{error}}
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.close()">Fermer</button>
</div>
