<div class="modal-header">
    <h4 class="modal-title">Fichiers de l'examen {{ exam.voucher_code }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="user">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Catégorie</th>
                <th scope="col">Déposé par</th>
                <th scope="col">Déposé le</th>
                <th scope="col">Fichier</th>
                <th scope="col" *ngIf="user.isStaff || user.isCorrector">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let resultFile of examResultFiles; index as i">
                <td>
                    {{resultFile.category.title}}
                </td>
                <td>
                    {{resultFile.droppedby.fullName}}
                </td>
                <td>
                    {{resultFile.created_date | date:'dd/MM/yyyy à H:mm:ss'}}
                </td>
                <td>
                    <button
                        class="btn btn-link"
                        [disabled]="retrievingDocumentForExamResultFileId == resultFile.id"
                        (click)="showDocument(resultFile)">
                        <span *ngIf="retrievingDocumentForExamResultFileId == resultFile.id" class="button-loader"></span>
                        Voir le fichier
                    </button>
                </td>
                <td *ngIf="user.isStaff || user.isCorrector">
                    <button class="btn btn-link"
                        [disabled]="user.isCorrector && resultFile.category.name != 'exam_correction_file'"
                        (click)="delete(resultFile)">
                        <i class="fas fa-trash"></i>
                        Supprimer
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <div>
        <hr>
        <div class="row mt-3">
            <div class="col">
                <input class="form-control-file" type="file" id="documentFile" (change)="onFileChange($event)">
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <div class="form-inline form-group">
                    <label for="categorySelect">Type de fichier</label>
                    <select class="form-control ml-3" [formControl]="categoryControl" id="categorySelect">
                        <option [value]="-1" disabled selected>Sélectionnez une catégorie</option>
                        <option *ngFor="let category of categories; let i = index" [value]='categories[i].id'>
                            {{categories[i].title}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mb-3" *ngIf="loading">
            <div class="col">
                <ngb-progressbar type="primary" [value]="uploadProgress"></ngb-progressbar>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <button [disabled]="!document || loading" class="btn btn-primary" (click)="addFile()">
                    Ajouter
                </button>
            </div>
        </div>
        <div class="row mt-3" *ngIf="error">
            <div class="col">
                <div class="alert alert-danger">
                    {{error}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.dismiss()">Fermer</button>
</div>
