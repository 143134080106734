<div class="row" *ngIf="user">
    <div class="col">
        <div class="form-group form-inline">
            <input class="form-control" type="text" [formControl]="searchTermControl" placeholder="Rechercher" />
            <select class="form-control ml-2" [formControl]="studentFilterControl">
                <option value="student_all" default>Tous</option>
                <option value="student_assigned">Assignés</option>
                <option value="student_unassigned">En attente</option>
                <option value="unlocked">En attente de validation</option>
                <option value="locked">Validés</option>
            </select>
            <small class="ml-2">
                Total: {{itemsCount}}
            </small>
        </div>
    </div>
    <div class="col">
        <ngb-pagination (pageChange)="onPageChange($event)" [page]="page" [pageSize]="pageSize"
            [collectionSize]="itemsCount"></ngb-pagination>
    </div>
    <div class="col d-flex justify-content-end">
        <button class="btn btn-link" (click)="add()" *ngIf="user.isStaff">
            <i class="fas fa-plus"></i>
            Ajouter de nouveaux codes
        </button>
        <button class="btn btn-link" (click)="request()" *ngIf="user.isExamCenterBoss">
            <i class="fas fa-plus"></i>
            Demander de nouveaux codes
        </button>
    </div>
</div>
<app-loading-overlay [active]="loading">
    <table class="table table-striped" *ngIf="user">
        <thead>
            <tr>
                <ng-container>
                    <th scope="col"
                        *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor || user.isCorrector">
                        Code</th>
                    <th scope="col"
                        *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor || user.isCorrector">
                        Session</th>
                    <th scope="col" *ngIf="user.isStaff">Centres</th>
                    <th scope="col" *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor">Certification
                    </th>
                    <th scope="col" *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor">Candidat</th>
                    <th scope="col" *ngIf="user.isStaff || user.isExamCenterBoss">Succès</th>
                    <th scope="col" *ngIf="user.isStaff || user.isSupervisor || user.isCorrector">
                        Fichiers
                    </th>
                    <th scope="col" *ngIf="user.isStaff || user.isExamCenterBoss">Facture</th>
                    <th scope="col" *ngIf="user.isStaff">Diplôme</th>
                    <th scope="col" *ngIf="user.isCorrector || user.isStaff">Correction</th>
                    <th scope="col" *ngIf="user.isCorrector || user.isStaff || user.isSupervisor">
                        <i class="fas fa-comment">
                        </i>
                    </th>
                    <th scope="col">Actions</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let exam of exams; index as i">

                <!-- Voucher code -->

                <td *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor || user.isCorrector">
                    <i class="fas fa-hashtag" style="margin-right: 5px;">
                    </i>
                    <ngb-highlight [result]="exam.voucher_code" [term]="searchTermControl.value">
                    </ngb-highlight>

                    <ng-container *ngIf="user.isStaff && exam.file_number">
                        <br>
                        <i class="fas fa-folder" style="margin-right: 5px;">
                        </i>
                        <ngb-highlight [result]="exam.file_number" [term]="searchTermControl.value">
                        </ngb-highlight>
                    </ng-container>
                </td>

                <!-- Session -->

                <td *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor || user.isCorrector">
                    <ng-container
                        *ngIf="!exam.session && (!user.isExamCenterBoss || exam.exam_center.boss_id == user.id)">
                        <span class="btn-assign text-secondary " (click)="openSelectSessionModal(exam)">
                            En attente d'attribution
                        </span>
                    </ng-container>
                    <ng-container *ngIf="exam.session">
                        <ngb-highlight [result]="exam.session.code" [term]="searchTermControl.value">
                        </ngb-highlight>
                    </ng-container>
                </td>

                <!-- Formation and exam centers -->

                <td *ngIf="user.isStaff">
                    <div style="display: flex; align-items: center;">
                        <i class="fas fa-school" style="margin-right: 5px;"></i>
                        <ng-container *ngIf="!exam.invoice">
                            En attente d'attribution
                        </ng-container>
                        <ng-container *ngIf="exam.invoice">
                            <ngb-highlight [result]="exam.invoice.center.nom" [term]="searchTermControl.value">
                            </ngb-highlight>
                        </ng-container>
                    </div>

                    <div style="display: flex; align-items: center;">
                        <i class="fas fa-graduation-cap" style="margin-right: 5px;"></i>
                        <ng-container *ngIf="exam.exam_center">
                            <ngb-highlight [result]="exam.exam_center.nom" [term]="searchTermControl.value">
                            </ngb-highlight>
                        </ng-container>
                    </div>
                </td>

                <!-- Certification -->

                <td *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor">
                    <ng-container
                        *ngIf="!exam.certification  && (!user.isExamCenterBoss || exam.exam_center.boss_id == user.id)">
                        <span class="btn-assign text-secondary" (click)="openSelectCertificationModal(exam)">
                            En attente d'attribution
                        </span>
                    </ng-container>
                    <ngb-highlight *ngIf="exam.certification" [result]="exam.certification.title"
                        [term]="searchTermControl.value"></ngb-highlight>
                </td>

                <!-- Student -->

                <td *ngIf="user.isStaff || user.isExamCenterBoss || user.isSupervisor">
                    <ng-container
                        *ngIf="!exam.student  && (!user.isExamCenterBoss || exam.exam_center.boss_id == user.id)">
                        <span class="btn-assign text-secondary" (click)="openSelectStudentModal(exam)">
                            En attente d'attribution</span>
                    </ng-container>
                    <ng-container *ngIf="exam.student">
                        <ngb-highlight [result]="exam.student.fullName" [term]="searchTermControl.value">
                        </ngb-highlight>
                    </ng-container>
                </td>

                <!-- Success -->

                <td *ngIf="user.isStaff">
                    <span *ngIf="exam.success == true" class="text-success">
                        <i class="fas fa-check"></i>
                        <span *ngIf="!exam.locked">
                            (En attente de validation)
                        </span>
                    </span>
                    <span *ngIf="exam.success == false" class="text-danger">
                        <i class="fas fa-times"></i>
                        <span *ngIf="!exam.locked">
                            (En attente de validation)
                        </span>
                    </span>
                    <span *ngIf="exam.success == null">-</span>
                </td>

                <td *ngIf="user.isExamCenterBoss">
                    <a *ngIf="exam.success == true && exam.locked == true" class="text-success" style="cursor: pointer;"
                        (click)="openExamResultsModal(exam)">
                        <i class="fas fa-check"></i>
                        Examen réussi
                        <i class="fas fa-external-link-square-alt"></i>
                    </a>
                    <a *ngIf="exam.success == false && exam.locked == true" class="text-danger" style="cursor: pointer;"
                        (click)="openExamResultsModal(exam)">
                        <i class="fas fa-times"></i>
                        Examen échoué
                        <i class="fas fa-eye"></i>
                    </a>
                    <span *ngIf="exam.locked != true || (exam.success != false && exam.success != true)">
                        <span class="text-secondary">Non disponible</span>
                    </span>
                </td>

                <!-- Exam result files -->

                <td *ngIf="user.isStaff || user.isSupervisor  || user.isCorrector">
                    <button *ngIf="!user.isCorrector" class="btn btn-link"
                        style="display: flex; justify-content: center; align-items: center;"
                        (click)="openFilesModal(exam)" [disabled]="!exam.session">
                        <div *ngIf="exam.examResultFiles && exam.examResultFiles.length">
                            <i class="fas fa-file" style="margin-right: 5px;"></i>
                            {{ exam.examResultFiles.length }}
                        </div>

                        <div *ngIf="!(exam.examResultFiles && exam.examResultFiles.length)">
                            <i class="fas fa-file-upload"></i>
                        </div>
                    </button>

                    <ng-container *ngIf="user.isCorrector">
                        <button *ngIf="exam.examResultFiles && exam.examResultFiles.length" class="btn btn-link"
                            style="display: flex; justify-content: center; align-items: center;"
                            (click)="openFilesModal(exam)">
                            <i class="fas fa-file" style="margin-right: 5px;"></i>
                            {{exam.examResultFiles.length}}
                        </button>

                        <span *ngIf="!(exam.examResultFiles && exam.examResultFiles.length)">En attente des
                            fichiers</span>
                    </ng-container>
                </td>

                <!-- Facture -->

                <td *ngIf="user.isStaff || user.isExamCenterBoss">
                    <ng-container *ngIf="!exam.invoice">-</ng-container>
                    <button *ngIf="exam.invoice.document" class="btn btn-link" (click)="showInvoiceDocument(exam)"
                        [disabled]="retrievingInvoiceDocumentForExamId == exam.id">
                        <span *ngIf="retrievingInvoiceDocumentForExamId == exam.id" class="button-loader"></span>
                        {{ exam.invoice.label }}
                    </button>
                </td>

                <!-- Diploma -->

                <td *ngIf="user.isStaff">
                    <ng-container *ngIf="!exam.diplome">
                        <span *ngIf="!user.isStaff"> - </span>
                        <button *ngIf="user.isStaff" class="btn btn-link " (click)="openDiplomeModal(exam)"
                            [disabled]="!exam.session">
                            <i class="fas fa-file-upload"></i>
                        </button>
                    </ng-container>
                    <a target="_blank" rel="noopener noreferrer" class="btn btn-link" *ngIf="exam.diplome"
                        [href]="exam.diplome">
                        <i class="fas fa-eye"></i>
                    </a>
                </td>

                <!-- Correction -->

                <td *ngIf="user.isCorrector || user.isStaff">
                    <ng-container *ngIf="exam.locked">
                        <button class="btn btn-link text-success" (click)="openCorrectionModal(exam)">
                            <i class="fas fa-check"></i>
                            Résultats enregistrés et validés
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!exam.locked">
                        <ng-container *ngIf="exam.isCorrected">
                            <button class="btn btn-link" (click)="openCorrectionModal(exam)" *ngIf="!user.isCorrector">
                                Valider la correction
                            </button>
                            <button *ngIf="user.isCorrector" (click)="openCorrectionModal(exam)" class="btn btn-link">
                                <i class="fas fa-check"></i>&nbsp;Résultats saisis</button>
                        </ng-container>
                        <ng-container *ngIf="!exam.isCorrected">
                            <span *ngIf="!user.isCorrector">
                                Correction en attente
                            </span>
                            <button [disabled]="!exam.canBeCorrected" class="btn btn-link"
                                (click)="openCorrectionModal(exam)" *ngIf="user.isCorrector">
                                Saisir les résultats
                            </button>
                        </ng-container>
                    </ng-container>
                </td>

                <!-- Comments -->

                <td *ngIf="user.isStaff || user.isSupervisor || user.isCorrector || user.isExamCenterBoss">
                    <button class="btn btn-link" style="display: flex; justify-content: center; align-items: center;"
                        (click)="openExamCommentsModal(exam)">
                        <i class="fas fa-comment" style="margin-right: 5px;"></i>
                        {{ exam.comments.length }}
                    </button>
                </td>

                <!-- Edit -->

                <td>
                    <div style="display: flex; flex-direction: column;">
                        <button class="btn btn-link" (click)="edit(exam)" [disabled]="!exam.canBeReset">
                            <i class="fas fa-edit"></i>
                            Modifier
                        </button>

                        <button *ngIf="user.isStaff" class="btn btn-link" style="color: red;"
                            (click)="openExamResetConfirmModal(exam)" [disabled]="!exam.canBeReset">
                            Réinitialiser
                        </button>

                        <button *ngIf="user.isStaff || user.isExamCenterBoss"
                            [disabled]="!exam.session || !exam.session.wasOpen" class="btn btn-link"
                            (click)="certificatePdf(exam)">
                            Attestation de passage
                        </button>
                    </div>
                </td>
            </tr>


        </tbody>
    </table>
</app-loading-overlay>