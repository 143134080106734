/* eslint-disable camelcase */
import {User} from "./user";

export class ExamSessionComment {
    id: number;
    author: User;
    created_date: Date;
    comment: string;

    constructor (data = null) {
        if (data) {
            this.id = data.id;
            if (data.author) {
                this.author = new User(data.author);
            }
            if (data.created_date) {
                this.created_date = new Date(data.created_date);
            }
            this.comment = data.comment;
        }
    }

}
