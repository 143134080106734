import { User } from './user';
import { ExamCenterMemberShip } from './examCenterMembership';

export class ExamCenter {
    id: number;
    nom: string;
    boss: User;
    boss_id: number;
    adresse1: string;
    adresse2: string;
    zipcode: string;
    city: string;
    siret: string;
    tva: string;
    exam_center_memberships: Array<ExamCenterMemberShip>;

    constructor(data: any = null) {
        if (data != null) {
            this.id = data.id;
            this.nom = data.nom;
            if (data.boss) this.boss = new User(data.boss);
            this.boss_id = data.boss_id;
            this.adresse1 = data.adresse1;
            this.adresse2 = data.adresse2;
            this.zipcode = data.zipcode;
            this.city = data.city;
            this.siret = data.siret;
            this.tva = data.tva;
            if (data.exam_center_memberships) this.exam_center_memberships = data.exam_center_memberships.map((d: any) => new ExamCenterMemberShip(d));
        }
    }

    get isMembershipValid(): boolean {
        if (!this.exam_center_memberships) return false;
        return this.exam_center_memberships.some((membership: ExamCenterMemberShip) => membership.isValid);
    }
}
