import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appPhoneValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: PhoneValidatorDirective, multi: true}]
})
export class PhoneValidatorDirective implements Validator {
  @Input('appPhoneValidator') phonePattern: string;

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null; // Si le champ est vide, ne pas appliquer la validation
    }

    const phoneRegExp: RegExp = new RegExp(this.phonePattern || /^\d{10}$/); // Format par défaut : 10 chiffres

    if (!phoneRegExp.test(control.value)) {

      // Expression régulière pour accepter un format de numéro de téléphone avec indicatif
      const phoneRegExp: RegExp = new RegExp(this.phonePattern || /^\+(?:[0-9] ?){6,14}[0-9]$/);

      if (!phoneRegExp.test(control.value)) {
        return { 'invalidFormat': true }; // Retourner une erreur si le format est incorrect
      }
    }

    return null; // Retourner null si le numéro de téléphone est valide
  }
}
