/* eslint-disable @typescript-eslint/no-explicit-any */
import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {AuthenticationService} from "../services/authentication/authentication.service";

/**
 * The token interceptor makes sur to add the token to any outbound request when the user is logged in
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor (private authenticationService: AuthenticationService) { }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = this.authenticationService.getAccessToken();

        let newRequest = request;

        if (accessToken && !request.url.includes("/auth/token/refresh/")) {
            newRequest = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
        }

        return next.handle(newRequest).pipe(catchError((error) => {
            if (error.status === 401) {
                return this.handle401Error(
                    newRequest,
                    error,
                    next
                );
            }
            return throwError(error);

        }));
    }

    private handle401Error (request: HttpRequest<any>, error, next: HttpHandler): Observable<HttpEvent<any>> {

        /*
         * If the request is the refresh token request and it received a 401 error then the refresh token must have expired
         * Then we logout
         */

        if (request.url.includes("/auth/token/refresh/")) {
            this.authenticationService.logout();
            return throwError(error);
        }

        /*
         * If the request is the token auth request and it received a 401 error then someone is trying to login with invalid credentials
         * Then we just propagate the error
         */

        if (request.url.includes("/auth/token/")) {
            return throwError(error);
        }

        return this.authenticationService.refreshToken().pipe(
            switchMap((newAccessToken: string | null) => {

                if (!newAccessToken) {
                    this.authenticationService.logout();
                    return throwError(error);
                }

                // If token refreshed, clone the request and add the new token

                const newRequest = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${newAccessToken}`
                    }
                });

                return next.handle(newRequest);
            }),
            catchError((newError) => {
                this.authenticationService.logout();
                return throwError(newError);
            })
        );

    }


}
