/* eslint-disable @typescript-eslint/no-explicit-any */
import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {User} from "../../models/user";
import {environment} from "../../../../environments/environment";
import {tap, map} from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class UserService {

    // Current user of the app
    private user: User;

    constructor (private http: HttpClient) { }

    // Get the currently logged user
    getMe (): Observable<User> {

        // Return observable version of user if already in memory
        if (this.user) {
            return of(this.user);
        }

        // Fetch data if user not in memory

        return this.http.get<User>(`${environment.baseApiUrl}/users/me/`).
            pipe(
                map((res: any) => new User(res)),
                tap({
                    next: (res: User) => {
                        this.user = res;
                    },
                    error: (err) => {
                        console.error(`Could not fetch user: ${err}`);
                    }
                })
            );

    }

    list (groups?: Array<string>, page?: number, term?: string): Observable<Array<User>> {
        let params = new HttpParams();
        if (groups) {
            params = params.set(
                "groups",
                groups.join(",")
            );
        }
        if (page) {
            params = params.set(
                "page",
                `${page}`
            );
        }
        if (term) {
            params = params.set(
                "term",
                `${term}`
            );
        }
        return this.http.get<Array<User>>(
            `${environment.baseApiUrl}/users/`,
            {params}
        ).
            pipe(map((res: any) => res.map((d: any) => new User(d))));
    }

    count (groups?: Array<string>, term?: string): Observable<number> {
        let params = new HttpParams();
        if (groups) {
            params = params.set(
                "groups",
                groups.join(",")
            );
        }
        if (term) {
            params = params.set(
                "term",
                `${term}`
            );
        }
        return this.http.get<number>(
            `${environment.baseApiUrl}/users/count/`,
            {params}
        );
    }


    update (user: User) {
        return this.http.put<User>(
            `${environment.baseApiUrl}/users/${user.id}/`,
            user
        ).pipe(map((res: any) => new User(res)));
    }

    partialUpdate (id: number, data: Object) {
        return this.http.patch<User>(
            `${environment.baseApiUrl}/users/${id}/`,
            data
        ).pipe(map((res: any) => new User(res)));
    }

    create (user: User) {
        return this.http.post<User>(
            `${environment.baseApiUrl}/users/`,
            user
        ).pipe(map((res: any) => new User(res)));
    }

    destroy (id: number) {
        return this.http.delete<User>(`${environment.baseApiUrl}/users/${id}/`);
    }

    block (id: number) {
        return this.http.post<User>(
            `${environment.baseApiUrl}/users/${id}/block/`,
            {}
        ).pipe(map((res: any) => new User(res)));
    }

    unblock (id: number) {
        return this.http.post<User>(
            `${environment.baseApiUrl}/users/${id}/unblock/`,
            {}
        ).pipe(map((res: any) => new User(res)));
    }
}
