<div class="modal-header">
    <h4 class="modal-title">Export la session  {{ examSession.code }} en XML</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngFor="let exam of exams; let i = index" style="margin-bottom: 10px;">
        <div class="form-check">
            <input type="checkbox" class="form-check-input" (change)="onSelectExam(exam)" [checked]="isSelected(exam)">
            <label class="form-check-label">{{ exam.student.first_name }} {{ exam.student.last_name }}</label>
        </div>
        <small class="text-danger" *ngIf="isSelected(exam) && !exam.locked">
            Attention ! Cet examen n'est pas verrouillé. Les données pourraient être modifiées.
        </small>
        <small class="text-danger" *ngIf="isSelected(exam) && !exam.diplome">
            Attention ! Cet examen n'a pas encore de diplome.
        </small>
    </div>

    <div *ngIf="!exams.length" class="alert alert-warning">
        Aucun candidat n'a encore réussi son examen pour cette session.
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-primary"
        [disabled]="!selectedExams.length || exporting"
        (click)="export()">
        <span *ngIf="exporting" class="button-loader"></span>
        Exporter
    </button>
    <button
        type="button"
        class="btn btn-outline-danger"
        [disabled]="exporting"
        (click)="close()">
        Annuler
    </button>
</div>
