import { Component, OnInit } from '@angular/core';
import { ExamCenter } from '../../../shared/models/examCenter';
import { take, startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExamCenterModalComponent } from '../exam-center-modal/exam-center-modal.component';
import { ExamCenterService } from '../../../shared/services/exam-center/exam-center.service';

@Component({
  selector: 'app-exam-center-list',
  templateUrl: './exam-center-list.component.html',
  styleUrls: ['./exam-center-list.component.scss']
})
export class ExamCenterListComponent implements OnInit {

  examCenters: Array<ExamCenter>; // List of all the exams fetched from backend
  examCenters$: Observable<Array<ExamCenter>>; // Search-filtered exam observable
  searchForm: FormGroup;
  loading: boolean;

  constructor(
    private examCenterService: ExamCenterService,
    private modalService: NgbModal,
  ) {
    this.searchForm = new FormGroup({
      filter: new FormControl('')
    });
  }

  search(text: string) {
    return this.examCenters.filter((examCenter: ExamCenter) => {
      const term = text.toLowerCase();
      return examCenter.nom.toLowerCase().includes(term)
        || examCenter.boss.fullName.toLowerCase().includes(term)
        || examCenter.adresse1.toLowerCase().includes(term)
        || examCenter.adresse2.toLowerCase().includes(term)
        || examCenter.zipcode.toLowerCase().includes(term)
        || examCenter.city.toLowerCase().includes(term)
        || examCenter.siret.toLowerCase().includes(term)
        || examCenter.tva.toLowerCase().includes(term)
    })
  }

  ngOnInit() {
    this.fetchExamCenters();
  }

  fetchExamCenters() {

    // Reset the search field
    this.searchForm.get('filter').setValue('');

    this.loading = true;
    this.examCenterService.list().pipe((take(1))).subscribe((res: Array<ExamCenter>) => {
      this.loading = false;
      this.examCenters = res
      this.examCenters$ = this.searchForm.get('filter').valueChanges
        .pipe(
          startWith(''),
          map((text: string) => this.search(text))
        )
    });
  }

  add() {
    const modalRef = this.modalService.open(ExamCenterModalComponent, { size: 'lg' });
    modalRef.componentInstance.examCenter = null;
    modalRef.result.then((res: ExamCenter) => {
      this.fetchExamCenters();
    });
  }

  edit(examCenter: ExamCenter) {
    const modalRef = this.modalService.open(ExamCenterModalComponent, { size: 'lg' });
    modalRef.componentInstance.examCenter = examCenter;
    modalRef.result.then((res: ExamCenter) => {
      this.fetchExamCenters();
    });
  }

  delete(examCenter: ExamCenter) {
    let res = confirm(`Êtes-vous sûr de vouloir supprimer ce centre d'examen ? Si ce centre d'examen a des sessions associées, elles seront également supprimées.`)
    if (res) {
      this.examCenterService.destroy(examCenter.id).pipe(take(1)).subscribe((res: any) => {
        this.fetchExamCenters();
      });
    }
  }
}
