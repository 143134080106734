import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import {User} from "../../../shared/models/user";
import {Observable} from "rxjs";
import {FormGroup, FormControl} from "@angular/forms";
import {UserService} from "../../../shared/services/user/user.service";
import {take, startWith, map, debounceTime} from "rxjs/operators";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UserModalComponent} from "../user-modal/user-modal.component";
import {GroupsEnum} from "../../../shared/enums/groupsEnum";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
    selector: "app-user-list",
    templateUrl: "./user-list.component.html",
    styleUrls: ["./user-list.component.scss"]
})
export class UserListComponent implements OnInit {

  @Input() selectGroup: GroupsEnum;
  @Output() selectedUser: EventEmitter<User>; // Used only when the list is in "select mode"
  users: Array<User>; // List of all the exams fetched from backend
  searchTermControl: FormControl;
  groups = GroupsEnum; // Reference to get it in the htmls
  group: GroupsEnum;
  user: User;
  selectMode: boolean; // The component can be used also as a select list, which changes the display a little bit
  loading: boolean;

  // Pagination related
  page: number;
  pageSize: number;
  itemsCount: number;

  constructor (
    private userService: UserService,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) {
      this.page = 1;
      this.pageSize = environment.pageSize;
      this.selectedUser = new EventEmitter<User>();
      this.searchTermControl = new FormControl("");
  }

  ngOnInit () {

      // Subscribe to the change of value of the search field
      this.searchTermControl.valueChanges.pipe(debounceTime(1000)).
          subscribe(() => {
              this.fetchItemCount();
              this.fetchCurrentPage();
          });

      // If there's a 'selectGroup' input, then we know that the component is in "select mode"
      if (this.selectGroup) {
          this.group = this.selectGroup;
          this.selectMode = true;
          this.fetchCurrentPage();
          this.fetchItemCount();
      }

      // Get the user group from the route
      else {
          this.route.paramMap.subscribe((params: ParamMap) => {
              this.group = params.get("group") as GroupsEnum;
              this.fetchCurrentPage();
              this.fetchItemCount();
          });
      }

      this.userService.getMe().pipe(take(1)).
          subscribe((user: User) => {
              this.user = user;
          });
  }

  fetchCurrentPage () {
      this.loading = true;
      const searchTerm = this.searchTermControl.value;
      this.userService.list(
          [this.group],
          this.page,
          searchTerm
      ).pipe(take(1)).
          subscribe((res: Array<User>) => {
              this.users = res;
              this.loading = false;
          });
  }

  fetchItemCount () {
      const searchTerm = this.searchTermControl.value;
      this.userService.count(
          [this.group],
          searchTerm
      ).pipe(take(1)).
          subscribe((count: number) => {
              this.itemsCount = count;
          });
  }

  onPageChange (newPage: number) {
      this.page = newPage;
      this.fetchCurrentPage();
  }

  add () {
      const modalRef = this.modalService.open(
          UserModalComponent,
          {size: "lg"}
      );
      modalRef.componentInstance.defaultGroup = this.group;
      modalRef.result.then((res: User) => {
          this.fetchCurrentPage();
          this.fetchItemCount();
      }).catch((err) => {
      // Catching to prevent an error shown in the console
      });
  }

  edit (user: User) {
      const modalRef = this.modalService.open(
          UserModalComponent,
          {size: "lg"}
      );
      modalRef.componentInstance.inputUser = user;
      modalRef.result.then((res: User) => {
          this.fetchCurrentPage();
          this.fetchItemCount();
      }).catch((err) => {
      // Catching to prevent an error shown in the console
      });
  }

  block (user: User) {
      const res = confirm("Êtes-vous sûr de vouloir bloquer cet utilisateur. Il pourra toujours se connecter mais un message indiquant le blocage de son compte s'affichera à la place de son profil");
      if (res) {
          this.userService.block(user.id).pipe(take(1)).
              subscribe((res: any) => {
                  this.fetchCurrentPage();
                  this.fetchItemCount();
              });
      }
  }

  unblock (user: User) {
      const res = confirm("Êtes-vous sûr de vouloir débloquer cet utilisateur ?");
      if (res) {
          this.userService.unblock(user.id).pipe(take(1)).
              subscribe((res: any) => {
                  this.fetchCurrentPage();
                  this.fetchItemCount();
              });
      }
  }

  delete (user: User) {
      const res = confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?");
      if (res) {
          this.userService.destroy(user.id).pipe(take(1)).
              subscribe((res: any) => {
                  this.fetchCurrentPage();
                  this.fetchItemCount();
              });
      }
  }

  select (user: User) {
      this.selectedUser.emit(user);
  }
}
