import {StudentProfile} from "./studentProfile";
import {Group} from "./group";
import {SupervisorProfile} from "./supervisorProfile";
import {ExamSession} from "./examSession";
import {GroupsEnum} from "../enums/groupsEnum";
import {Exam} from "./exam";
import {ExamCenter} from "./examCenter";

export class User {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    is_student: boolean;
    is_blocked: boolean;
    groups: Array<Group>;
    student_profile: StudentProfile;
    supervisor_profile: SupervisorProfile;
    supervising_sessions: Array<ExamSession>;
    correcting_sessions: Array<ExamSession>;
    exams: Array<Exam>;
    examCenters: Array<ExamCenter>;

    constructor (data: any = null) {
        if (data) {
            this.id = data.id;
            this.email = data.email;
            this.first_name = data.first_name;
            this.last_name = data.last_name;
            this.phone_number = data.phone_number;
            this.is_student = data.is_student;
            this.is_blocked = data.is_blocked;
            if (data.groups) {
                this.groups = data.groups.map((rawGroup: any) => new Group(rawGroup));
            }
            if (data.student_profile) {
                this.student_profile = new StudentProfile(data.student_profile);
            }
            if (data.supervisor_profile) {
                this.supervisor_profile = new SupervisorProfile(data.supervisor_profile);
            }
            if (data.supervising_sessions) {
                this.supervising_sessions = data.supervising_sessions.map((s: any) => new ExamSession(s));
            }
            if (data.correcting_sessions) {
                this.correcting_sessions = data.correcting_sessions.map((s: any) => new ExamSession(s));
            }
            if (data.exams) {
                this.exams = data.exams.map((s: any) => new Exam(s));
            }
            if (data.exam_centers) {
                this.examCenters = data.exam_centers.map((s: any) => new ExamCenter(s));
            }
        }
    }

    get fullName () {
        return `${this.first_name} ${this.last_name}`;
    }

    get supervisions () {
        if (!this.supervising_sessions) {
            return 0;
        }
        return this.supervising_sessions.length;
    }

    get corrections () {
        if (!this.correcting_sessions) {
            return 0;
        }
        return this.correcting_sessions.length;
    }

    get supervisingSessionsWaitingConfirm () {
        if (!this.supervising_sessions) {
            return 0;
        }
        let count = 0;
        for (let i = 0; i < this.supervising_sessions.length; i++) {
            if (this.supervising_sessions[i].supervisorConfirmed == null) {
                count++;
            }
        }
        return count;
    }

    get correctingSessionsWaitingConfirm () {
        if (!this.correcting_sessions) {
            return 0;
        }
        let count = 0;
        for (let i = 0; i < this.correcting_sessions.length; i++) {
            if (this.correcting_sessions[i].correctorConfirmed == null) {
                count++;
            }
        }
        return count;
    }

    get isSupervisor (): boolean {
        if (!this.groups) {
            return false;
        }
        if (!this.groups.length) {
            return false;
        }
        return this.groups.some((g: Group) => g.name == GroupsEnum.SUPERVISOR);
    }

    get isCorrector (): boolean {
        if (!this.groups) {
            return false;
        }
        if (!this.groups.length) {
            return false;
        }
        return this.groups.some((g: Group) => g.name == GroupsEnum.CORRECTOR);
    }

    get isStudent (): boolean {
        if (!this.groups) {
            return false;
        }
        if (!this.groups.length) {
            return false;
        }
        return this.groups.some((g: Group) => g.name == GroupsEnum.STUDENT);
    }

    get isStaff (): boolean {
        if (!this.groups) {
            return false;
        }
        if (!this.groups.length) {
            return false;
        }
        return this.groups.some((g: Group) => g.name == GroupsEnum.STAFF);
    }

    get isExamCenterBoss (): boolean {
        if (!this.groups) {
            return false;
        }
        if (!this.groups.length) {
            return false;
        }
        return this.groups.some((g: Group) => g.name == GroupsEnum.EXAM_CENTER_BOSS);
    }

    get successCount (): number {
        if (!this.exams) {
            return 0;
        }
        let count = 0;
        for (let i = 0; i < this.exams.length; i++) {
            if (this.exams[i].success) {
                count++;
            }
        }
        return count;
    }

    toString () {
        return this.fullName || this.email;
    }
}
