import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../shared/services/authentication/authentication.service';
import { ResetPasswordDTO } from '../../../shared/dto/resetPasswordDTO';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {

  forgottenPasswordForm: FormGroup;
  errors: any;
  submitted: boolean;
  loading: boolean;
  genericError = "Une erreur est survenue lors de la réinitialisation du mot de passe";
  success: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
  ) {
    this.errors = {};
    this.success = false;
    this.submitted = false;
    this.loading = false;
  }

  ngOnInit(): void {
    this.forgottenPasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.forgottenPasswordForm.controls; }

  onSubmit() {
    this.errors = {};
    this.submitted = true;
    this.success = false;

    // stop here if form is invalid
    if (this.forgottenPasswordForm.invalid) {
      this.errors['default'] = "Erreur: vérifiez les informations avant de les soumettre à nouveau"
      return;
    }

    this.loading = true;

    let resetPasswordDTO = new ResetPasswordDTO({
      email: this.f.email.value,
    });

    this.authenticationService.resetPassword(resetPasswordDTO).pipe(take(1)).subscribe(
      () => {
        this.success = true;
        this.loading = false;
        this.forgottenPasswordForm.reset();
      },
      (err: any) => {
        this.loading = false;

        // If we have detailed errors, we'll set them for each of the form's field
        if (err.error) {
          for (let type in err.error) {
            this.errors[type] = err.error[type].join(' ');
          }
          if (err.error['non_field_errors']) {
            this.errors['default'] = this.genericError;
          }
        } else {
          this.errors['default'] = this.genericError;
        }
      });
  }
}
