<div class="mt-3">
    <div class="row">
        <div class="col">
            <button class="btn btn-link" (click)=back()><i class="fas fa-arrow-left"></i> Retour</button>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 offset-sm-4">
            <br>
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
                        <h5>Changer le mot de passe</h5>
                        <div class="form-group">
                            <label for="inputPassword">Mot de passe actuel</label>
                            <input type="password" class="form-control" formControlName="password" id="inputPassword">
                            <small class="text-danger" *ngIf="errors['password']">{{errors['password']}}</small>
                        </div>
                        <div class="form-group">
                            <label for="inputNewPassword1">Nouveau mot de passe</label>
                            <input type="password" class="form-control" formControlName="new_password1"
                                id="inputNewPassword1">
                            <small class="text-danger"
                                *ngIf="errors['new_password1']">{{errors['new_password1']}}</small>
                        </div>
                        <div class="form-group">
                            <label for="inputNewPassword2">Confirmez le nouveau mot de passe</label>
                            <input type="password" class="form-control" formControlName="new_password2"
                                id="inputNewPassword2">
                            <small class="text-danger"
                                *ngIf="errors['new_password2']">{{errors['new_password2']}}</small>
                        </div>
                        <div class="alert alert-danger" *ngIf="errors['default']">
                            {{errors['default']}}
                        </div>
                        <div class="alert alert-success" *ngIf="success">
                            Votre mot de passe a bien été changé
                        </div>
                        <button [disabled]="loading" type="submit" class="btn btn-primary btn-block">
                            <span *ngIf="loading" class="button-loader"></span>
                            Valider
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>