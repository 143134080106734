<div class="row">
    <div class="col">
        <div class="form-group form-inline">
            <input class="form-control" type="text" [formControl]="searchTermControl" placeholder="Rechercher" />
            <small class="ml-2">
                Total: {{itemsCount}}
            </small>
        </div>
    </div>
    <div class="col">
        <ngb-pagination (pageChange)="onPageChange($event)" [page]="page" [pageSize]="pageSize"
            [collectionSize]="itemsCount"></ngb-pagination>
    </div>
    <div class="col d-flex justify-content-end" *ngIf="user">
        <ng-container [ngSwitch]="group">
            <ng-container *ngSwitchCase="groups.SUPERVISOR">
                <button class="btn btn-link" (click)="add()"><i class="fas fa-plus"></i>
                    Ajouter un nouveau superviseur
                </button>
            </ng-container>
            <ng-container *ngSwitchCase="groups.CORRECTOR">
                <button class="btn btn-link" (click)="add()"><i class="fas fa-plus"></i>
                    Ajouter un nouveau correcteur
                </button>
            </ng-container>
            <ng-container *ngSwitchCase="groups.STUDENT">
                <button *ngIf="(user.isStaff || user.isExamCenterBoss || user.isSupervisor) && !selectMode"
                    class="btn btn-link" (click)="add()"><i class="fas fa-plus"></i>
                    Ajouter un nouveau candidat
                </button>
            </ng-container>
        </ng-container>
    </div>
</div>
<app-loading-overlay [active]="loading">
    <table class="table table-striped" *ngIf="user">
        <thead>
            <tr>
                <ng-container [ngSwitch]="group">
                    <ng-container *ngSwitchCase="groups.SUPERVISOR">
                        <th *ngIf="!user.isExamCenterBoss" scope="col">Centre d'examen adhérant</th>
                        <th scope="col">Superviseur</th>
                        <th scope="col">Mail</th>
                        <th scope="col">Téléphone</th>
                        <th scope="col">Supervisions</th>
                        <th scope="col">En attente de confirmation</th>
                    </ng-container>
                    <ng-container *ngSwitchCase="groups.CORRECTOR">
                        <th scope="col">Correcteur</th>
                        <th scope="col">Mail</th>
                        <th scope="col">Corrections</th>
                        <th scope="col">En attente de confirmation</th>
                    </ng-container>
                    <ng-container *ngSwitchCase="groups.STUDENT">
                        <th scope="col">Candidat</th>
                        <th scope="col">Mail</th>
                        <th scope="col">Téléphone</th>
                        <th scope="col" *ngIf="!selectMode">Adresse</th>
                        <th scope="col" *ngIf="!selectMode">Public</th>
                        <th scope="col" *ngIf="!selectMode">Tentatives</th>
                        <th scope="col" *ngIf="!selectMode">Succès</th>
                    </ng-container>
                    <th *ngIf="user.isStaff || user.isSupervisor || selectMode" scope="col">Actions</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let u of users; index as i">
                <ng-container [ngSwitch]="group">
                    <ng-container *ngSwitchCase="groups.SUPERVISOR">
                        <td *ngIf="!user.isExamCenterBoss">
                            <ngb-highlight [result]="u.supervisor_profile?.center.nom" [term]="searchTermControl.value">
                            </ngb-highlight>
                        </td>
                        <td>
                            <ngb-highlight [result]="u.fullName" [term]="searchTermControl.value"></ngb-highlight>
                        </td>
                        <td>
                            <ngb-highlight [result]="u.email" [term]="searchTermControl.value"></ngb-highlight>
                        </td>
                        <td>
                            <ngb-highlight [result]="u.phone_number" [term]="searchTermControl.value"></ngb-highlight>
                        </td>
                        <td>
                            {{u.supervisions}}
                        </td>
                        <td>
                            {{u.supervisingSessionsWaitingConfirm}}
                        </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="groups.CORRECTOR">
                        <td>
                            <ngb-highlight [result]="u.fullName" [term]="searchTermControl.value">
                            </ngb-highlight>
                        </td>
                        <td>
                            <ngb-highlight [result]="u.email" [term]="searchTermControl.value"></ngb-highlight>
                        </td>
                        <td>
                            {{u.corrections}}
                        </td>
                        <td>
                            {{u.correctingSessionsWaitingConfirm}}
                        </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="groups.STUDENT">
                        <td>
                            <ngb-highlight [result]="u.fullName" [term]="searchTermControl.value"> </ngb-highlight>
                        </td>
                        <td>
                            <ngb-highlight [result]="u.email" [term]="searchTermControl.value">
                            </ngb-highlight>
                        </td>
                        <td>
                            <ngb-highlight [result]="u.phone_number" [term]="searchTermControl.value"></ngb-highlight>
                        </td>
                        <td *ngIf="!selectMode">
                            {{u.student_profile?.address}} {{u.student_profile?.zipcode}} {{u.student_profile?.city}}
                        </td>
                        <td *ngIf="!selectMode">
                            <span *ngIf="u.student_profile?.wants_to_be_published == true" class="text-success"><i
                                    class="fas fa-check"></i></span>
                            <span *ngIf="u.student_profile?.wants_to_be_published == false" class="text-danger"><i
                                    class="fas fa-times"></i></span>
                        </td>
                        <td *ngIf="!selectMode">
                            {{u.exams.length}}
                        </td>
                        <td *ngIf="!selectMode">
                            {{u.successCount}}
                        </td>
                    </ng-container>
                </ng-container>
                <td *ngIf="user.isStaff || user.isSupervisor || selectMode">
                    <button *ngIf="!selectMode" class="btn btn-link" (click)="edit(u)"><i class="fas fa-edit"></i>
                        Modifier</button>
                    <button *ngIf="!selectMode" class="btn btn-link" (click)="delete(u)"><i class="fas fa-trash"></i>
                        Supprimer</button>
                    <button *ngIf="!selectMode && !u.is_blocked" class="btn btn-outline-danger" (click)="block(u)"><i class="fas fa-lock"></i>
                        Bloquer</button>
                    <button *ngIf="!selectMode && u.is_blocked" class="btn btn-danger" (click)="unblock(u)"><i class="fas fa-lock"></i>
                        Débloquer</button>
                    <button *ngIf="selectMode" class="btn btn-success" (click)="select(u)">Sélectionner</button>
                </td>
            </tr>
        </tbody>
    </table>
</app-loading-overlay>
