<div class="container">
    <h2 class="text-center mb-5">Contactez nous</h2>
    <div class="row">
        <div class="col-md-6 offset-md-3">
            <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
                <div class="form-group">
                    <label for="comment">Votre message</label>
                    <textarea class="form-control" formControlName="comment" name="comment"></textarea>
                </div>
                <button type="submit" class="btn btn-primary" [disabled]="!FormData.valid">
                    <span *ngIf="submitting" class="button-loader"></span>
                    Envoyer
                </button>
            </form>
            <div *ngIf="error" class="alert alert-danger">
                Votre message n'a pas pu être envoyé
            </div>
            <div *ngIf="success" class="alert alert-success">
                Votre message a été envoyé
            </div>
        </div>
    </div>
</div>