import { Component, OnInit } from '@angular/core';
import { User } from '../../shared/models/user';
import { UserService } from '../../shared/services/user/user.service';
import { take } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-file-dashboard',
  templateUrl: './file-dashboard.component.html',
  styleUrls: ['./file-dashboard.component.scss']
})
export class FileDashboardComponent implements OnInit {

  user: User;

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.userService.getMe().pipe(take(1)).subscribe((user: User) => {
      this.user = user;

      // Redirect user to its right default files tab
      if(this.router.url == '/dashboard/files') {
        if (this.user.isStaff || this.user.isExamCenterBoss) this.router.navigate(['invoices'], { relativeTo: this.activatedRoute });
        else this.router.navigate(['misc_files'], { relativeTo: this.activatedRoute });
      }
    })
  }
}
