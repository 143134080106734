<div class="row">
    <div class="col">
        <form [formGroup]="searchForm">
            <div class="form-group form-inline">
                <input class="form-control" type="text" formControlName="filter" placeholder="Rechercher" />
            </div>
        </form>
    </div>
    <div class="col d-flex justify-content-end">
        <button class="btn btn-link" (click)="add()"><i class="fas fa-plus"></i> Ajouter un nouveau centre
            d'examen</button>
    </div>
</div>

<app-loading-overlay [active]="loading">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Nom</th>
                <th scope="col">Représentant Légal</th>
                <th scope="col">Adresse</th>
                <th scope="col">SIRET</th>
                <th scope="col">TVA</th>
                <th scope="col">Adhésion</th>
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let center of examCenters$ | async; index as i">
                <td>
                    <ngb-highlight [result]="center.nom" [term]="searchForm.value.filter"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="center.boss.fullName" [term]="searchForm.value.filter"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="[center.adresse1, center.adresse2, center.zipcode, center.city].join(' ')" [term]="searchForm.value.filter"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="center.siret" [term]="searchForm.value.filter"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="center.tva" [term]="searchForm.value.filter"></ngb-highlight>
                </td>
                <td>
                    <span *ngIf="center.isMembershipValid">Valide</span>
                    <span class="text-danger" *ngIf="!center.isMembershipValid">Expirée</span>
                </td>
                <td>
                    <button class="btn btn-link" (click)="edit(center)"><i class="fas fa-edit"></i> Modifier</button>
                    <button class="btn btn-link" (click)="delete(center)"><i class="fas fa-trash"></i>
                        Supprimer</button>
                </td>
            </tr>
        </tbody>
    </table>
</app-loading-overlay>