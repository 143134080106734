<div class="row">
    <div class="col">
        <form [formGroup]="searchForm">
            <div class="form-group form-inline">
                <input class="form-control" type="text" formControlName="filter" placeholder="Rechercher" />
            </div>
        </form>
    </div>
    <div class="col d-flex justify-content-end">
        <button class="btn btn-link" (click)="add()" *ngIf="user?.isStaff"><i class="fas fa-plus"></i> Ajouter une
            nouvelle facture</button>
    </div>
</div>
<app-loading-overlay [active]="loading">
    <table class="table table-striped" *ngIf="user">
        <thead>
            <tr>
                <th scope="col">Facture</th>
                <th scope="col">Centre d'examen</th>
                <th scope="col">Date</th>
                <th scope="col">Fichier</th>
                <th scope="col" *ngIf="user.isStaff">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let invoice of invoices$ | async; index as i">
                <td>
                    <ngb-highlight [result]="invoice.label" [term]="searchForm.value.filter"></ngb-highlight>
                </td>
                <td>
                    <ng-container *ngIf="!invoice.center">Non attribué</ng-container>
                    <ngb-highlight *ngIf="invoice.center" [result]="invoice.center.nom"
                        [term]="searchForm.value.filter">
                    </ngb-highlight>
                </td>
                <td>
                    {{invoice.date | date:'dd/MM/yyyy'}}
                </td>
                <td>
                    <ng-container *ngIf="invoice.document">
                        <button
                            class="btn btn-link"
                            (click)="showDocument(invoice)"
                            [disabled]="retrievingDocumentForInvoiceId == invoice.id">
                            <span *ngIf="retrievingDocumentForInvoiceId == invoice.id" class="button-loader"></span>
                            Voir le fichier
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!invoice.document">
                        -
                    </ng-container>
                </td>
                <td *ngIf="user.isStaff">
                    <button class="btn btn-link" (click)="edit(invoice)"><i class="fas fa-edit"></i> Modifier</button>
                    <button class="btn btn-link" (click)="delete(invoice)"><i class="fas fa-trash"></i>
                        Supprimer</button>
                </td>
            </tr>
        </tbody>
    </table>
</app-loading-overlay>
