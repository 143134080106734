import {AuthGuardService} from "./shared/services/auth-guard/auth-guard.service";
import {CertificationListComponent} from "./components/certification/certification-list/certification-list.component";

import {ContactFormComponent} from "./components/contact-form/contact-form.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {ExamCenterListComponent} from "./components/exam-center/exam-center-list/exam-center-list.component";
import {ExamCenterMembershipListComponent} from "./components/exam-center-membership/exam-center-membership-list/exam-center-membership-list.component";
import {ExamListComponent} from "./components/exam/exam-list/exam-list.component";
import {ExamTestComponent} from "./components/exam-test/exam-test.component";
import {ExamMockTestComponent} from "./components/exam-mock-test/exam-mock-test.component";
import {FileDashboardComponent} from "./components/file-dashboard/file-dashboard.component";
import {ForgottenPasswordComponent} from "./components/auth/forgotten-password/forgotten-password.component";
import {InvoiceListComponent} from "./components/invoice/invoice-list/invoice-list.component";
import {LoginComponent} from "./components/auth/login/login.component";
import {MiscFileListComponent} from "./components/misc-file/misc-file-list/misc-file-list.component";
import {NgModule} from "@angular/core";
import {NoAuthGuardService} from "./shared/services/no-auth-guard/no-auth-guard.service";
import {RouterModule, Routes} from "@angular/router";
import {SessionListComponent} from "./components/session/session-list/session-list.component";
import { PreregistrationListComponent } from "./components/preregistration/preregistration-list/preregistration-list.component";
import {SettingsComponent} from "./components/settings/settings.component";
import {SignupComponent} from "./components/auth/signup/signup.component";
import {StudentProfileComponent} from "./components/student-profile/student-profile/student-profile.component";
import {UserListComponent} from "./components/user/user-list/user-list.component";

const routes: Routes = [
    {path: "login", component: LoginComponent, canActivate: [NoAuthGuardService]},
    {path: "signup", component: SignupComponent, canActivate: [NoAuthGuardService]},
    {path: "forgotten_password", component: ForgottenPasswordComponent, canActivate: [NoAuthGuardService]},
    {path: "settings", component: SettingsComponent, canActivate: [AuthGuardService]},
    {path: "contact", component: ContactFormComponent, canActivate: [AuthGuardService]},
    {
        path: "dashboard", component: DashboardComponent, canActivate: [AuthGuardService],
        children: [
            {path: "certifications", component: CertificationListComponent},
            {path: "tickets", component: ExamListComponent},
            {path: "sessions", component: SessionListComponent},
            {path: "preregistrations", component: PreregistrationListComponent},
            {path: "exam_centers", component: ExamCenterListComponent},
            {path: "exam_center_memberships", component: ExamCenterMembershipListComponent},
            {path: "users/:group", component: UserListComponent},
            {path: "student_profile", component: StudentProfileComponent},
            {path: "exam_test/:exam_id/:test_id", component: ExamTestComponent},
            {path: "exam_mock_test/:exam_id/:test_id", component: ExamMockTestComponent},
            {
                path: "files", component: FileDashboardComponent, children: [
                    {path: "invoices", component: InvoiceListComponent},
                    {path: "misc_files", component: MiscFileListComponent}
                ]
            }
        ]
    },

    // Default redirection
    {path: "**", redirectTo: "dashboard"}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
