<div class="mt-3">
    <div class="row">
        <div class="col-sm-4 offset-sm-4">
            <br>
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="forgottenPasswordForm" (ngSubmit)="onSubmit()">
                        <h5>Mot de passe oublié</h5>
                        <div class="form-group">
                            <label for="inputEmail">Email</label>
                            <input type="email" class="form-control" formControlName="email" id="inputEmail">
                            <small class="text-danger" *ngIf="errors['email']">{{errors['email']}}</small>
                        </div>
                        <div class="alert alert-danger" *ngIf="errors['default']">
                            {{errors['default']}}
                        </div>
                        <div class="alert alert-success" *ngIf="success">
                            Un email vous été envoyé afin de continuer la procédure de réinitialisation du mot de passe.
                        </div>
                        <button [disabled]="loading" type="submit" class="btn btn-primary btn-block">Réinitialiser le mot de passe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>