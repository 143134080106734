import { CertificationTest } from './certificationTest';

export class ExamResultValue {
    test: CertificationTest;
    value: number;
    success: boolean;
    commentaire: string;
    is_done: boolean;

    constructor(data: any) {
        this.test = new CertificationTest(data.test);
        this.value = data.value;
        this.success = data.success;
        this.commentaire = data.commentaire;
        this.is_done = data.is_done;
    }
}