<div class="modal-header">
  <h4 class="modal-title">Commentaires</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
<table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Date</th>
      <th scope="col">Autheur</th>
      <th scope="col">Commentaire</th>
      <th *ngIf="user.isStaff || user.isSupervisor || user.isCorrector" scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let comment of session.comments; index as i">
      <td>{{comment.created_date | date:'dd/MM/yyyy'}}</td>
      <td>{{comment.author}}</td>
      <td>
        <div
          *ngIf="editedComment && editedComment.id == comment.id">
          <form [formGroup]="editCommentForm">
            <input type="text" class="form-control" formControlName='comment' placeholder="Editer le commentaire">
          </form>
        </div>
        <div *ngIf="!editedComment || editedComment.id != comment.id">{{comment.comment}}</div>
      </td>
      <td *ngIf="user.isStaff || user.isSupervisor || user.isCorrector">
        <button *ngIf="!editedComment || editedComment.id != comment.id" class="btn btn-link" (click)="editComment(comment)" [disabled]="deletedComment != null && deletedComment.id == comment.id">
          <i class="fas fa-edit"></i>
        </button>
        <button *ngIf="editedComment && editedComment.id == comment.id" class="btn btn-link" (click)="submitEditedComment()" [disabled]="editCommentForm.invalid || submittingEditedComment">
          <span *ngIf="submittingEditedComment" class="button-loader"></span>
          <i *ngIf="!submittingEditedComment" class="fas fa-check"></i>
        </button>
        <button *ngIf="!editedComment || editedComment.id != comment.id" class="btn btn-link" (click)="deleteComment(comment)" [disabled]="deletedComment != null && deletedComment.id == comment.id">
          <span *ngIf="deletedComment != null && deletedComment.id == comment.id" class="button-loader"></span>
          <i *ngIf="deletedComment == null || deletedComment.id != comment.id" class="fas fa-trash"></i>
        </button>
        <button *ngIf="editedComment && editedComment.id == comment.id" class="btn btn-link" (click)="cancelEditComment()" [disabled]="submittingEditedComment">
          <i class="fas fa-times"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
<form *ngIf="user.isStaff || user.isSupervisor || user.isCorrector" [formGroup]="form">
  <div style="display: flex;">
    <textarea class="form-control" id="comment" formControlName='comment' placeholder="Nouveau commentaire" style="margin-right: 10px;"></textarea>
    <button type="button" class="btn btn-outline-secondary" [disabled]="form.invalid || submittingComment" (click)="submitComment()">
      <span *ngIf="submittingComment" class="button-loader"></span>
      Commenter
    </button>
  </div>
</form>
<div *ngIf="error" class="alert alert-danger">
  {{error}}
</div>
</div>
<div class="modal-footer">
<button type="button" class="btn btn-outline-primary" (click)="activeModal.dismiss()">Fermer</button>
</div>
