<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="selectForm">
        <div class="form-group row">
            <div class="col">
                <select class="form-control" formControlName="selected" id="optionSelect">
                    <option *ngFor="let option of options; let i = index" [value]='options[i].id'>
                        {{options[i].toString()}}
                    </option>
                </select>
            </div>
        </div>
    </form>
    <div *ngIf="error" class="alert alert-danger">
        {{error}}
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">Annuler</button>
    <button type="button" class="btn btn-outline-success" (click)="validate()">
        Valider
    </button>
</div>