import {Component, Input, OnInit} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ExamService} from "../../../shared/services/exam/exam.service";
import {ExamCommentService} from "../../../shared/services/exam-comment/exam-comment.service";
import {take} from "rxjs/operators";
import {Exam} from "../../../shared/models/exam";
import {ExamComment} from "../../../shared/models/examComment";
import {UserService} from "../../../shared/services/user/user.service";
import {User} from "../../../shared/models/user";


@Component({
    selector: "app-exam-comments-modal",
    templateUrl: "./exam-comments-modal.component.html",
    styleUrls: ["./exam-comments-modal.component.scss"]
})
export class ExamCommentsModalComponent implements OnInit {
    @Input() exam: Exam;
    editCommentForm: FormGroup;
    form: FormGroup;
    error: string;
    user: User;
    submittingComment: boolean = false;
    submittingEditedComment: boolean = false;
    editedComment: ExamComment = null;
    deletedComment: ExamComment = null;

    constructor (
        public activeModal: NgbActiveModal,
        private examService: ExamService,
        private examCommentService: ExamCommentService,
        private userService: UserService
    ) {
        this.form = new FormGroup({
            comment: new FormControl(
                "",
                Validators.required
            )
        });
        this.editCommentForm = new FormGroup({
            comment: new FormControl(
                "",
                Validators.required
            )
        });
    }

    ngOnInit () {
        this.userService.getMe().pipe(take(1)).
            subscribe((user: User) => {
                this.user = user;
            });
    }

    editComment (comment: ExamComment) {
        this.editedComment = comment;
        this.editCommentForm.setValue({comment: comment.comment});
    }

    cancelEditComment () {
        this.editedComment = null;
        this.editCommentForm.reset();
    }

    submitEditedComment () {
        this.submittingEditedComment = true;

        const {comment} = this.editCommentForm.value;

        this.examCommentService.partialUpdate(
            this.editedComment.id,
            {comment}
        ).pipe(take(1)).
            subscribe(
                (res: ExamComment) => {
                    this.editedComment.comment = res.comment;
                    this.submittingEditedComment = false;
                    this.cancelEditComment();
                },
                (err: any) => {
                    this.error = "Une erreur est survenue lors de la modification du commentaire";
                    this.submittingEditedComment = false;
                    throw err;
                }
            );
    }

    deleteComment (comment: ExamComment) {
        this.deletedComment = comment;

        this.examCommentService.delete(comment.id).pipe(take(1)).
            subscribe(
                (res: any) => {
                    this.exam.comments = this.exam.comments.filter((c) => c.id != comment.id);
                    this.deletedComment = null;
                },
                (err: any) => {
                    this.error = "Une erreur est survenue lors de la suppression du commentaire";
                    this.deletedComment = null;
                    throw err;
                }
            );
    }

    submitComment () {
        this.submittingComment = true;

        const {comment} = this.form.value;

        this.examService.comment(
            this.exam.id,
            comment
        ).pipe(take(1)).
            subscribe(
                (res: Exam) => {
                    this.exam.comments.push(res.comments[res.comments.length - 1]);
                    this.submittingComment = false;
                    this.form.reset();
                },
                (err: any) => {
                    this.error = "Une erreur est survenue lors de la création des codes";
                    this.submittingComment = false;
                    throw err;
                }
            );
    }

}
