import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  postMessage(post: string) {
    return this.http.post(`${environment.baseApiUrl}/contact/`, post).pipe(
      map((res: any) => {
        if (res) {
          return res;
        }
      })
    );
  }
}
