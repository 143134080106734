<div class="modal-header">
    <h4 *ngIf="!examCenter" class="modal-title">Ajouter un centre d'examen</h4>
    <h4 *ngIf="examCenter" class="modal-title">Centre {{examCenter.nom}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="centerForm" *ngIf="!isDataLoading">
        <div class="form-group row">
            <label for="nomInput" class="col-sm-4 col-form-label text-right">Nom</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="nomInput" formControlName='nom'>
            </div>
        </div>
        <div *ngIf="!createExamCenterBossMode" class="form-group row">
            <label for="bossSelect" class="col-sm-4 col-form-label text-right">Représentant Légal</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="boss" id="bossSelect">
                    <option *ngFor="let boss of bosses; let i = index" [value]='bosses[i].id'>
                        {{bosses[i].fullName}} ({{bosses[i].email}})
                    </option>
                </select>
            </div>
        </div>
        <ng-container *ngIf="createExamCenterBossMode">
          <div class="form-group row">
            <label for="first-name" class="col-sm-4 col-form-label text-right">Prénom du représentant légal</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="first-name" formControlName='boss_first_name'>
            </div>
          </div>
          <div class="form-group row">
            <label for="last-name" class="col-sm-4 col-form-label text-right">Nom du représentant légal</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="last-name" formControlName='boss_last_name'>
            </div>
          </div>
          <div class="form-group row">
            <label for="email" class="col-sm-4 col-form-label text-right">Email du représentant légal</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="email" formControlName='boss_email'>
            </div>
          </div>
          <div class="form-group row">
            <label for="phone-number" class="col-sm-4 col-form-label text-right">Téléphone du représentant légal</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="phone-number" formControlName='boss_phone_number'>
            </div>
          </div>
        </ng-container>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label text-right"> </label>
          <span *ngIf="!createExamCenterBossMode" class="btn-assign text-secondary" style="padding: 0 15px" (click)="createExamCenterBossMode = true;">
            Ou bien créer un nouveau représentant légal
          </span>
          <span *ngIf="createExamCenterBossMode" class="btn-assign text-secondary" style="padding: 0 15px" (click)="createExamCenterBossMode = false;">
            Ou bien sélectionnez un représentant légal existant
          </span>
        </div>
        <div class="form-group row">
            <label for="adresseInput1" class="col-sm-4 col-form-label text-right">Adresse ligne 1</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="adresseInput1" formControlName='adresse1'>
            </div>
        </div>
        <div class="form-group row">
            <label for="adresseInput2" class="col-sm-4 col-form-label text-right">Adresse ligne 2</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="adresseInput2" formControlName='adresse2'>
            </div>
        </div>
        <div class="form-group row">
            <label for="zipcodeInput" class="col-sm-4 col-form-label text-right">Code postal</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="zipcodeInput" formControlName='zipcode'>
            </div>
        </div>
        <div class="form-group row">
            <label for="cityInput" class="col-sm-4 col-form-label text-right">Ville</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="cityInput" formControlName='city'>
            </div>
        </div>
        <div class="form-group row">
            <label for="siretInput" class="col-sm-4 col-form-label text-right">SIRET</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="siretInput" formControlName='siret'>
            </div>
        </div>
        <div class="form-group row">
            <label for="tvaInput" class="col-sm-4 col-form-label text-right">TVA</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="tvaInput" formControlName='tva'>
            </div>
        </div>
    </form>
    <div *ngIf="error" class="alert alert-danger">
        {{error}}
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">Annuler</button>
    <button type="button" class="btn btn-outline-success" (click)="validate()">
        <span *ngIf="submitting" class="button-loader"></span>
        Valider
    </button>
</div>
