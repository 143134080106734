import {Component, OnInit} from "@angular/core";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {take} from "rxjs/operators";
import {CertificationMockTest} from "src/app/shared/models/certificationMockTest";
import {Exam} from "../../shared/models/exam";
import {ExamService} from "../../shared/services/exam/exam.service";
import {environment} from "../../../environments/environment";

@Component({
    "selector": "app-exam-mock-test",
    "templateUrl": "./exam-mock-test.component.html",
    "styleUrls": ["./exam-mock-test.component.scss"]
})
export class ExamMockTestComponent implements OnInit {

    exam: Exam;
    test: CertificationMockTest;
    embeddingQcmURL: SafeResourceUrl;
    document: string;
    error: string;
    baseUrl = environment.baseUrl;

    constructor (
        private examService: ExamService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit (): void {
        window.setTimeout(
            () => {
                this.checkIfExamCanBePassed();
            },
            2000
        );
    }

    private checkIfExamCanBePassed () {
        const examId = this.route.snapshot.paramMap.get("exam_id");

        // Fetch exam
        this.examService.retrieve(examId).pipe(take(1)).
            subscribe((exam: Exam) => {
                this.exam = exam;

                // Fetch test
                const testId = parseInt(this.route.snapshot.paramMap.get("test_id"));
                this.test = this.exam.certification.mock_tests.find((test) => test.id == testId);

                // Check that test has a document or an embedded test
                if (!this.test.type) {
                    this.error = "Cette épreuve n'a pas encore de sujet associé";
                    return;
                }

                // If we can pass the QCM, then stop the interval and fetch the QCM embedding code or the document

                this.examService.startMockTest(
                    this.exam.id,
                    this.test.id,
                    this.test.type == "embedded"
                        ? "text"
                        : "blob"
                ).pipe(take(1)).
                    subscribe(
                        (res: string | Blob) => {

                            if (res instanceof Blob) {
                                this.embeddingQcmURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));

                            } else {
                                this.embeddingQcmURL = this.sanitizer.bypassSecurityTrustResourceUrl(res);
                            }

                        },
                        (error) => {
                            this.error = error.error;
                            throw error;
                        }
                    );


            });
    }
}
