<div class="modal-header">
    <h4 *ngIf="!certification" class="modal-title">Ajouter une certification</h4>
    <h4 *ngIf="certification" class="modal-title">Certification {{certification.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="certificationForm" *ngIf="!isDataLoading">
        <div class="form-group row">
            <label for="titleInput" class="col-sm-4 col-form-label text-right">Titre</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="titleInput" formControlName='title'>
                <small *ngIf="validationErrors?.title" class="text-danger">{{ validationErrors.title }}</small>
            </div>
        </div>
        <div class="form-group row">
            <label for="categorySelect" class="col-sm-4 col-form-label text-right">Catégorie</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="category" id="categorySelect">
                    <option *ngFor="let category of categories; let i = index" [value]='categories[i].id'>
                        {{categories[i].label}}
                    </option>
                </select>
                <small *ngIf="validationErrors?.category" class="text-danger">{{ validationErrors.category }}</small>
            </div>
        </div>
        <div class="form-group row">
            <label for="descriptionInput" class="col-sm-4 col-form-label text-right">Description</label>
            <div class="col-sm-8">
                <textarea type="text" class="form-control" id="descriptionInput" formControlName='description'></textarea>
                <small *ngIf="validationErrors?.description" class="text-danger">{{ validationErrors.description }}</small>
            </div>
        </div>
        <div class="form-group row">
            <label for="codeInput" class="col-sm-4 col-form-label text-right">Code</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="codeInput" formControlName='code'>
                <small *ngIf="validationErrors?.code" class="text-danger">{{ validationErrors.code }}</small>
            </div>
        </div>
    </form>
    <div *ngIf="error" class="alert alert-danger">
        {{error}}
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">
        Annuler
    </button>
    <button type="button" class="btn btn-outline-success" (click)="validate()">
        <span *ngIf="submitting" class="button-loader"></span>
        Valider
    </button>
</div>