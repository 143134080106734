import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ContactService } from '../../shared/services/contact/contact.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  FormData: FormGroup;
  submitting: boolean;
  error: boolean;
  success: boolean;

  constructor(private builder: FormBuilder, private contact: ContactService) { 
    this.submitting = false;
    this.error = false;
    this.success = false;
  }

  ngOnInit(): void {
    this.FormData = this.builder.group({
      comment: new FormControl('', [Validators.required])
    });
  }

  onSubmit(FormData) {
    this.submitting = true;
    this.contact.postMessage(FormData).subscribe(response => {
      this.success = true;
      this.submitting = false;
      this.error = false;
    }, error => {
      this.success = false;
      this.submitting = false;
      this.error = true;
    });
  }

}
