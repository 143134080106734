import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {InvoiceService} from "../../../shared/services/invoice/invoice.service";
import {Invoice} from "../../../shared/models/invoice";
import {Observable} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {take, startWith, map} from "rxjs/operators";
import {InvoiceModalComponent} from "../invoice-modal/invoice-modal.component";
import {User} from "../../../shared/models/user";
import {UserService} from "../../../shared/services/user/user.service";
import {FileDownloadHelper} from "../../../shared/helpers/file-download.helper";
import {HttpResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";


@Component({
    selector: "app-invoice-list",
    templateUrl: "./invoice-list.component.html",
    styleUrls: ["./invoice-list.component.scss"]
})
export class InvoiceListComponent implements OnInit {

    // List of all the exams fetched from backend
    invoices: Array<Invoice>;

    // Search-filtered exam observable
    invoices$: Observable<Array<Invoice>>;
    searchForm: FormGroup;
    user: User;
    loading: boolean;
    retrievingDocumentForInvoiceId: number = null;

    constructor (
        private invoiceService: InvoiceService,
        private modalService: NgbModal,
        private userService: UserService,
        private toastr: ToastrService
    ) {
        this.searchForm = new FormGroup({
            filter: new FormControl("")
        });
    }

    search (text: string) {
        return this.invoices.filter((invoice: Invoice) => {
            const term = text.toLowerCase();
            return invoice.label.toLowerCase().includes(term) ||
            invoice.center?.nom.toLowerCase().includes(term);
        });
    }

    ngOnInit () {
        this.fetchInvoices();

        this.userService.getMe().pipe(take(1)).
            subscribe((user: User) => {
                this.user = user;
            });
    }

    fetchInvoices () {

        // Reset the search field
        this.searchForm.get("filter").setValue("");

        this.loading = true;
        this.invoiceService.list().pipe(take(1)).
            subscribe((invoices: Array<Invoice>) => {
                this.loading = false;
                this.invoices = invoices;
                this.invoices$ = this.searchForm.get("filter").valueChanges.
                    pipe(
                        startWith(""),
                        map((text: string) => this.search(text))
                    );
            });
    }

    add () {
        const modalRef = this.modalService.open(
            InvoiceModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.invoice = null;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        modalRef.result.then((res: Invoice) => {
            this.fetchInvoices();
        }).catch(() => {
        // Dummy catch to preven error in console
        });
    }

    edit (invoice: Invoice) {
        const modalRef = this.modalService.open(
            InvoiceModalComponent,
            {size: "lg"}
        );
        modalRef.componentInstance.invoice = invoice;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        modalRef.result.then((res: Invoice) => {
            this.fetchInvoices();
        }).catch(() => {
        // Dummy catch to preven error in console
        });
    }

    delete (invoice: Invoice) {
        const res = confirm("Êtes-vous sûr de vouloir supprimer cette facture ?");
        if (res) {
            this.invoiceService.destroy(invoice.id).pipe(take(1)).
                subscribe(() => {
                    this.fetchInvoices();
                });
        }
    }

    showDocument (invoice: Invoice) {
        this.retrievingDocumentForInvoiceId = invoice.id;

        this.invoiceService.retrieveFile(invoice.id).pipe(take(1)).
            subscribe(
                (response: HttpResponse<Blob>) => {
                    this.retrievingDocumentForInvoiceId = null;
                    FileDownloadHelper.downloadBlobFromResponse(response);
                },
                () => {
                    this.retrievingDocumentForInvoiceId = null;
                    this.toastr.error(
                        "Contactez l'administrateur pour plus d'informations",
                        "Fichier introuvable"
                    );
                }
            );
    }
}
