<div class="modal-header">
    <h4 class="modal-title">Résultat de l'examen {{exam.voucher_code}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngFor="let test_result of exam.examResultValues; first as isFirst;">
        <hr *ngIf="!isFirst">
        <div class="row">
            <div class="col-sm-4 col-form-label text-right">{{ test_result.test.description }}</div>
            <div class="col-sm-2 col-form-label">
                {{ test_result.value }}
            </div>
            <div class="col-form-label">
                <span *ngIf="test_result.success == false" class="text-danger">Échec</span>
                <span *ngIf="test_result.success == true" class="text-success">Succès</span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 col-form-label text-right">Commentaires</div>
            <div class="col-sm-8">
                {{ test_result.commentaire }}
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <label class="col-sm-4 col-form-label text-right">Résultat final</label>
        <label class="col-sm-8 col-form-label">
            <span *ngIf="exam.success == false" class="text-danger">
                Échec
            </span>
            <span *ngIf="exam.success == true" class="text-success">
                Succès
            </span>
        </label>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="activeModal.dismiss()">OK</button>
</div>
