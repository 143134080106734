import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";
import {ExamComment} from "../../models/examComment";

@Injectable({
    providedIn: "root"
})
export class ExamSessionCommentService {

    constructor (private http: HttpClient) { }

    partialUpdate (id: number | string, data) {
        return this.http.patch<ExamComment>(
            `${environment.baseApiUrl}/exam-session-comments/${id}/`,
            data
        ).pipe(map((res) => new ExamComment(res)));
    }

    delete (id: number | string) {
        return this.http.delete(`${environment.baseApiUrl}/exam-session-comments/${id}/`);
    }

}
