import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ChangePasswordDTO } from '../../shared/dto/changePasswordDTO';
import { AuthenticationService } from '../../shared/services/authentication/authentication.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  changePasswordForm: FormGroup;
  errors: any;
  submitted: boolean;
  loading: boolean;
  genericError = "Une erreur est survenue lors du changement de mot de passe";
  success: boolean;

  constructor(
    private location: Location,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
  ) {
    this.errors = {};
    this.success = false;
    this.submitted = false;
    this.loading = false;
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      new_password1: ['', Validators.required],
      new_password2: ['', Validators.required],
    });
  }

  back() {
    this.location.back();
  }

  // convenience getter for easy access to form fields
  get f() { return this.changePasswordForm.controls; }

  onSubmit() {
    this.errors = {};
    this.submitted = true;
    this.success = false;

    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      this.errors['default'] = "Erreur: vérifiez les informations avant de les soumettre à nouveau"
      return;
    }

    this.loading = true;

    let changePasswordDTO = new ChangePasswordDTO({
      password: this.f.password.value,
      new_password1: this.f.new_password1.value,
      new_password2: this.f.new_password2.value,
    });


    this.authenticationService.changePassword(changePasswordDTO).pipe(take(1)).subscribe(
      () => {
        this.success = true;
        this.loading = false;
        this.changePasswordForm.reset();
      },
      (err: any) => {
        this.loading = false;

        // If we have detailed errors, we'll set them for each of the form's field
        if (err.error) {
          for (let type in err.error) {
            this.errors[type] = err.error[type].join(' ');
          }
          if (err.error['non_field_errors']) {
            this.errors['default'] = this.genericError;
          }
        } else {
          this.errors['default'] = this.genericError;
        }
      });
  }

}
