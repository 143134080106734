<div class="modal-header">
    <h4 *ngIf="!invoice" class="modal-title">Ajouter une facture</h4>
    <h4 *ngIf="invoice" class="modal-title">Facture {{invoice.label}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="invoiceForm" *ngIf="!isDataLoading">
        <div class="form-group row">
            <label for="labelInput" class="col-sm-4 col-form-label text-right">Label</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="labelInput" formControlName='label'>
            </div>
        </div>
        <div class="form-group row">
            <label for="centerSelect" class="col-sm-4 col-form-label text-right">Centre d'examen</label>
            <div class="col-sm-8">
                <select class="form-control" formControlName="center" id="centerSelect">
                    <option *ngFor="let examCenter of examCenters; let i = index" [value]='examCenters[i].id'>
                        {{examCenters[i].nom}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row">
            <label for="dateInput" class="col-sm-4 col-form-label text-right">Date</label>
            <div class="input-group col-sm-8">
                <input class="form-control" id="dateInput" placeholder="jj/mm/aaaa" formControlName='date' name="dp"
                    ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i
                            class="far fa-calendar-alt"></i></button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="documentUrl" class="col-sm-4 col-form-label text-right">Fichier</label>
            <div class="col-sm-8">
                <button
                    *ngIf="invoice?.document"
                    class="btn btn-link"
                    (click)="showDocument()"
                    [disabled]="retrievingDocument">
                    <span *ngIf="retrievingDocument" class="button-loader"></span>
                    Voir le fichier
                </button>
                <input class="form-control-file" type="file" id="documentFile" (change)="onFileChange($event)">
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">Annuler</button>
    <button type="button" class="btn btn-outline-success" (click)="validate()">
        <span *ngIf="submitting" class="button-loader"></span>
        Valider
    </button>
</div>
