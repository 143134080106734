<ul class="nav nav-tabs border-bottom" *ngIf="user">
  <li class="nav-item" *ngIf="user.isStaff || user.isExamCenterBoss">
    <a class="nav-link" routerLink="invoices" routerLinkActive="active" href="#" >Factures</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="misc_files" routerLinkActive="active" href="#">Documents utiles</a>
  </li>
</ul>
<div class="mt-3">
  <router-outlet></router-outlet>
</div>