import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/services/user/user.service';
import { User } from '../../shared/models/user';
import { take } from 'rxjs/operators';
import { GroupsEnum } from '../../shared/enums/groupsEnum';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  groups: GroupsEnum;
  user: User;

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {

    // If the user arrives on the dashboard, redirect him depending on its role
    this.userService.getMe().pipe(take(1)).subscribe((user: User) => {
      this.user = user;

      // Redirect user to its default dashboard tab
      if (this.router.url == '/dashboard') {
        if (this.user.isStaff) this.router.navigate(['certifications'], { relativeTo: this.activatedRoute });
        if (this.user.isExamCenterBoss || this.user.isSupervisor || this.user.isCorrector) this.router.navigate(['tickets'], { relativeTo: this.activatedRoute });
        if (this.user.isStudent) this.router.navigate(['student_profile'], { relativeTo: this.activatedRoute });
      }
    });
  }
}
