<div class="modal-header">
    <h4 class="modal-title">Sélectionner un candidat</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <app-user-list [selectGroup]="group" (selectedUser)="onSelectedUser($event)"></app-user-list>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">Annuler</button>
</div>