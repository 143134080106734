<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="col">
        <input class="form-control-file" type="file" id="documentFile" (change)="onFileChange($event)">
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()">Annuler</button>
    <button [disabled]="!document" type="button" class="btn btn-outline-success" (click)="validate()">
        Valider
    </button>
</div>