import {Injectable} from "@angular/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {ExamResultFile} from "../../models/examResultFile";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ExamResultFileService {

    constructor (private http: HttpClient) { }

    destroy (id: number) {
        return this.http.delete<ExamResultFile>(`${environment.baseApiUrl}/exam_result_files/${id}/`);
    }

    fetchDocument (id: number): Observable<HttpResponse<Blob>> {
        return this.http.get(
            `${environment.baseApiUrl}/exam_result_files/${id}/document/`,
            {
                responseType: "blob",
                observe: "response" // Observe the full response
            }
        );
    }
}
