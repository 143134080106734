import { Component, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControl, ControlValueAccessor, ValidationErrors } from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';
import { Validator } from '@angular/forms';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: CountrySelectComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CountrySelectComponent
    }
  ]
})
export class CountrySelectComponent implements ControlValueAccessor, Validator {
  value: string = null;

  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  favoriteCountries: Array<string> = [
    'fr',
  ];
  
  countries: Object = {
    al: "Albanie",
    ad: "Andorre",
    am: "Arménie",
    at: "Autriche",
    az: "Azerbaïdjan",
    by: "Biélorussie",
    be: "Belgique",
    ba: "Bosnie-Herzégovine",
    bg: "Bulgarie",
    hr: "Croatie",
    cy: "Chypre",
    cz: "République tchèque",
    dk: "Danemark",
    ee: "Estonie",
    fi: "Finlande",
    fr: "France",
    ge: "Géorgie",
    de: "Allemagne",
    gr: "Grèce",
    hu: "Hongrie",
    is: "Islande",
    ie: "Irlande",
    it: "Italie",
    kz: "Kazakhstan",
    lv: "Lettonie",
    li: "Liechtenstein",
    lt: "Lituanie",
    lu: "Luxembourg",
    mk: "Macédoine du Nord",
    mt: "Malte",
    md: "Moldavie",
    mc: "Monaco",
    me: "Monténégro",
    nl: "Pays-Bas",
    no: "Norvège",
    pl: "Pologne",
    pt: "Portugal",
    ro: "Roumanie",
    ru: "Russie",
    sm: "Saint-Marin",
    rs: "Serbie",
    sk: "Slovaquie",
    si: "Slovénie",
    es: "Espagne",
    se: "Suède",
    ch: "Suisse",
    tr: "Turquie",
    ua: "Ukraine",
    gb: "Royaume-Uni",
    va: "Cité du Vatican",
  };
  
  constructor() { }

  getCountryCodes(): Array<string> {
    return Object.keys(this.countries);
  }

  onCodeSelected(value) {    
    this.markAsTouched();
    if (this.disabled) {
      return;
    }
    this.value = value;
    this.onChange(value);
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const code = control.value;
    if (code == null) {
      return {
        required: {
          valid: false
        }
      };
    }
  }

}
