import {Component, Input} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {environment} from "../../../../environments/environment";
import {Exam} from "../../../shared/models/exam";
import {ExamService} from "../../../shared/services/exam/exam.service";
import {take} from "rxjs/operators";


@Component({
    selector: "app-exam-reset-confirm-modal",
    templateUrl: "./exam-reset-confirm-modal.component.html",
    styleUrls: ["./exam-reset-confirm-modal.component.scss"]
})
export class ExamResetConfirmModalComponent {

  @Input() exam: Exam;
  baseUrl = environment.baseUrl;
  submitting: boolean = false;

  constructor (
    public activeModal: NgbActiveModal,
    private examService: ExamService
  ) {}

  submit () {
      this.submitting = true;
      this.examService.reset(this.exam.id).pipe(take(1)).
          subscribe((res: Exam) => {
              this.activeModal.close(res);
          });
  }
}
