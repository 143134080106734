import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { ExamComment } from '../../models/examComment';

@Injectable({
  providedIn: 'root'
})
export class ExamCommentService {

  constructor(private http: HttpClient) { }

  partialUpdate(id: number | string, data: Object) {
    return this.http.patch<ExamComment>(`${environment.baseApiUrl}/exam_comments/${id}/`, data).pipe(map((res: any) => new ExamComment(res)));
  }

  delete(id: number | string) {
    return this.http.delete(`${environment.baseApiUrl}/exam_comments/${id}/`);
  }

}
