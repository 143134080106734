<div class="container-fluid" id="site-wrapper">
  <header class="row">
    <div class="col text-left">
      <img class="logo-header" src="../assets/logo_gie_small_100x100.png" alt="Portail Certification Audionumerique">
    </div>
  </header>
  <div class="row">
    <div class="col" *ngIf="isLoggedIn && user">
      {{user.email}} <span *ngIf="userGroups"> - <b>{{userGroups}}</b></span>
    </div>
    <div class="col d-flex justify-content-end">
      <button class="btn btn-link" *ngIf="isLoggedIn" routerLink="/settings"><i class="fas fa-users-cog"></i>
        Paramètres</button>
      <button class="btn btn-link" *ngIf="!isLoggedIn" routerLink="/login">
        Connexion</button>
      <button class="btn btn-link" *ngIf="isLoggedIn" (click)="logout()"><i class="fas fa-door-open"></i>
        Déconnexion</button>
    </div>
  </div>
  <main class="border-top border-bottom">
    <router-outlet *ngIf="!(user && user.is_blocked)"></router-outlet>
    <div *ngIf="user && user.is_blocked" class="alert alert-danger">
      Il semble que votre compte soit bloqué temporairement. Si vous pensez qu'il s'agit d'une erreur, veuillez <a routerLink="contact">contacter</a> les administrateurs.
    </div>
  </main>
  <footer>
    <div class="container">
      <div class="row d-flex justify-content-center align-items-center">
        <small class="mx-2">© 2020 GIE</small>
        -
        <small>
          <a class="mx-2" routerLink="/terms-of-service">Conditions générales d'utilisation</a>
        </small>
        -
        <small>
          <a class="mx-2" routerLink="contact">Contact</a>
        </small>
      </div>
    </div>
  </footer>
</div>
