export class ChangePasswordDTO {
    password: string;
    new_password1: string;
    new_password2: string;

    constructor(data: any) {
        this.password = data.password;
        this.new_password1 = data.new_password1;
        this.new_password2 = data.new_password2;
    }
}