import {Injectable} from "@angular/core";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {ExamSession, ExamSessionCreateOrUpdateData} from "../../models/examSession";


@Injectable({
    providedIn: "root"
})
export class ExamSessionService {

    constructor (private http: HttpClient) { }

    list (short: boolean = false, page: number = null, term = null, centerId = null): Observable<Array<ExamSession>> {
        let params = new HttpParams();

        if (short) {
            params = params.set(
                "short",
                "True"
            );
        }

        if (centerId) {
            params = params.set(
                "center_id",
                `${centerId}`
            );
        }

        if (page) {
            params = params.set(
                "page",
                `${page}`
            );
        }

        if (term) {
            params = params.set(
                "term",
                `${term}`
            );
        }

        return this.http.get<Array<ExamSession>>(
            `${environment.baseApiUrl}/exam_sessions/`,
            {params}
        ).
            pipe(map((res) => res.map((d) => new ExamSession(d))));
    }

    count (term: string): Observable<number> {
        let params = new HttpParams();
        if (term) {
            params = params.set(
                "term",
                `${term}`
            );
        }
        return this.http.get<number>(
            `${environment.baseApiUrl}/exam_sessions/count/`,
            {params}
        );
    }
    update (id: number, data: ExamSessionCreateOrUpdateData) {
        return this.http.patch<ExamSession>(
            `${environment.baseApiUrl}/exam_sessions/${id}/`,
            data
        ).pipe(map((res) => new ExamSession(res)));
    }

    create (data: ExamSessionCreateOrUpdateData) {
        return this.http.post<ExamSession>(
            `${environment.baseApiUrl}/exam_sessions/`,
            data
        ).pipe(map((res) => new ExamSession(res)));
    }

    destroy (id: number) {
        return this.http.delete<ExamSession>(`${environment.baseApiUrl}/exam_sessions/${id}/`);
    }

    confirmSupervisorAttendance (id: number) {
        return this.http.put<ExamSession>(
            `${environment.baseApiUrl}/exam_sessions/${id}/confirm_supervisor_attendance/`,
            {}
        ).pipe(map((res) => new ExamSession(res)));
    }

    confirmCorrectorAttendance (id: number) {
        return this.http.put<ExamSession>(
            `${environment.baseApiUrl}/exam_sessions/${id}/confirm_corrector_attendance/`,
            {}
        ).pipe(map((res) => new ExamSession(res)));
    }

    exportToXml (id: number, examIds) {
        return this.http.get<string>(
            `${environment.baseApiUrl}/exam_sessions/${id}/export_to_xml/?${examIds.map((id) => `exam_ids=${id}`).join("&")}`,
            {}
        );
    }

    open (id: number) {
        return this.http.post<ExamSession>(
            `${environment.baseApiUrl}/exam_sessions/${id}/open/`,
            {}
        ).pipe(map((res) => new ExamSession(res)));
    }

    close (id: number) {
        return this.http.post<ExamSession>(
            `${environment.baseApiUrl}/exam_sessions/${id}/close/`,
            {}
        ).pipe(map((res) => new ExamSession(res)));
    }

    comment (id: number, comment: string) {
        return this.http.post<ExamSession>(
            `${environment.baseApiUrl}/exam_sessions/${id}/comment/`,
            {comment}
        ).pipe(map((res) => new ExamSession(res)));
    }

    minutePdf (id: number): Observable<HttpResponse<Blob>> {
        return this.http.get(
            `${environment.baseApiUrl}/exam_sessions/${id}/minute_pdf/`,
            {
                responseType: "blob",
                observe: "response" // Observe the full response
            }
        );
    }
}
