<div class="modal-header">
    <h4 class="modal-title">Etes-vous sûr de vouloir réinitaliser le ticket {{ exam.voucher_code }} ?</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()" [disabled]="submitting">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.dismiss()" [disabled]="submitting">Annuler</button>
    <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitting">
        <span *ngIf="submitting" class="button-loader"></span>
        Oui
    </button>
</div>