import { Component, OnInit, Input } from '@angular/core';
import { ExamCenter } from '../../../shared/models/examCenter';
import { ExamCenterMemberShip } from '../../../shared/models/examCenterMembership';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExamCenterMembershipService } from '../../../shared/services/exam-center-membership/exam-center-membership.service';
import { take } from 'rxjs/operators';
import { ExamCenterService } from '../../../shared/services/exam-center/exam-center.service';

@Component({
  selector: 'app-exam-center-membership-modal',
  templateUrl: './exam-center-membership-modal.component.html',
  styleUrls: ['./exam-center-membership-modal.component.scss']
})
export class ExamCenterMembershipModalComponent implements OnInit {

  @Input() membership: ExamCenterMemberShip;
  membershipForm: FormGroup;
  centers: Array<ExamCenter>;
  isDataLoading: boolean = true;
  error: string;
  submitting: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public examCenterMembershipService: ExamCenterMembershipService,
    public examCenterService: ExamCenterService,
  ) {
    this.membershipForm = new FormGroup({
      reference: new FormControl(''),
      center: new FormControl(''),
      date_start: new FormControl(''),
      date_end: new FormControl(''),
    });
    this.isDataLoading = true;
  }

  // Function to instanciate the frontend form
  // Call only after all data is fetched
  private resetForm() {

    // Populate the form if there's an input
    if (this.membership != null) {
      this.membershipForm.setValue({
        reference: this.membership.reference,
        center: this.membership.center ? this.membership.center.id : null,
        date_start: this.membership.date_start,
        date_end: this.membership.date_end,
      });
    }
  }

  ngOnInit() {
    this.examCenterService.list().pipe(take(1)).subscribe((centers: Array<ExamCenter>) => {
      this.centers = centers;
      this.resetForm();
      this.isDataLoading = false;
    });
  }

  validate() {
    this.submitting = true;

    let submitMembership = new ExamCenterMemberShip(this.membership);
    submitMembership.reference = this.membershipForm.value.reference;
    submitMembership.center = this.centers.find((center: ExamCenter) => center.id == this.membershipForm.value.center);
    submitMembership.date_start = this.membershipForm.value.date_start;
    submitMembership.date_end = this.membershipForm.value.date_end;

    // Trigger update if it's an edit
    if (this.membership) {
      this.examCenterMembershipService.update(submitMembership).pipe(take(1)).subscribe(
        (res: ExamCenterMemberShip) => {
          this.membership = res;
          this.resetForm();
          this.activeModal.close(this.membership);
        },
        (err: any) => {
          this.error = "Une erreur est survenue lors de la modification de l'adhésion"
        },
        () => {
          this.submitting = false;
        },
      );
    }

    // Trigger a save otherwise
    else {
      this.examCenterMembershipService.create(submitMembership).pipe(take(1)).subscribe(
        (res: ExamCenterMemberShip) => {
          this.membership = res;
          this.resetForm();
          this.activeModal.close(this.membership);
        },
        (err: any) => {
          this.error = "Une erreur est survenue lors de la création de l'adhésion"
        },
        () => {
          this.submitting = false;
        },
      );
    }
  }
}
