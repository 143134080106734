import {enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";

import {AppModule} from "./app/app.module";
import {environment} from "./environments/environment";

import * as Sentry from "@sentry/angular-ivy";

if (!environment.sentryEnv) {
    Sentry.init({
        dsn: "https://49568483c003a4865992055d5c757deb@o4504296763097088.ingest.us.sentry.io/4506918130024448",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false
            })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions

        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            "localhost",
            /^https:\/\/app\.media-cert\.fr/
        ],

        // Session Replay

        // // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysSessionSampleRate: 0.1,

        // // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        replaysOnErrorSampleRate: 1.0,

        beforeSend (event, hint) {

            /*
             * Console.log(event);
             * Check if it is an exception, and if so, show the report dialog
             * if (event.exception && event.event_id) {
             *  Sentry.showReportDialog({ eventId: event.event_id });
             * }
             */
            return event;
        },
        environment: environment.sentryEnv

    });
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).
    catch((err) => console.error(err));
