<div *ngIf="error">
    <div class="row">
        <div class="col d-flex justify-content-center">
            <span class="alert alert-warning">
                {{error}}
            </span>
        </div>
    </div>
</div>
<div *ngIf="embeddingQcmURL">
    <iframe frameBorder="0" class="w-100 vh-100" [src]="embeddingQcmURL"></iframe>
</div>
<div *ngIf="document" class="d-flex flex-column align-items-center">
    <hr />
    <div style="max-width: 800px; width: 100%;">
        <div class="d-flex">
            <h1 style="font-size: 28px; font-weight: normal; line-height: 1.25em;">Examen: {{test.description}}</h1>
            <a [href]="document" target="_blank" class="btn btn-primary" style="margin-left: auto;"><strong>Démarrer</strong></a>
        </div>
    </div>
</div>
<div *ngIf="!document && !error && !embeddingQcmURL">
    <div class="row">
        <div class="col d-flex justify-content-center align-items-center">
            <span class="button-loader"></span>Chargement...
        </div>
    </div>
</div>